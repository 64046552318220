<template>
	<div class="row" style="margin-top: 50px;">
		<div class="col-md-12">

			<div class="text-center loader" v-if="loading">
		        <div class="spinner-border"></div>
		    </div>

		    <div class="table-responsive">
				<table v-if="!loading" class="table" id="data-table">

					<thead>
		                <tr v-if="$isMobile()">
		                    <th></th>
		                </tr>

		                <tr v-else>
		                	<th>Invoice</th>
		                	<th>Amount</th>
		                	<th>Payment</th>
		                	<th>Invoice For</th>
		                	<th>Due Date</th>
		                	<th>Status</th>
		                </tr>
		            </thead>

		            <tbody>
	                    <template v-for="(d, index) in data">
	                    	<tr v-if="$isMobile()">
	                    		<th>
	                    			<table>
	                    				<tr>
	                    					<th>Invoice</th>
	                    					<td>
	                    						<router-link v-bind:to="'/invoice/' + d.id" v-text="d.id"></router-link>
	                    					</td>
	                    				</tr>

	                    				<tr>
	                    					<th>Amount</th>
	                    					<td v-html="d.currency + ' ' + d.amount"></td>
	                    				</tr>

	                    				<tr>
	                    					<th>Payment</th>
	                    					<td>
												<template v-if="d.paymentMethod == 'bank' && d.bank != null">
													<table class="table table-bordered table-bank">
														<tr>
															<th>Name</th>
															<td v-text="d.bank.name"></td>
														</tr>
														<tr>
															<th>Account #</th>
															<td v-text="d.bank.accountNumber"></td>
														</tr>
														<tr>
															<th>Title</th>
															<td v-text="d.bank.accountTitle"></td>
														</tr>
														<tr>
															<th>Swift Code</th>
															<td v-text="d.bank.swiftCode"></td>
														</tr>
														<tr>
															<th>Branch</th>
															<td v-text="d.bank.branch"></td>
														</tr>
														<tr>
															<th>Address</th>
															<td v-text="d.bank.address"></td>
														</tr>
													</table>
												</template>

												<p v-else-if="d.paymentMethod == 'cheque' && d.cheque != null" v-text="'Cheque: ' + d.cheque" style="margin-bottom: 0px;"></p>

												<p v-else v-text="d.paymentMethod" style="margin-bottom: 0px; text-transform: capitalize;"></p>
											</td>
	                    				</tr>

	                    				<tr>
	                    					<th>Invoice For</th>
	                    					<td v-text="d.invoiceFor.replace(/([a-z])([A-Z])/g, '$1 $2')" style="text-transform: capitalize;"></td>
	                    				</tr>

	                    				<tr>
	                    					<th>Due Date</th>
	                    					<td v-text="d.dueDate"></td>
	                    				</tr>

	                    				<tr>
	                    					<th>Status</th>
	                    					<td v-text="d.status" style="text-transform: capitalize;"></td>
	                    				</tr>
	                    			</table>
	                    		</th>
	                    	</tr>

	                    	<tr v-else>
								<td>
									<router-link v-bind:to="'/invoice/' + d.id" v-text="d.id"></router-link>
								</td>
								<td v-html="d.currency + ' ' + d.amount"></td>
								<td>
									<template v-if="d.paymentMethod == 'bank' && d.bank != null">
										<table class="table table-bordered table-bank">
											<tr>
												<th>Name</th>
												<td v-text="d.bank.name"></td>
											</tr>
											<tr>
												<th>Account #</th>
												<td v-text="d.bank.accountNumber"></td>
											</tr>
											<tr>
												<th>Title</th>
												<td v-text="d.bank.accountTitle"></td>
											</tr>
											<tr>
												<th>Swift Code</th>
												<td v-text="d.bank.swiftCode"></td>
											</tr>
											<tr>
												<th>Branch</th>
												<td v-text="d.bank.branch"></td>
											</tr>
											<tr>
												<th>Address</th>
												<td v-text="d.bank.address"></td>
											</tr>
										</table>
									</template>

									<p v-else-if="d.paymentMethod == 'cheque' && d.cheque != null" v-text="'Cheque: ' + d.cheque" style="margin-bottom: 0px;"></p>

									<p v-else v-text="d.paymentMethod" style="margin-bottom: 0px; text-transform: capitalize;"></p>
								</td>
								<td v-text="d.invoiceFor.replace(/([a-z])([A-Z])/g, '$1 $2')" style="text-transform: capitalize;"></td>
								<td v-text="d.dueDate"></td>
								<td v-text="d.status" style="text-transform: capitalize;"></td>
							</tr>
	                    </template>
	                </tbody>
		        </table>
		    </div>

		    <div v-if="pages > 0" id="pagination">
				<nav aria-label="Page navigation">
					<ul class="pagination">
						<li v-for="page in pages" v-bind:class="'page-item ' + (page == pageNumber ? 'active' : '')">
							<a class="page-link" href="javascript:void(0)" v-on:click="paginate(page)" v-text="page"></a>
						</li>
					</ul>
				</nav>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from "axios"
    import swal from "sweetalert2"
    import jQuery from "jquery"

    import "../assets/js/jquery.js"
    import "../assets/datetimepicker/jquery.datetimepicker.min.css"
    import "../assets/datetimepicker/jquery.datetimepicker.full.js"

    import "../assets/datatable/datatables.css"
    import "../assets/datatable/datatables.min.js"

	export default {
		name: "MyInvoicesComponent",
		data() {
			return {
				loading: false,
				data: [],
				pages: 0,
				pageNumber: this.$route.query.page || 1,
				dataTable: null
			}
		},
		methods: {

			paginate: function (page) {
				this.pageNumber = page
				this.addOrUpdateURLParam("page", page)
				this.getData()
			},

            async getData() {
            	const self = this
            	const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
                this.loading = true

                if (this.dataTable != null) {
	            	this.dataTable.destroy()
	            }

                try {
                    const formData = new FormData()
                    formData.append("page", this.pageNumber)
                    formData.append("timezone", timezone)

                    const response = await axios.post(
                        this.$apiURL + "/myInvoices",
                        formData,
                        {
                            headers: this.$headers
                        }
                    )

                    if (response.data.status == "success") {
                        this.data = response.data.data
                        this.pages = response.data.pages
                    }
                } catch (error) {
                    if (error?.response?.status == 401) {
                        swal.fire("Error", error.response.data.message || "Unauthorized", "error")
                    }
                } finally {
                    this.loading = false

                    setTimeout(function () {
			    		self.initializeTable()
			    	}, 100)
                }
            },

            initializeTable() {
	            if (this.$isMobile()) {
	            	this.dataTable = jQuery("#data-table").DataTable({
		            	ordering: false,
	                    pageLength: 1
		            })
	            } else {
	            	this.dataTable = jQuery("#data-table").DataTable({
		            	ordering: false
		            })
	            }
	        }
        },

		mounted() {
            this.getData()

            document.title = "My Invoice"
        }
	}
</script>

<style scoped>
	.table-bank,
	.table-bank th,
	.table-bank td {
		border: 1px solid #dadada;
	}
	.table-bank th {
		font-weight: bold;
	}
</style>