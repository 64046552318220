<template>
	<!-- Main Section-->
    <section class="mt-5 ">
        <!-- Page Content Goes Here -->
        <!-- Product Top-->
        <section class="container">
            <!-- Breadcrumbs-->
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item active" aria-current="page">Login / Sign Up</li>
                </ol>
            </nav> <!-- /Breadcrumbs-->
            <div class="row g-5">
                <!-- Images Section-->
                <div class="col-md-6">
                	<br />
                	<h2>Login</h2>
                	<br />
                	<form style="display: contents;" v-on:submit.prevent="doLogin" novalidate id="form-login">
                		<div class="product-option">
                            <small class="text-uppercase d-block fw-bolder mb-2">
                                Email:
                            </small>
                            <div class="form-group">
                                <textarea name="email" class="form-control" required rows="1" oninput="this.value = this.value.replace(/\n/g, '')"></textarea>
                            </div>
                        </div>

                        <div class="product-option">
                            <small class="text-uppercase d-block fw-bolder mb-2">
                                Password:
                            </small>
                            <div class="form-group">
                                <input name="password" type="password" class="form-control" required v-on:keyup="onkeyupPassword" />
                            </div>
                        </div>

                        <button type="submit" v-bind:disabled="logging" class="btn btn-dark btn-dark-chunky flex-grow-1 me-2 text-white">Login</button>
                	</form>

                    <router-link to="/forgotPassword">Forgot Password?</router-link>
                </div>

                <div class="col-md-6">
                	<br />
                	<h2>Sign Up</h2>
                	<br />
                	<form style="display: contents;" v-on:submit.prevent="doSignUp" id="form-signup">
                		<div class="product-option">
                            <small class="text-uppercase d-block fw-bolder mb-2">
                                Name:
                            </small>
                            <div class="form-group">
                                <textarea name="name" class="form-control" required rows="1" oninput="this.value = this.value.replace(/\n/g, '')"></textarea>
                            </div>
                        </div>

                        <div class="product-option">
                            <small class="text-uppercase d-block fw-bolder mb-2">
                                Phone:
                            </small>
                            <div class="form-group">
                                <textarea name="phone" class="form-control" required rows="1" oninput="this.value = this.value.replace(/\n/g, '')"></textarea>
                            </div>
                        </div>

                		<div class="product-option">
                            <small class="text-uppercase d-block fw-bolder mb-2">
                                Email:
                            </small>
                            <div class="form-group">
                                <textarea name="email" class="form-control" required rows="1" oninput="this.value = this.value.replace(/\n/g, '')"></textarea>
                            </div>
                        </div>

                        <div class="product-option">
                            <small class="text-uppercase d-block fw-bolder mb-2">
                                Password:
                            </small>
                            <div class="form-group">
                                <input name="password" type="password" class="form-control" required v-on:keyup="onkeyupPasswordSignup" />
                            </div>
                        </div>

                        <button type="submit" v-bind:disabled="signingUp" class="btn btn-dark btn-dark-chunky flex-grow-1 me-2 text-white">Sign Up</button>
                	</form>
                </div>
            </div>
        </section>
    </section>
</template>

<script>

	import axios from "axios"
	import swal from "sweetalert2"
	import store from "../vuex/store"

	export default {
		name: "LoginComponent",

		data() {
			return {
				logging: false,
				signingUp: false
			}
		},

		methods: {
            onkeyupPassword() {
                if (event.keyCode == 13) {
                    this.doLogin()
                }
            },

            onkeyupPasswordSignup() {
                if (event.keyCode == 13) {
                    this.doSignUp()
                }
            },

			async doLogin() {
                this.logging = true

                try {

                    const form = document.getElementById("form-login")
                    const formData = new FormData(form)

                    const response = await axios.post(
                        this.$apiURL + "/login",
                        formData
                    )

                    if (response.data.status == "success") {
                        const token = response.data.token
                        localStorage.setItem(this.$accessToken, token)
                        this.$headers.Authorization = "Bearer " + token
                        
                        store.commit("setUser", response.data.user)
                        store.commit("setUnreadNotifications", response.data.unreadNotifications)

                        this.initializeUserMenu()
                        if (response.data.requiresCrypto) {
                            const keyPair = await window.crypto.subtle.generateKey(
                                {
                                    name: "ECDH",
                                    namedCurve: "P-256",
                                },
                                true,
                                ["deriveKey", "deriveBits"]
                            )
                            const publicKeyJwk = await window.crypto.subtle.exportKey(
                                "jwk",
                                keyPair.publicKey
                            )
                            const privateKeyJwk = await window.crypto.subtle.exportKey(
                                "jwk",
                                keyPair.privateKey
                            )
                            try {
                                const formData1 = new FormData()
                                formData1.append("publicKey", JSON.stringify(publicKeyJwk))
                                formData1.append("privateKey", JSON.stringify(privateKeyJwk))
                                const response1 = await axios.post(
                                    this.$apiURL + "/updateCrypto",
                                    formData1,
                                    {
                                        headers: this.$headers
                                    }
                                )
                                if (response1.data.status == "success") {
                                    this.$router.push("/")
                                }
                            } catch (error) {
                                if (error?.response?.status == 401) {
                                    swal.fire("Error", error.response.data.message || "Unauthorized", "error")
                                }
                            }
                        } else {
                            this.$router.push("/")
                        }
                    } else {
                        swal.fire("Login", response.data.message, response.data.status)
                    }
                } catch (error) {
                    if (error?.response?.status == 401) {
                        swal.fire("Error", error.response.data.message || "Unauthorized", "error")
                    }
                } finally {
                    this.logging = false
                }
            },

            async doSignUp() {
                this.signingUp = true

                try {
	                const form = document.getElementById("form-signup")
	                const formData = new FormData(form)

	                const response = await axios.post(
	                    this.$apiURL + "/signUp",
	                    formData
	                )

	                this.signingUp = false
	                swal.fire("Sign Up", response.data.message, response.data.status)
                } catch (error) {
                    if (error?.response?.status == 401) {
                        swal.fire("Error", error.response.data.message || "Unauthorized", "error")
                    }
                } finally {
                    this.logging = false
                }
            },
		},
        mounted() {
            /*setTimeout(function () {
                document.getElementById("form-login").email.value = ""
                document.getElementById("form-login").password.value = ""
                document.getElementById("form-signup").name.value = ""
                document.getElementById("form-signup").email.value = ""
                document.getElementById("form-signup").password.value = ""
            }, 1000)*/

            document.title = "Login"
        }
	}
</script>

<style scoped>
    #form-login textarea,
    #form-signup textarea {
        resize: none;
        min-height: fit-content;
        font-family: sans-serif;
        font-size: 14px;
    }
</style>