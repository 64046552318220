<template>
	<!-- Main Section-->
    <section class="mt-0 ">
        <!-- Category Top Banner -->
        <div class="py-6 bg-img-cover bg-dark bg-overlay-gradient-dark position-relative overflow-hidden mb-4 bg-pos-center-center" style="background-image: url(./assets/images/banners/banner-1.jpg);">
            <div class="container position-relative z-index-20" data-aos="fade-right" data-aos-delay="300">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item breadcrumb-light">
                            <router-link to="/">Home</router-link>
                        </li>
                        <li class="breadcrumb-item active breadcrumb-light" aria-current="page">Investor Panel</li>
                    </ol>
                </nav>
                <h1 class="fw-bold display-6 mb-4 text-white">Investor Panel</h1>
            </div>
        </div>

        <!-- Category Top Banner -->
        <div class="container">
            <div class="row">
                <!-- Category Aside/Sidebar -->
                <div class="col-lg-12">
                	<div class="text-center loader" v-if="loading">
                        <div class="spinner-border"></div>
                    </div>

                    <div class="table-responsive">
						<table v-if="!loading" class="table" id="data-table">

							<thead>
				                <tr v-if="$isMobile()">
				                    <th></th>
				                </tr>

				                <tr v-else>
				                	<th>LOT</th>
				                	<th>Images</th>
				                	<th>Time</th>
				                	<th>Year</th>
				                	<th>Name</th>
				                	<th>Chassis</th>
				                	<th>Package</th>
				                	<th>Mileage</th>
				                	<th>Displace</th>
				                	<th>Transmission</th>
				                	<th>Grade</th>
				                	<th>Color</th>
				                	<th>Starting Price</th>
				                	<th>Bid Price</th>
				                	<th>Awarded Price</th>
				                	<th>Actions</th>
				                </tr>
				            </thead>

				            <tbody>
			                    <template v-for="(d, index) in data">
			                    	<tr v-if="$isMobile()">
			                    		<th>
			                    			<table>
			                    				<tr>
													<th>LOT</th>
													<td v-text="d.auctionCar.LOT"></td>
												</tr>

												<tr>
													<th>Images</th>
													<td>
														<router-link v-bind:to="'/carDetail/' + d.auctionCar.CAR_ID">
															<template v-for="(img, index) in d.auctionCar.IMAGES">
																<img v-if="index < 3" v-bind:src="img.replaceAll('h=50', 'w=320')" style="height: 100px; display: inline-block;" />
															</template>
														</router-link>
													</td>
												</tr>

												<tr>
													<th>Time</th>
													<td v-text="d.auctionCar.TIME"></td>
												</tr>

												<tr>
													<th>Year</th>
													<td v-text="d.auctionCar.YEAR"></td>
												</tr>

												<tr>
													<th>Name</th>
													<td v-text="d.auctionCar.MARKA_NAME + ' ' + d.auctionCar.MODEL_NAME"></td>
												</tr>

												<tr>
													<th>Chassis</th>
													<td v-text="d.auctionCar.KUZOV"></td>
												</tr>

												<tr>
													<th>Package</th>
													<td v-html="d.auctionCar.GRADE"></td>
												</tr>

												<tr>
													<th>Mileage</th>
													<td v-text="d.auctionCar.MILEAGE + ' km'"></td>
												</tr>

												<tr>
													<th>Displace</th>
													<td v-text="d.auctionCar.ENG_V + ' cc'"></td>
												</tr>

												<tr>
													<th>Transmission</th>
													<td v-text="d.auctionCar.KPP"></td>
												</tr>
												
												<tr>
													<th>Grade</th>
													<td v-text="d.auctionCar.RATE"></td>
												</tr>

												<tr>
													<th>Color</th>
													<td v-text="d.auctionCar.COLOR"></td>
												</tr>
												
												<tr>
													<th>Starting Price</th>
													<td v-html="'&yen;' + d.auctionCar.START"></td>
												</tr>

												<tr>
													<th>Bid Price</th>
													<td v-html="'&yen;' + d.bidAmount"></td>
												</tr>

												<tr>
													<th>Awarded Price</th>
													<td v-html="'&yen;' + d.awardedPrice"></td>
												</tr>

												<tr>
													<th class="border-top-0">Actions</th>
													<td>
														<router-link v-bind:to="'/account?tab=tracking&id=' + d.auctionCarId">
															Tracking
														</router-link>
														
														<br />

														<!-- Button trigger modal -->
														<button type="button" class="btn btn-success btn-sm" v-on:click="onclickPutForSale(d)" style="margin-top: 10px;" v-if="d.orderId == null || d.orderId == 0">
															Put car for sale
														</button>

														<br />

														<router-link v-if="d.orderId != null && d.orderId > 0" v-bind:to="'/carsForSale/' + d.auctionCarId">Sale Page</router-link>
													</td>
												</tr>
			                    			</table>
			                    		</th>
			                    	</tr>

			                    	<tr v-else>
			                    		<td v-text="d.auctionCar.LOT"></td>
										<td>
											<router-link v-bind:to="'/carDetail/' + d.auctionCar.CAR_ID">
												<template v-for="(img, index) in d.auctionCar.IMAGES">
													<img v-if="index < 3" v-bind:src="img.replaceAll('h=50', 'w=320')" style="height: 100px; display: inline-block;" />
												</template>
											</router-link>
										</td>
										<td v-text="d.auctionCar.TIME"></td>
										<td v-text="d.auctionCar.YEAR"></td>
										<td v-text="d.auctionCar.MARKA_NAME + ' ' + d.auctionCar.MODEL_NAME"></td>
										<td v-text="d.auctionCar.KUZOV"></td>
										<td v-html="d.auctionCar.GRADE"></td>
										<td v-text="d.auctionCar.MILEAGE + ' km'"></td>
										<td v-text="d.auctionCar.ENG_V + ' cc'"></td>
										<td v-text="d.auctionCar.KPP"></td>
										<td v-text="d.auctionCar.RATE"></td>
										<td v-text="d.auctionCar.COLOR"></td>
										<td v-html="'&yen;' + d.auctionCar.START"></td>
										<td v-html="'&yen;' + d.bidAmount"></td>
										<td v-html="'&yen;' + d.awardedPrice"></td>
										<td>
											<router-link v-bind:to="'/account?tab=tracking&id=' + d.auctionCarId">
												Tracking
											</router-link>

											<br />

											<!-- Button trigger modal -->
											<button type="button" class="btn btn-success btn-sm" v-on:click="onclickPutForSale(d)" style="margin-top: 10px;" v-if="d.orderId == null || d.orderId == 0">
												Put car for sale
											</button>

											<br />

											<router-link v-if="d.orderId != null && d.orderId > 0" v-bind:to="'/carsForSale/' + d.auctionCarId">Sale Page</router-link>
										</td>
			                    	</tr>
			                    </template>
			                </tbody>
			            </table>
			        </div>

		            <div v-if="pages > 0" id="pagination">
						<nav aria-label="Page navigation">
							<ul class="pagination">
								<li v-for="page in pages" v-bind:class="'page-item ' + (page == pageNumber ? 'active' : '')">
									<a class="page-link" href="javascript:void(0)" v-on:click="paginate(page)" v-text="page"></a>
								</li>
							</ul>
						</nav>
					</div>
                </div>
            </div>
        </div>
    </section>
	<!-- The Modal -->
	<div id="putForSaleModal" class="modal">
	  	<!-- Modal content -->
		<div class="modal-content">
			<div class="modal-header">
				<h2>Put Car for Sale</h2>
				<span class="close">&times;</span>
			</div>
			<div class="modal-body">
				<form v-on:submit.prevent="putCarForSale" v-if="selectedCar != null">
					<input type="hidden" name="id" v-model="selectedCar.auctionCarId" required />
					
					<div class="form-group">
						<label>Price</label>
						<input type="number" min="0" class="form-control" name="price" v-model="selectedCar.awardedPrice" required />
					</div>
					
					<div class="form-group">
						<label>Currency</label>
						<input type="text" name="currency" class="form-control" required value="&yen;" />
					</div>

					<button type="submit" name="submit" class="btn btn-success" v-bind:disabled="puttingForSale">Put Car for Sale <i v-if="puttingForSale" class="fa fa-spinner fa-spin"></i></button>
				</form>
			</div>
			<!-- <div class="modal-footer">
				<h3>Modal Footer</h3>
			</div> -->
		</div>
	</div>
</template>

<script>
	import axios from "axios"
	import swal from "sweetalert2"
	import jQuery from "jquery"
	import "../../assets/js/jquery.js"
	import "../../assets/fonts/css/font-awesome.min.css"

	export default {
		name: "InvestorComponent",
		data() {
			return {
				loading: false,
				data: [],
				dataTable: null,
				pages: 0,
				pageNumber: this.$route.query.page || 1,
				selectedCar: null,
				puttingForSale: false
			}
		},
		methods: {
			async putCarForSale() {
				const self = this
				try {
					this.puttingForSale = true
					const form = event.target
	        		const formData = new FormData(form)
	        		formData.append("page", this.pageNumber)
					const response = await axios.post(
						this.$apiURL + "/investor/putForSale",
						formData,
						{
							headers: this.$headers
						}
					)
					if (response.data.status == "success") {
						swal.fire("Put Car for Sale", response.data.message, "info")
                    } else {
				        swal.fire("Error", response.data.message, "error")
				    }
				} catch (error) {
					if (error?.response?.status == 401) {
                        swal.fire("Error", error.response.data.message || "Unauthorized", "error")
                    }
				} finally {
					this.puttingForSale = false
				}
			},
			onclickPutForSale(obj) {
				this.selectedCar = obj
				this.showModal("#putForSaleModal")
			},
			paginate(page) {
				this.pageNumber = page
				this.addOrUpdateURLParam("page", page)
				this.getData()
			},
			async getData() {
				const self = this
				try {
					this.loading = true
	        		this.data = []
	        		if (this.dataTable != null) {
		            	this.dataTable.destroy()
		            }
	        		const formData = new FormData()
	        		formData.append("page", this.pageNumber)
					const response = await axios.post(
						this.$apiURL + "/myPurchases",
						formData,
						{
							headers: this.$headers
						}
					)
					if (response.data.status == "success") {
						this.data = response.data.data
				    	this.pages = response.data.pages
						this.pageNumber = response.data.pageNumber
                    } else {
				        swal.fire("Error", response.data.message, "error")
				    }
				} catch (error) {
					if (error?.response?.status == 401) {
                        swal.fire("Error", error.response.data.message || "Unauthorized", "error")
                    }
				} finally {
					this.loading = false
					setTimeout(function () {
			    		self.initialize()
			    	}, 100)
				}
			},
			initialize() {
				if (this.dataTable != null) {
					return
				}
				if (this.$isMobile()) {
					this.dataTable = jQuery("#data-table").DataTable({
		            	ordering: false,
	                    pageLength: 1
		            }) 
				} else {
					this.dataTable = jQuery("#data-table").DataTable({
		            	ordering: false
		            })
				}
	        }
		},
		mounted() {
			this.getData()

			document.title = "Investor Panel"
		}
	}
</script>