<template>

  <app-header />
    <router-view />
  <app-footer />

  <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
  <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'

import AppHeader from "./components/layouts/AppHeader.vue"
import AppFooter from "./components/layouts/AppFooter.vue"

export default {
  name: 'App',
  components: {
    // HelloWorld

    AppHeader,
    AppFooter,
  }
}

document.title = "USSGlobal.com"

</script>

<style>
/*@page {
      size: A4;
      margin: 0;
    }
    .invoice, .invoice * {
      visibility: visible;
    }
    .invoice {
      font-size: 11px !important;
      overflow: hidden !important;
    }
    .invoice footer {
      position: absolute;
      bottom: 10px;
      page-break-after: always
    }
    .invoice>div:last-child {
      page-break-before: always
    }
    body * {
      visibility: hidden;
    }
    .print-container, .print-container * {
      visibility: visible;
    }
    .print-container {
      position: absolute;
      top: 0px;
      left: 50%;
      transform: translateX(-50%);
    }*/
  @media print {
    @page {
      size: A4;
      margin: 0;
    }
    .invoice, .invoice * {
      visibility: visible;
    }
    .invoice {
      font-size: 11px !important;
      overflow: hidden !important;
    }
    .invoice footer {
      position: absolute;
      bottom: 10px;
      page-break-after: always
    }
    .invoice>div:last-child {
      page-break-before: always
    }
    body * {
      visibility: hidden;
    }
    .print-container, .print-container * {
      visibility: visible;
    }
    .print-container {
      position: absolute;
      top: 0px;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
    }
  }
</style>
