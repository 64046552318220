<template>
	<div class="row">
		<div class="col-md-12">
			<div class="input-group" style="margin-bottom: 0px;">
                <input type="text" name="date"
                    id="date"
                    class="form-control date br-tl-7 br-bl-7"
                    placeholder="Enter date"
                    v-model="date"
                    style="border-radius: 0px !important; margin-top: 0px;" 
                    required />
            </div>
		</div>
	</div>

	<div class="row" style="margin-top: 50px;">
		<div class="col-md-12">

			<div class="text-center loader" v-if="loading">
		        <div class="spinner-border"></div>
		    </div>

		    <div class="table-responsive">
				<table class="table" id="data-table">

					<thead>
		                <tr v-if="$isMobile()">
		                    <th></th>
		                </tr>

		                <tr v-else>
		                	<th>LOT</th>
		                	<th>Images</th>
		                	<th>Name</th>
		                	<th>Type</th>
		                	<th>Status</th>
		                </tr>
		            </thead>

		            <tbody>
	                    <template v-for="(d, index) in data">
	                    	<tr v-if="$isMobile()">
	                    		<th>
	                    			<table>
	                    				<tr>
											<th>LOT</th>
											<td v-text="d.LOT"></td>
										</tr>

										<tr>
											<th>Images</th>
											<td>
												<router-link v-bind:to="'/carDetail/' + d.CAR_ID">
													<img v-for="img in d.IMAGES" v-bind:src="img.replaceAll('h=50', 'w=320')" style="height: 100px; display: inline-block;" />
												</router-link>
											</td>
										</tr>

										<tr>
											<th>Name</th>
											<td>
												<router-link v-bind:to="'/carDetail/' + d.CAR_ID" v-text="d.MARKA_NAME + ' ' + d.MODEL_NAME"></router-link>
											</td>
										</tr>

										<tr>
											<th>Type</th>
											<td style="text-transform: capitalize;" v-text="d.type.replace(/([a-z])([A-Z])/g, '$1 $2')"></td>
										</tr>

										<tr>
											<th>Status</th>
											<td style="text-transform: capitalize;" v-text="d.status"></td>
										</tr>
	                    			</table>
	                    		</th>
	                    	</tr>

	                    	<tr v-else>
	                    		<td v-text="d.LOT"></td>
	                    		<td>
									<router-link v-bind:to="'/carDetail/' + d.CAR_ID">
										<template v-for="(image, index) in 2">
											<img v-if="index < d.IMAGES.length" v-bind:src="d.IMAGES[index].replaceAll('h=50', 'w=320')" style="height: 100px; display: inline-block;" />
										</template>
									</router-link>
								</td>
								<td>
									<router-link v-bind:to="'/carDetail/' + d.CAR_ID" v-text="d.MARKA_NAME + ' ' + d.MODEL_NAME"></router-link>
								</td>
								<td style="text-transform: capitalize;" v-text="d.type.replace(/([a-z])([A-Z])/g, '$1 $2')"></td>
								<td style="text-transform: capitalize;" v-text="d.status"></td>
	                    	</tr>
	                    </template>
	                </tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>

	import axios from "axios"
    import swal from "sweetalert2"
    import jQuery from "jquery"

    import "../assets/datetimepicker/jquery.datetimepicker.min.css"
    import "../assets/datetimepicker/jquery.datetimepicker.full.js"

    import "../assets/datatable/datatables.css"
    import "../assets/datatable/datatables.min.js"

	export default {
		name: "MyRequestsComponent",

		data() {
			return {
				loading: false,
				style: "",
				data: [],
				date: this.$route.query.date || "",
				dataTable: null
			}
		},

		methods: {
        	getData: async function () {
        		const self = this

        		this.loading = true
        		this.data = []

        		if (this.dataTable != null) {
	            	this.dataTable.destroy()
	            }

        		const formData = new FormData()
        		formData.append("date", this.date)

        		try {
        			const response = await axios.post(
				        this.$apiURL + "/myRequests",
				        formData,
				        {
				            headers: this.$headers
				        }
				    )
				 
				    if (response.data.status == "success") {
				    	this.data = response.data.data
				    } else {
				        swal.fire("Error", response.data.message, "error")
				    }
        		} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.loading = false

					setTimeout(function () {
			    		self.initializeMyBids()
			    	}, 500)
				}
			},

			initializeMyBids() {
				const self = this
	            let selectedColumn = -1

	            jQuery(".date").datetimepicker({
	                "format": "Y-m-d",
	                "timepicker": false,
	                "onSelectDate": function() {
	                	const d = jQuery('.date').datetimepicker('getValue')
	                	self.getDate(null, new Date(d))
	                	self.addOrUpdateURLParam("date", self.date)
	                    self.getData()
	                },
	                "scrollMonth": false
	            })

	            if (this.$isMobile()) {
					this.dataTable = jQuery("#data-table").DataTable({
		            	ordering: false,
	                    pageLength: 1
		            })
				} else {
					this.dataTable = jQuery("#data-table").DataTable({
		            	ordering: false
		            })
				}
	        },

	        getDate(date, dateObj) {
	        	if (!date) {
					let month = (dateObj.getMonth() + 1)
					if (month < 10) {
						month = "0" + month
					}

					let dateVal = dateObj.getDate()
					if (dateVal < 10) {
						dateVal = "0" + dateVal
					}

					date = dateObj.getFullYear() + "-" + month + "-" + dateVal
				}

				this.date = date
	        }
        },

		mounted() {
			this.getDate(this.$route.query.date, new Date())
        	this.getData()
        	document.title = "My Requests"
        }
	}
</script>