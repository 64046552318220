<template>
	SaveLocalStorageComponent
</template>

<script>
	export default {
		name: "SaveLocalStorageComponent",

		mounted() {
			const self = this
			window.addEventListener("message", function (e) {
				if (e.origin !== self.$postMessageURL) {
				    return
				}

				const data = JSON.parse(e.data)
				if (typeof data.token !== "undefined") {
					localStorage.setItem(self.$accessToken, data.token)
				}
		    })
		}
	}
</script>