<template>
	<!-- Main Section-->
    <section class="mt-5 ">
        <!-- Page Content Goes Here -->
        <!-- Product Top-->
        <section class="container">
            <!-- Breadcrumbs-->
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item active" aria-current="page">Reset Password</li>
                </ol>
            </nav> <!-- /Breadcrumbs-->
            <div class="row g-5">
                <!-- Images Section-->
                <div class="offset-md-3 col-md-6">
                	<br />
                	<h2>Reset Password</h2>
                	<p>Please enter the code which you have received in your email inbox.</p>
                	<br />
                	<form v-on:submit.prevent="resetPassword">
                		<div class="product-option">
                            <small class="text-uppercase d-block fw-bolder mb-2">
                                Code:
                            </small>
                            <div class="form-group">
                                <input type="text" name="code" class="form-control" required />
                            </div>
                        </div>
                        <div class="product-option">
                            <small class="text-uppercase d-block fw-bolder mb-2">
                                New Password:
                            </small>
                            <div class="form-group">
                                <input type="password" name="password" class="form-control" required />
                            </div>
                        </div>
                        <div class="product-option">
                            <small class="text-uppercase d-block fw-bolder mb-2">
                                Confirm Password:
                            </small>
                            <div class="form-group">
                                <input type="password" name="confirmPassword" class="form-control" required />
                            </div>
                        </div>
                        <button type="submit" v-bind:disabled="resetting" class="btn btn-dark btn-dark-chunky flex-grow-1 me-2 text-white">Reset Password</button>
                	</form>
                </div>
            </div>
        </section>
    </section>
</template>

<script>
	import axios from "axios"
	import swal from "sweetalert2"

	export default {
		name: "ResetPasswordComponent",
		data() {
			return {
				resetting: false,
				email: this.$route.query.email || ""
			}
		},
		methods: {
			async resetPassword() {
				this.resetting = true
                try {
                    const form = event.target
                    const formData = new FormData(form)
                    formData.append("email", this.email)
                    const response = await axios.post(
                        this.$apiURL + "/resetPassword",
                        formData
                    )
                    swal.fire("Reset Password", response.data.message, response.data.status)
                    if (response.data.status == "success") {
                    	this.$router.push("/login")
                    }
                } catch (error) {
                    if (error?.response?.status == 401) {
                        swal.fire("Error", error.response.data.message || "Unauthorized", "error")
                    }
                } finally {
                    this.resetting = false
                }
			}
		},

        mounted() {
            document.title = "Reset Password"
        }
	}
</script>