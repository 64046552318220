<template>
	<!-- Main Section-->
    <section class="mt-0 ">
        <!-- Category Top Banner -->
        <div class="py-6 bg-img-cover bg-dark bg-overlay-gradient-dark position-relative overflow-hidden mb-4 bg-pos-center-center" style="background-image: url(./assets/images/banners/banner-1.jpg);">
            <div class="container position-relative z-index-20" data-aos="fade-right" data-aos-delay="300">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item breadcrumb-light">
                            <router-link to="/">Home</router-link>
                        </li>
                        <li class="breadcrumb-item active breadcrumb-light" aria-current="page">Countries</li>
                    </ol>
                </nav>
                <h1 class="fw-bold display-6 mb-4 text-white">Countries</h1>
            </div>
        </div>

        <!-- Category Top Banner -->
        <div class="container">
            <div class="row">
                <!-- Category Aside/Sidebar -->
                <div class="col-lg-12">
                	<div id="content" class="row">
                    	<div class="single-country col-md-3" v-for="(d, index) in data" v-bind:key="d.name">
                    		<div class="card">
                				<div class="card-body">
                    				<div class="row">
                    					<div class="col-md-4">
                    						<img v-bind:src="d.flag" class="flag" />
                    					</div>

                    					<div class="col-md-8 country-info">
                    						<p style="font-weight: bold; margin-bottom: 20px; margin-left: 10px;
											    position: relative;
											    top: 50%;
											    transform: translateY(-50%);">
                    							<router-link v-if="d.status == 'active'" v-bind:to="'/country/' + d.name" v-text="d.name + ' | ' + d.region"></router-link>

                    							<span v-else v-text="d.name + ' | ' + d.region"></span>
                    						</p>
                    					</div>
                    				</div>
                    			</div>
                			</div>
                    	</div>

                    	<div class="text-center loader" v-if="loading">
	                        <div class="spinner-border"></div>
	                    </div>

                    	<nav v-if="pages > 0" style="margin-top: 20px;">
	        				<ul class="pagination">
	        					<li v-for="page in pages" v-bind:class="'page-item ' + (page == pageNumber ? 'active' : '')">
	        						<a class="page-link" href="javascript:void(0)" v-on:click="paginate(page)" v-text="page"></a>
	        					</li>
	        				</ul>
	        			</nav>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
	import axios from "axios"
    import swal from "sweetalert2"

	export default {
		name: "CountriesComponent",

		data() {
			return {
				loading: false,
				data: null,
				pageNumber: this.$route.query.page || 1
			}
		},

		methods: {
			paginate(page) {
				this.pageNumber = page
				this.addOrUpdateURLParam("page", page)
				this.getData()
			},

			async getData() {
                this.loading = true
                const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
                try {
                	const formData = new FormData()
					formData.append("page", this.pageNumber)
					formData.append("timezone", timezone)

                    const response = await axios.post(
                        this.$apiURL + "/fetchCountries",
                        formData,
                        {
                            headers: this.$headers
                        }
                    )
                    if (response.data.status == "success") {
                        this.data = response.data.data
                        this.pages = response.data.pages
                    }
                } catch (error) {
                    if (error?.response?.status == 401) {
                        // swal.fire("Error", error.response.data.message || "Unauthorized", "error")
                    }
                } finally {
                    this.loading = false
                }
            }
		},

		mounted() {
            this.getData()
            document.title = "Countries"
        }
	}
</script>

<style scoped>
	.flag {
		width: 100px;
	    position: relative;
	    top: 50%;
	    transform: translateY(-50%);
	}
	.single-country {
		border: 1px dashed lightgray;
    	border-radius: 5px;
	}
	.country-info p {
		padding-top: 0px;
		padding-bottom: 0px;
		margin-bottom: 0px;
	}
</style>