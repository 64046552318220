<template>
	<!-- Main Section-->
    <section class="mt-0 ">
        <!-- Category Top Banner -->
        <div class="py-6 bg-img-cover bg-dark bg-overlay-gradient-dark position-relative overflow-hidden mb-4 bg-pos-center-center" style="background-image: url(./assets/images/banners/banner-1.jpg);">
            <div class="container position-relative z-index-20" data-aos="fade-right" data-aos-delay="300">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item breadcrumb-light">
                            <router-link to="/">Home</router-link>
                        </li>
                        <li class="breadcrumb-item active breadcrumb-light" aria-current="page">Seller</li>
                    </ol>
                </nav>
                <h1 class="fw-bold display-6 mb-4 text-white" v-text="user?.name"></h1>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <!-- Category Aside/Sidebar -->
                <div class="col-lg-12">
                	<div class="text-center loader" v-if="loading">
                        <div class="spinner-border"></div>
                    </div>
                </div>
            </div>
            <div class="row" style="margin-top: 50px;">
                <div class="offset-md-1 col-md-11">

                    <div class="row" id="container-chat">
                        <div class="col-md-3" id="contacts" style="background-color: #2d3d50; padding: 0px;">
                            <div class="form-group" style="margin-top: 10px; margin-left: 10px; margin-right: 10px;">
                                <input type="text" placeholder="Search Contacts..." class="form-control" v-on:input.prevent="oninputSearchContact" style="border-radius: 5px;" />
                            </div>

                            <div class="text-center loader" v-if="searchingContacts">
                                <div class="spinner-border"></div>
                            </div>

                            <ul style="padding: 0px; list-style-type: none;">
                                <li v-for="(contact, index) in contacts" v-on:click="contactSelected(contact.id)" v-bind:class="'contact' + (contact.id == id ? ' active' : '')">
                                    <div class="wrap">
                                        <div class="meta">
                                            <p class="name" v-text="contact.name"></p>
                                            <p class="preview" v-text="contact.lastMessage"></p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div class="col-md-9" style="padding-left: 0px;">
                            <div class="row" style="background-color: #f5f5f5; margin-left: 0px;">
                                <div class="col-4">
                                    <p v-text="user?.name" style="margin-bottom: 0px;
                                        position: relative;
                                        top: 50%;
                                        transform: translateY(-50%);
                                        margin-left: 20px;"></p>
                                </div>

                                <div class="col-8 pull-right" style="padding-right: 0px;">
                                    <button type="button" class="btn btn-success pull-right" v-on:click="createOrder" v-bind:disabled="creatingOrder">
                                        Create Order
                                        <i class="fa fa-spinner fa-spin" v-if="creatingOrder"></i>
                                    </button>
                                </div>
                            </div>

                            <div class="row" style="margin-left: 0px; overflow-y: scroll;">
                                <div class="col-md-12" style="background-color: #e6eaea;">
                                    <ul style="list-style-type: none; margin-top: 20px; padding-left: 20px; padding-right: 10px;">
                                        <template v-for="(chat, index) in chats">
                                            <li v-if="chat.type == 'message'" v-bind:class="(chat.senderEmail == me.email) ? 'replies text-right' : 'sent'" style="margin-top: 20px;">
                                                <p v-text="chat.message" v-if="(chat.senderEmail == me.email)" style="    background: #f5f5f5;
                                                    color: black;
                                                    display: inline-block;
                                                    padding: 7px 20px;
                                                    border-radius: 50px;
                                                    margin-bottom: 0px;"></p>

                                                <p v-text="chat.message" v-else style="    background: #435f7a;
                                                    color: #f5f5f5;
                                                    display: inline-block;
                                                    padding: 7px 20px;
                                                    border-radius: 50px;
                                                    margin-bottom: 0px;"></p>

                                                <p v-text="chat.sendAt" style="margin-bottom: 0px;
                                                    margin-top: 5px;
                                                    color: gray;
                                                    font-size: 12px;"></p>
                                            </li>

                                            <li v-if="chat.type == 'order'">
                                                <p v-html="'Amount: ' + chat.orderCurrency + ' ' + chat.orderAmount"></p>
                                                <p v-text="'Status: ' + chat.orderStatus"></p>
                                            </li>
                                        </template>
                                    </ul>
                                </div>
                            </div>

                            <div class="row" style="margin-left: 0px;">
                                <div class="input-group mb-3" style="padding-left: 0px; padding-right: 0px;">
                                    <input type="text" class="form-control" placeholder="Write your Message..." v-on:keyup.prevent="onkeyupInputMessage" v-model="message" />
                                    <div class="input-group-append">
                                        <button class="btn btn-primary" type="button" v-bind:disabled="sending" v-on:click="sendMessage"><i class="fa fa-paper-plane" aria-hidden="true"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-if="sending">
                <div class="col-md-12">
                    <div class="text-center loader">
                        <div class="spinner-border"></div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <div id="createOrderModal" class="modal">
        <!-- Modal content -->
        <div class="modal-content">
            <div class="modal-header">
                <h2>Create Order</h2>
                <span class="close">&times;</span>
            </div>
            <div class="modal-body">
                <i class="fa fa-spinner fa-spin" v-if="fetchingMyCars"></i>
                <form id="form-create-order" v-on:submit.prevent="doCreateOrder" v-else>
                    <div class="form-group">
                        <label>Select Car</label>
                        <select class="form-control" required name="car">
                            <option v-for="(car, index) in myCars" v-bind:value="car.auctionCarId" v-text="car.auctionCar.MARKA_NAME + ' ' + car.auctionCar.MODEL_NAME + ' - ' + car.auctionCar.YEAR"></option>
                        </select>
                    </div>

                    <div class="form-group">
                        <label>Enter Currency</label>
                        <input class="form-control" required name="currency" />
                    </div>

                    <div class="form-group">
                        <label>Enter Amount</label>
                        <input class="form-control" required name="amount" type="number" />
                    </div>
                </form>
            </div>

            <div class="modal-footer">
                <input type="reset" v-on:click="closeModal('#createOrderModal')" form="form-create-order" class="btn btn-danger" value="Cancel" />
                <button type="submit" form="form-create-order" class="btn btn-success" v-bind:disabled="creatingOrder">
                    Create Order
                    <i class="fa fa-spinner fa-spin" v-if="creatingOrder"></i>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
	import axios from "axios"
    import swal from "sweetalert2"
    import store from "../vuex/store"
    import jQuery from "jquery"

	export default {
		name: "ChatComponent",
		computed: {
			me() {
				return store.getters.getUser
			}
		},
		data() {
			return {
				id: this.$route.params.id || 0,
				user: null,
                contacts: [],
                chats: [],
				loading: false,
                chatPage: 1,
                sending: false,
                message: "",
                hasMoreChat: false,
                hasMoreContact: false,
                creatingOrder: false,
                fetchingMyCars: false,
                myCars: [],
                searchingContacts: false,
                timerSearchContact: null,
                queryContacts: ""
			}
		},
		methods: {
            onkeyupInputMessage() {
                if (event.keyCode == 13) {
                    this.sendMessage()
                }
            },

            oninputSearchContact() {
                const self = this
                const target = event.target
                clearTimeout(this.timerSearchContact)
                this.timerSearchContact = setTimeout(function () {
                    self.queryContacts = target.value
                    const hasSearched = true
                    self.getContacts(hasSearched)
                }, 500)
            },

            async doCreateOrder() {
                this.creatingOrder = true
                try {
                    const form = event.target
                    const formData = new FormData(form)
                    formData.append("id", this.id)
                    const response = await axios.post(
                        this.$apiURL + "/investor/createOrder",
                        formData,
                        {
                            headers: this.$headers
                        }
                    )
                    if (response.data.status == "success") {
                        swal.fire("Order Created", response.data.message, "success")
                        this.closeModal("#createOrderModal")
                    }  else {
                        swal.fire("Error", response.data.message || "Unauthorized", "error")
                    }
                } catch (error) {
                    if (error?.response?.status == 401) {
                        swal.fire("Error", error.response.data.message || "Unauthorized", "error")
                    }
                } finally {
                    this.creatingOrder = false
                }
            },
            async createOrder() {
                this.showModal("#createOrderModal")
                this.fetchingMyCars = true
                this.myCars = []
                try {
                    const formData = new FormData()
                    const response = await axios.post(
                        this.$apiURL + "/myPurchases",
                        formData,
                        {
                            headers: this.$headers
                        }
                    )
                    if (response.data.status == "success") {
                        this.myCars = response.data.data
                    }  else {
                        swal.fire("Error", response.data.message || "Unauthorized", "error")
                    }
                } catch (error) {
                    if (error?.response?.status == 401) {
                        swal.fire("Error", error.response.data.message || "Unauthorized", "error")
                    }
                } finally {
                    this.fetchingMyCars = false
                }

                /*const { value: value } = await swal.fire({
                    title: 'Input selling amount',
                    input: 'number',
                    inputLabel: 'Input selling amount',
                    inputPlaceholder: 'Input selling amount'
                })

                if (value) {
                    
                }*/
            },
            async contactSelected(id) {
                this.id = id
                this.chatPage = 1
                this.$router.push("/chat/" + id)
                this.getData()
            },
            async decryptMessage(message, iv) {
                try {
                    iv = new Uint8Array(atob(iv).split("").map(function(c) {
                        return c.charCodeAt(0)
                    }))
                    const initializationVector = new Uint8Array(iv).buffer
                    const string = atob(message)
                    const uintArray = new Uint8Array(
                        [...string].map((char) => char.charCodeAt(0))
                    )
                    const derivedKey = await this.getDerivedKey()
                    const decryptedData = await window.crypto.subtle.decrypt(
                        {
                            name: "AES-GCM",
                            iv: initializationVector,
                        },
                        derivedKey,
                        uintArray
                    )
                    return new TextDecoder().decode(decryptedData)
                } catch (e) {
                    // return `error decrypting message: ${e}`
                    return ""
                }
            },
            async getDerivedKey() {
                try {
                    const publicKey = await window.crypto.subtle.importKey(
                        "jwk",
                        this.user.publicKey,
                        {
                            name: "ECDH",
                            namedCurve: "P-256",
                        },
                        true,
                        []
                    )
                    const privateKey = await window.crypto.subtle.importKey(
                        "jwk",
                        this.me.privateKey,
                        {
                            name: "ECDH",
                            namedCurve: "P-256",
                        },
                        true,
                        ["deriveKey", "deriveBits"]
                    )
                    const derivedKey = await window.crypto.subtle.deriveKey(
                        { name: "ECDH", public: publicKey },
                        privateKey,
                        { name: "AES-GCM", length: 256 },
                        true,
                        ["encrypt", "decrypt"]
                    )
                    return derivedKey
                } catch (e) {
                    return null
                }
            },
            async sendMessage() {
                if (this.user == null) {
                    swal.fire("Error", "Please select a user first.", "error")
                    return
                }
                this.sending = true
                try {
                    const derivedKey = await this.getDerivedKey()
                    const encodedText = new TextEncoder().encode(this.message)
                    const iv = new TextEncoder().encode(new Date().getTime())
                    const b64encodedIv = btoa(new TextDecoder("utf8").decode(iv))
                    const encryptedData = await window.crypto.subtle.encrypt(
                        { name: "AES-GCM", iv: iv },
                        derivedKey,
                        encodedText
                    )
                    const uintArray = new Uint8Array(encryptedData)
                    const string = String.fromCharCode.apply(null, uintArray)
                    const base64Data = btoa(string)
                    const formData = new FormData()
                    formData.append("id", this.id)
                    formData.append("message", base64Data)
                    formData.append("iv", b64encodedIv)
                    const response = await axios.post(
                        this.$apiURL + "/chat/send",
                        formData,
                        {
                            headers: this.$headers
                        }
                    )
                    if (response.data.status == "success") {
                        const messageObj = response.data.messageObj
                        messageObj.message = await this.decryptMessage(messageObj.message, messageObj.iv)
                        this.chats.push(messageObj)
                        this.message = ""
                        setTimeout(function () {
                            jQuery(".messages").animate({ scrollTop: jQuery(document).height() }, "fast")
                        }, 100)
                        if (global.socketIO != null) {
                            global.socketIO.emit("newMessage", {
                                "messageObj": JSON.stringify(response.data.messageObj),
                                "id": this.user.email
                            })
                        }
                    } else if (response.data.status == "premiumMembersOnly") {
                        swal.fire("Error", response.data.message || "Unauthorized", "error") 
                    } else {
                        swal.fire("Error", response.data.message || "Unauthorized", "error")
                    }
                } catch (error) {
                    if (error?.response?.status == 401) {
                        swal.fire("Error", error.response.data.message || "Unauthorized", "error")
                    }
                } finally {
                    this.sending = false
                }
            },
            async getContacts(hasSearched = false) {
                const self = this
                this.loading = true
                try {
                    const formData = new FormData()
                    formData.append("id", this.id)
                    formData.append("search", this.queryContacts)
                    const response = await axios.post(
                        this.$apiURL + "/chat/fetchContacts",
                        formData,
                        {
                            headers: this.$headers
                        }
                    )
                    if (response.data.status == "success") {
                        this.contacts = response.data.contacts
                        this.hasMoreContact = response.data.hasMoreContact
                        if (this.id && !hasSearched) {
                            this.getData()
                        }
                    } else if (response.data.status == "premiumMembersOnly") {
                        swal.fire("Error", response.data.message || "Unauthorized", "error") 
                    } else {
                        swal.fire("Error", response.data.message || "Unauthorized", "error")
                    }
                } catch (error) {
                    if (error?.response?.status == 401) {
                        swal.fire("Error", error.response.data.message || "Unauthorized", "error")
                    }
                } finally {
                    this.loading = false
                }
            },
			async getData() {
                if (global.socketIO != null) {
                    global.socketIO.off("newMessage")
                }
                const self = this
                const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
                this.chats = []
                this.loading = true
                try {
                    const formData = new FormData()
                    formData.append("id", this.id)
                    formData.append("page", this.chatPage)
                    formData.append("timezone", timezone)
                    const response = await axios.post(
                        this.$apiURL + "/chat/fetch",
                        formData,
                        {
                            headers: this.$headers
                        }
                    )
                    if (response.data.status == "success") {
                        this.user = response.data.user
                        // if (this.contacts.length == 0) {
                        //     this.contacts = response.data.contacts
                        //     this.hasMoreContact = response.data.hasMoreContact
                        // }
                        this.hasMoreChat = response.data.hasMoreChat
                        this.me.privateKey = response.data.privateKey
                        const chats = response.data.chats
                        for (let a = 0; a < chats.length; a++) {
                            chats[a].message = await this.decryptMessage(chats[a].message, chats[a].iv)
                        }
                        this.chats = chats
                        if (global.socketIO != null) {
                            global.socketIO.on("newMessage", function (messageObj) {
                                messageObj = JSON.parse(messageObj)
                                if (messageObj.senderId == self.id) {
                                    self.chats.push(messageObj)
                                    setTimeout(function () {
                                        jQuery(".messages").animate({ scrollTop: jQuery(document).height() }, "fast")
                                    }, 100)
                                }
                            })
                        }
                    } else if (response.data.status == "premiumMembersOnly") {
                    	swal.fire("Error", response.data.message || "Unauthorized", "error") 
                    } else {
                    	swal.fire("Error", response.data.message || "Unauthorized", "error")
                    }
                } catch (error) {
                    if (error?.response?.status == 401) {
                        swal.fire("Error", error.response.data.message || "Unauthorized", "error")
                    }
                } finally {
                    this.loading = false

                    setTimeout(function () {
                        jQuery(".messages").animate({ scrollTop: jQuery(document).height() }, "fast")
                    }, 100)

                    /*const formData1 = new FormData()
                    formData1.append("name", "adnan")
                    formData1.append("age", 30)
                    await axios.post(
                        this.$nodeURL + "/test",
                        formData1
                    )*/
                }
            }
        },
		mounted() {
            const $ = jQuery
            const self = this
			this.getContacts()
            setTimeout(function () {
                $(".messages").animate({ scrollTop: $(document).height() }, "fast")
                $("#profile-img").click(function() {
                    $("#status-options").toggleClass("active")
                })
                $(".expand-button").click(function() {
                    $("#profile").toggleClass("expanded")
                    $("#contacts").toggleClass("expanded")
                })
                $("#status-options ul li").click(function() {
                    $("#profile-img").removeClass()
                    $("#status-online").removeClass("active")
                    $("#status-away").removeClass("active")
                    $("#status-busy").removeClass("active")
                    $("#status-offline").removeClass("active")
                    $(this).addClass("active")
                    if($("#status-online").hasClass("active")) {
                        $("#profile-img").addClass("online")
                    } else if ($("#status-away").hasClass("active")) {
                        $("#profile-img").addClass("away")
                    } else if ($("#status-busy").hasClass("active")) {
                        $("#profile-img").addClass("busy")
                    } else if ($("#status-offline").hasClass("active")) {
                        $("#profile-img").addClass("offline")
                    } else {
                        $("#profile-img").removeClass()
                    }
                    $("#status-options").removeClass("active")
                })
            }, 1000)

            document.title = "Private Chat"
		},
        watch: {
            $route: function (to, from) {
                if (global.socketIO != null) {
                    global.socketIO.off("newMessage")
                }
            }
        }
	}
</script>

<style scoped>
@media only screen and (max-width: 480px) {
    #container-chat {
        height: fit-content !important;
    }
}
.text-right {
    text-align: right !important;
}
#container-chat {
    /*height: 350px;*/
}
/*.replies {
    float: right;
    clear: both;
}*/
#contacts .contact:hover,
#contacts .contact.active {
    background-color: #32465a;
}
#contacts .contact.active {
    border-right: 10px solid #445f7a;
}
#contacts {
    color: white;
}
#contacts .contact {
    list-style-type: none;
    cursor: pointer;
    padding: 10px 20px;
}
</style>