<template>
	<div class="row">
		<div class="col-md-12">
			<div class="text-center loader" v-if="loading">
		        <div class="spinner-border"></div>
		    </div>

            <table v-if="!loading" class="table" id="data-table">
            	<thead>
                    <tr v-if="$isMobile()">
                        <th></th>
                    </tr>

                    <tr v-else>
                    	<th>Amount</th>
                    	<th>Type</th>
                    	<th>Payment Method</th>
                    	<th>Description</th>
                    	<th>Banking Fee</th>
                    	<th>Shipping Charges</th>
                    	<th>Shipping Discount</th>
                    	<th>Taxes</th>
                    	<th>Time</th>
                    </tr>
                </thead>

                <tbody>
                    <template v-for="(d, index) in data">
                    	<tr v-if="$isMobile()">
                    		<th>
                    			<table>
									<tr>
										<th>Amount</th>
										<td v-text="d.currency + ' ' + d.amount"></td>
									</tr>

									<tr>
										<th>Type</th>
										<td style="text-transform: capitalize;" v-text="d.type.replace(/([a-z])([A-Z])/g, '$1 $2')"></td>
									</tr>

									<tr>
										<th>Payment Method</th>
										<td>
											<span v-text="d.paymentMethod" style="text-transform: capitalize;"></span>

											<span v-if="d.paymentMethod == 'bank'">
												(<span v-text="d.bank.accountTitle"></span>

													|

													<span v-text="d.bank.accountNumber"></span>)
											</span>

											<span v-if="d.paymentMethod == 'cheque'">
												(#<span v-text="d.cheque"></span>)
											</span>
										</td>
									</tr>

									<tr>
										<th>Description</th>
										<td v-html="d.description"></td>
									</tr>

									<tr>
										<th>Banking Fee</th>
										<td v-text="d.currency + ' ' + d.bankingFee"></td>
									</tr>

									<tr>
										<th>Shipping Charges</th>
										<td v-text="d.currency + ' ' + d.shippingCharges"></td>
									</tr>

									<tr>
										<th>Shipping Discount</th>
										<td v-text="d.currency + ' ' + d.shippingDiscount"></td>
									</tr>

									<tr>
										<th>Taxes</th>
										<td v-text="d.currency + ' ' + d.taxes"></td>
									</tr>

									<tr>
										<th>Time</th>
										<td v-text="d.created_at"></td>
									</tr>
                    			</table>
                    		</th>
                    	</tr>

                    	<tr v-else>
                    		<td v-text="d.currency + ' ' + d.amount"></td>
							<td style="text-transform: capitalize;" v-text="d.type.replace(/([a-z])([A-Z])/g, '$1 $2')"></td>
							<td>
								<span v-text="d.paymentMethod" style="text-transform: capitalize;"></span>

								<span v-if="d.paymentMethod == 'bank'">
									(<span v-text="d.bank.accountTitle"></span>

										|

										<span v-text="d.bank.accountNumber"></span>)
								</span>

								<span v-if="d.paymentMethod == 'cheque'">
									(#<span v-text="d.cheque"></span>)
								</span>
							</td>
							<td v-html="d.description"></td>
							<td v-text="d.currency + ' ' + d.bankingFee"></td>
							<td v-text="d.currency + ' ' + d.shippingCharges"></td>
							<td v-text="d.currency + ' ' + d.shippingDiscount"></td>
							<td v-text="d.currency + ' ' + d.taxes"></td>
							<td v-text="d.created_at"></td>
                    	</tr>
					</template>
				</tbody>
            </table>

            <div v-if="pages > 0" id="pagination">
				<nav aria-label="Page navigation">
					<ul class="pagination">
						<li v-for="page in pages" v-bind:class="'page-item ' + (page == pageNumber ? 'active' : '')">
							<a class="page-link" href="javascript:void(0)" v-on:click="paginate(page)" v-text="page"></a>
						</li>
					</ul>
				</nav>
			</div>

		</div>
	</div>
</template>

<script>
	import axios from "axios"
    import swal from "sweetalert2"
    import jQuery from "jquery"

    import "../assets/datatable/datatables.css"
    import "../assets/datatable/datatables.min.js"

	export default {
		name: "TransactionsComponent",

		data() {
			return {
				loading: false,
				data: [],
				dataTable: null,
				total: 0,
				pages: 0,
				pageNumber: this.$route.query.page || 1
			}
		},

		methods: {

			paginate: function (page) {
				this.pageNumber = page
				this.addOrUpdateURLParam("page", page)

				this.getData()
			},

        	getData: async function () {
        		const self = this
        		const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
        		this.pages = 0

        		this.loading = true
        		this.data = []

        		if (this.dataTable != null) {
	            	this.dataTable.destroy()
	            }

        		const formData = new FormData()
        		formData.append("search", this.search)
        		formData.append("page", this.pageNumber)
        		formData.append("timezone", timezone)

        		try {
				    const response = await axios.post(
				        this.$apiURL + "/myTransactions",
				        formData,
				        {
				            headers: this.$headers
				        }
				    )
				 
				    if (response.data.status == "success") {
				    	this.data = response.data.data
				    	this.pages = response.data.pages
				    } else {
				        swal.fire("Error", response.data.message, "error")
				    }
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.loading = false

					setTimeout(function () {
			    		self.initialize()
			    	}, 100)
				}
			},

			initialize() {
				if (this.dataTable != null) {
					return
				}

				if (this.$isMobile()) {
					this.dataTable = jQuery("#data-table").DataTable({
		            	ordering: false,
	                    pageLength: 1
		            })
				} else {
					this.dataTable = jQuery("#data-table").DataTable({
		            	ordering: false
		            })
				}
	        }
        },

		mounted() {
        	this.getData()
        	document.title = "Transactions"
        }
	}
</script>