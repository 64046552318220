import { createStore } from "vuex"

export default createStore({
	state() {
		return {
			search: {},
			user: null,
            loadingUser: false,
            unreadNotifications: 0
		}
	},

	getters: {
		getUnreadNotifications (state) {
            return state.unreadNotifications
        },

        getLoadingUser (state) {
            return state.loadingUser
        },

        getUser (state) {
            return state.user
        },

		getSearch(state) {
			return state.search
		}
	},

	mutations: {
		setUnreadNotifications (state, val) {
            state.unreadNotifications = val
        },

        setLoadingUser (state, val) {
            state.loadingUser = val
        },

        setUser (state, val) {
            state.user = val
        },

		setSearch(state, val) {
			state.search = val
		}
	}
})