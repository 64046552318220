<template>
	<!-- Category Top Banner -->
    <div class="py-6 bg-img-cover bg-dark bg-overlay-gradient-dark position-relative overflow-hidden mb-4 bg-pos-center-center" style="background-image: url(./assets/images/banners/banner-1.jpg);" v-if="auctionCar != null">
        <div class="container position-relative z-index-20" data-aos1="fade-right" data-aos-delay1="300">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item breadcrumb-light">
                        <router-link to="/">Home</router-link>
                    </li>
                    <li class="breadcrumb-item active breadcrumb-light" aria-current="page" v-text="auctionCar.MARKA_NAME + ' ' + auctionCar.MODEL_NAME + ' - ' + auctionCar.YEAR"></li>
                </ol>
            </nav>
            <h1 class="fw-bold display-6 mb-4 text-white" v-text="auctionCar.MARKA_NAME + ' ' + auctionCar.MODEL_NAME + ' - ' + auctionCar.YEAR"></h1>
        </div>
    </div>
    <div class="row" v-if="auctionCar != null">
    	<div class="col-md-12">
    		<div class="owl-carousel owl-theme owl-purchased-cars">
                <div v-for="(img, index) in auctionCar.IMAGES" style="width: fit-content !important;">
                    <div class="card position-relative h-100 card-listing hover-trigger">
                        <div class="card-header">
                            <picture class="position-relative overflow-hidden d-block bg-light">
                                <img class="w-100 img-fluid position-relative z-index-10" v-bind:title="auctionCar.MARKA_NAME + ' ' + auctionCar.MODEL_NAME" v-bind:src="getImage(auctionCar.IMAGES, index)" v-bind:alt="auctionCar.MARKA_NAME + ' ' + auctionCar.MODEL_NAME" style="width: 300px !important;" />
                            </picture>
                        </div>
                    </div>
                </div>
            </div>
    	</div>
    </div>
	<div class="row" style="background-color: rgb(146 57 57); border-radius: 5px;">
		<div class="col-md-12">
			<div class="text-center loader text-white" v-if="loading">
		        <div class="spinner-border"></div>
		    </div>
		    <template v-for="(d, index) in data">
		    	<div class="row log-block">
		    		<div class="col-md-12">
		    			<h2 v-text="d.created_at" class="title"></h2>
		    			<div class="row single-log" v-for="(log, indexLog) in d.logs">
		    				<div class="col-md-4">
    							<span v-text="log.log"></span>
    						</div>
    						<div class="col-md-4">
    							<template v-for="(attachment, indexAttachment) in log.attachments">
    								<a v-bind:href="attachment" v-text="attachment.split('/')[attachment.split('/').length - 1]" target="_blank"></a>
    							</template>
    						</div>
    						<div class="col-md-4" style="text-align: right;">
    							<span v-text="log.created_at"></span>
    						</div>
		    			</div>
		    		</div>
		    	</div>
		    </template>
		</div>
	</div>
</template>

<script>
	import axios from "axios"
    import swal from "sweetalert2"
    import jQuery from "jquery"
    import "../assets/owl-carousel/owl.carousel.css"
    import "../assets/owl-carousel/owl.theme.default.css"
    import "../assets/owl-carousel/owl.carousel.js"

	export default {
		name: "TrackingComponent",
		data() {
			return {
				loading: false,
				data: [],
				total: 0,
				pages: 0,
				pageNumber: this.$route.query.page || 1,
				auctionCar: null
			}
		},
		computed: {
			id() {
				const search = new URLSearchParams(window.location.search)
				return search.get("id")
			}
		},
		methods: {
			paginate: function (page) {
				this.pageNumber = page
				this.addOrUpdateURLParam("page", page)
				this.getData()
			},
        	getData: async function () {
        		const self = this
        		this.loading = true
        		this.data = []
        		const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
        		const formData = new FormData()
        		formData.append("id", this.id)
        		formData.append("page", this.pageNumber)
        		formData.append("timezone", timezone)
        		try {
        			const response = await axios.post(
				        this.$apiURL + "/tracking/fetch",
				        formData,
				        {
				            headers: this.$headers
				        }
				    )
				    if (response.data.status == "success") {
				    	this.data = response.data.data
				    	this.pages = response.data.pages
						this.pageNumber = response.data.pageNumber
						this.auctionCar = response.data.auctionCar
				    } else {
				        swal.fire("Error", response.data.message, "error")
				    }
        		} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.loading = false
					setTimeout(function () {
			    		self.initialize()
			    	}, 100)
				}
			},
			initialize() {
				setTimeout(function () {
                    jQuery(".owl-carousel").owlCarousel({
                        autoWidth: true,
                        dots: false,
                        nav: true
                    })
                    jQuery(".owl-purchased-cars .owl-nav .owl-next").css({
                        fontSize: "50px"
                    })
                    jQuery(".owl-purchased-cars .owl-nav .owl-prev").css({
                        fontSize: "50px"
                    })
                }, 500)
	        },
	    },
	    mounted() {
	    	this.getData()
	    	document.title = "Tracking"
	    }
	}
</script>

<style scoped>
	.log-block:last-child .single-log:last-child {
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
	}
	.single-log {
		background-color: #ebebeb;
		padding: 10px;
		border-bottom-left-radius: 0px;
		border-top-left-radius: 0px;
		border-bottom-right-radius: 0px;
		border-top-right-radius: 0px;
	}
	.title {
		color: white;
	    padding: 10px;
	    text-align: right;
	    margin-bottom: 0px;
	}
</style>