<template>
	<!-- Main Section-->
    <section class="mt-0 ">
        <!-- Category Top Banner -->
        <div class="py-6 bg-img-cover bg-dark bg-overlay-gradient-dark position-relative overflow-hidden mb-4 bg-pos-center-center" style="background-image: url(./assets/images/banners/banner-1.jpg);">
            <div class="container position-relative z-index-20" data-aos="fade-right" data-aos-delay="300">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item breadcrumb-light">
                            <router-link to="/">Home</router-link>
                        </li>
                        <li class="breadcrumb-item active breadcrumb-light" aria-current="page" v-text="page?.title"></li>
                    </ol>
                </nav>
                <h1 class="fw-bold display-6 mb-4 text-white" v-text="page?.title"></h1>
            </div>
        </div>

        <!-- Category Top Banner -->
        <div class="container">
            <div class="row">
                <!-- Category Aside/Sidebar -->
                <div class="col-lg-12">
                	<div class="text-center loader" v-if="loading">
                        <div class="spinner-border"></div>
                    </div>

                    <div id="content" v-html="page?.content"></div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
	import axios from "axios"
    import swal from "sweetalert2"

	export default {
		name: "FAQComponent",

		data() {
			return {
				loading: false,
				page: null
			}
		},

		methods: {
			async getData() {
                this.loading = true
                try {
                    const formData = new FormData()
                    formData.append("page", "faq")
                    const response = await axios.post(
                        this.$apiURL + "/fetchPage",
                        formData,
                        {
                            headers: this.$headers
                        }
                    )
                    if (response.data.status == "success") {
                        this.page = response.data.page
                    }
                } catch (error) {
                    if (error?.response?.status == 401) {
                        // swal.fire("Error", error.response.data.message || "Unauthorized", "error")
                    }
                } finally {
                    this.loading = false
                }
            }
		},

		mounted() {
            this.getData()
            document.title = "FAQs"
        }
	}
</script>