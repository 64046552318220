<template>
    <!-- Main Section-->
    <section class="mt-0 ">
        <!-- Page Content Goes Here -->
        <!-- / Hero Section -->
        <section class="vh-100 position-relative bg-overlay-dark ">
            <div class="container d-flex h-100 justify-content-center align-items-center position-relative z-index-10">
                <div class="d-flex justify-content-center align-items-center h-100 position-relative z-index-10 text-white">
                    <div>
                        <h1 class="display-1 fw-bold px-2 px-md-5 text-center mx-auto col-lg-8 mt-md-0" data-aos1="fade-up" data-aos1-delay="1000">Where will your next car take you?</h1>
                        <div data-aos1="fade-in" data-aos1-delay="2000">
                            <div class="d-md-flex justify-content-center mt-4 mb-3 my-md-5">
                                <router-link to="/search?query=japanese" class="btn btn-skew-left btn-orange btn-orange-chunky text-white mx-1 w-100 w-md-auto mb-2 mb-md-0"><span>Shop
                                        Japanese <i class="ri-arrow-right-line align-middle fw-bold"></i></span></router-link>
                                <router-link to="/search?query=european" class="btn btn-skew-left btn-orange btn-orange-chunky text-white mx-1 w-100 w-md-auto mb-2 mb-md-0"><span>Shop
                                        European <i class="ri-arrow-right-line align-middle fw-bold"></i></span></router-link>
                            </div>
                            <i class="ri-mouse-line d-block text-center animation-float ri-2x transition-all opacity-50-hover text-white" data-pixr-scrollto data-target=".brand-section" data-aos1="fade-up" data-aos1-delay="700"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="position-absolute z-index-1 top-0 bottom-0 start-0 end-0">
                <!-- Swiper Info -->
                <div class="swiper-container overflow-hidden bg-light w-100 h-100" data-swiper data-options='{
                    "slidesPerView": 1,
                    "speed": 1500,
                    "loop": true,
                    "effect": "none",
                    "autoplay": {
                      "delay": 5000
                    }
                  }'>
                    <div class="swiper-wrapper">
                        <div class="swiper-slide position-relative">
                            <div class="w-100 h-100 bg-img-cover animation-move bg-pos-center-center" v-bind:style="{ 'backgroundImage': 'url(' + require('../assets/images/cars/kia.jpg') + ')' }">
                            </div>
                        </div>
                    </div>
                </div>
                <!-- / Swiper Info-->
            </div>
        </section>
        <!--/ Hero Section-->
        <!-- Featured Brands-->
        <div class="mb-lg-7 bg-light py-4" data-aos1="fade-in">
            <div class="container">
                <div class="row gx-3 align-items-center">
                    <div class="col-12 justify-content-center justify-content-md-between align-items-center d-flex flex-wrap">
                        <div class="me-2 f-w-20 m-4 ms-md-0 mt-md-0 mb-md-0">
                            <router-link class="d-block" to="/search?query=toyota" data-bs-toggle="tooltip" data-bs-placement="top" title="Toyota">
                                <img class="img-fluid d-table mx-auto" src="../assets/images/brands/toyota.png" alt="Toyota">
                            </router-link>
                        </div>
                        <div class="me-2 f-w-20 m-4 ms-md-0 mt-md-0 mb-md-0">
                            <router-link class="d-block" to="/search?query=bmw" data-bs-toggle="tooltip" data-bs-placement="top" title="BMW">
                                <img class="img-fluid d-table mx-auto" src="../assets/images/brands/bmw.png" alt="BMW">
                            </router-link>
                        </div>
                        <div class="me-2 f-w-20 m-4 ms-md-0 mt-md-0 mb-md-0">
                            <router-link class="d-block" to="/search?query=honda" data-bs-toggle="tooltip" data-bs-placement="top" title="Honda">
                                <img class="img-fluid d-table mx-auto" src="../assets/images/brands/honda.png" alt="Honda">
                            </router-link>
                        </div>
                        <div class="me-2 f-w-20 m-4 ms-md-0 mt-md-0 mb-md-0">
                            <router-link class="d-block" to="/search?query=mercedes" data-bs-toggle="tooltip" data-bs-placement="top" title="Mercedes Benz">
                                <img class="img-fluid d-table mx-auto" src="../assets/images/brands/mercedes.png" alt="Mercedes Benz">
                            </router-link>
                        </div>
                        <div class="me-2 f-w-20 m-4 ms-md-0 mt-md-0 mb-md-0">
                            <router-link class="d-block" to="/search?query=nissan" data-bs-toggle="tooltip" data-bs-placement="top" title="Nissan">
                                <img class="img-fluid d-table mx-auto" src="../assets/images/brands/nissan.png" alt="Nissan">
                            </router-link>
                        </div>
                        <div class="me-2 f-w-20 m-4 ms-md-0 mt-md-0 mb-md-0">
                            <router-link class="d-block" to="/search?query=suzuki" data-bs-toggle="tooltip" data-bs-placement="top" title="Suzuki">
                                <img class="img-fluid d-table mx-auto" src="../assets/images/brands/suzuki.png" alt="Suzuki">
                            </router-link>
                        </div>
                        <router-link to="/search" class="btn btn-link fw-bolder">Explore All Brands <i class="ri-arrow-right-line align-bottom fw-bold"></i></router-link>
                    </div>
                </div>
            </div>
        </div>
        <!--/ Featured Brands-->
        <!-- featured-->
        <section class="mb-9 mt-5" data-aos1="fade-up" v-if="featured.length > 0">
            <div class="container">
                <div class="w-md-50 mb-5">
                    <p class="small fw-bolder text-uppercase tracking-wider mb-2 text-muted">Featured</p>
                    <h2 class="display-5 fw-bold mb-3">Cars for Sale</h2>
                    <p class="lead">We've sorted through our feed to put together a collection of our products perfect for your everyday drive.</p>
                </div>

                <div class="owl-carousel owl-theme owl-featured-cars">
                    <div v-for="(d, index) in featured" style="width: fit-content !important;">
                        <div class="card position-relative h-100 card-listing hover-trigger">
                            <div class="card-header">
                                <picture class="position-relative overflow-hidden d-block bg-light">
                                    <img class="w-100 img-fluid position-relative z-index-10" v-bind:title="d.MARKA_NAME + ' ' + d.MODEL_NAME" v-bind:src="getImage(d.IMAGES, 1)" v-bind:alt="d.MARKA_NAME + ' ' + d.MODEL_NAME" style="width: 300px !important;" />
                                </picture>
                                <picture class="position-absolute z-index-20 start-0 top-0 hover-show bg-light">
                                    <img class="w-100 img-fluid" v-bind:title="d.MARKA_NAME + ' ' + d.MODEL_NAME" v-bind:src="getImage(d.IMAGES, 2)" v-bind:alt="d.MARKA_NAME + ' ' + d.MODEL_NAME" style="width: 300px !important;" />
                                </picture>
                                <div class="card-actions">
                                    <span class="small text-uppercase tracking-wide fw-bolder text-center d-block" v-text="d.MILEAGE + ' km'"></span>
                                    <div class="d-flex justify-content-center align-items-center flex-wrap mt-3">
                                        <button class="btn btn-outline-dark btn-sm mx-2" v-text="'Grade: ' + d.RATE"></button>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body px-0 text-center">
                                <router-link class="mb-0 mx-2 mx-md-4 fs-p link-cover text-decoration-none d-block text-center" v-bind:to="'/carsForSale/' + d.id" v-text="d.MARKA_NAME + ' ' + d.MODEL_NAME + ' - ' + d.YEAR"></router-link>
                                <p class="fw-bolder m-0 mt-2" v-html="d.currency + d.price"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- / featured-->
        <!-- Staff Picks-->
        <section class="mb-9 mt-5" data-aos1="fade-up">
            <div class="container">
                <div class="w-md-50 mb-5">
                    <p class="small fw-bolder text-uppercase tracking-wider mb-2 text-muted">Auctions</p>
                    <h2 class="display-5 fw-bold mb-3">Upcoming Vehicles</h2>
                    <p class="lead">We've sorted through our feed to put together a collection of our products perfect for your everyday drive.</p>
                </div>

                <div class="owl-carousel owl-theme owl-upcoming-cars">
                    <div v-for="(d, index) in upcomingCars" style="width: fit-content !important;">
                        <div class="card position-relative h-100 card-listing hover-trigger">
                            <div class="card-header">
                                <picture class="position-relative overflow-hidden d-block bg-light">
                                    <img class="w-100 img-fluid position-relative z-index-10" v-bind:title="d.MARKA_NAME + ' ' + d.MODEL_NAME" v-bind:src="getImage(d.IMAGES, 0)" v-bind:alt="d.MARKA_NAME + ' ' + d.MODEL_NAME" style="width: 300px !important;" />
                                </picture>
                                <picture class="position-absolute z-index-20 start-0 top-0 hover-show bg-light">
                                    <img class="w-100 img-fluid" v-bind:title="d.MARKA_NAME + ' ' + d.MODEL_NAME" v-bind:src="getImage(d.IMAGES, 1)" v-bind:alt="d.MARKA_NAME + ' ' + d.MODEL_NAME" style="width: 300px !important;" />
                                </picture>
                                <div class="card-actions">
                                    <span class="small text-uppercase tracking-wide fw-bolder text-center d-block" v-text="d.MILEAGE + ' km'"></span>
                                    <div class="d-flex justify-content-center align-items-center flex-wrap mt-3">
                                        <button class="btn btn-outline-dark btn-sm mx-2" v-text="'Grade: ' + d.RATE"></button>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body px-0 text-center">
                                <router-link class="mb-0 mx-2 mx-md-4 fs-p link-cover text-decoration-none d-block text-center" v-bind:to="'/carDetail/' + d.ID" v-text="d.MARKA_NAME + ' ' + d.MODEL_NAME + ' - ' + d.YEAR"></router-link>
                                <p class="fw-bolder m-0 mt-2" v-html="'&yen;' + d.START"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- / Staff Picks-->
        <!-- Image Hotspot Banner-->
        <section class="my-10 position-relative">
            <!-- SVG Shape Divider-->
            <div class="position-absolute z-index-50 text-white top-0 start-0 end-0">
                <svg class="align-self-start d-flex" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1283 53.25">
                    <polygon fill="currentColor" points="1283 0 0 0 0 53.25 1283 0" /></svg></div>
            <!-- /SVG Shape Divider-->
            <div class="w-100 h-100 bg-img-cover bg-pos-center-center hotspot-container py-5 py-md-7 py-lg-10" st1yle="background-image: url(https://images.unsplash.com/photo-1508746829417-e6f548d8d6ed?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80);" v-bind:style="{'background-image': 'url(' + require('../assets/images/cars/audi.jpg') + ')'}">
                <div class="container py-lg-8 position-relative z-index-10 d-flex align-items-center" data-aos1="fade-left" style="padding-top: 0px !important;">
                    <div class="py-8 d-flex flex-column col-lg-4" style="color: white; text-align: justify; padding-top: 0px !important;">
                        <p class="small fw-bolder text-uppercase tracking-wider mb-2">Extreme Performance</p>
                        <h2 class="display-5 fw-bold mb-3">Audi</h2>
                        <p class="lead d-none d-lg-block">Be ready all year round with our selection of Audi cars — perfect for any time of the year and year round. Choose from a variety of colour shades and styles to warm you up on the road.</p>
                        <router-link class="text-decoration-none fw-bolder" to="/search?query=audi" style="color: white;">Shop Now &rarr;</router-link>
                    </div>
                </div>
            </div>
            <!-- SVG Shape Divider-->
            <div class="position-absolute z-index-50 text-white bottom-0 start-0 end-0">
                <svg class="align-self-end d-flex" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1283 53.25">
                    <polygon fill="currentColor" points="0 53.25 1283 53.25 1283 0 0 53.25" /></svg></div>
            <!-- /SVG Shape Divider-->
        </section>
        <!-- / Image Hotspot Banner-->
        <!-- Linked Product Carousels-->
        <section class="py-5" data-aos1="fade-in">
            <div class="container">

                <div class="row g-5">
                    <div class="col-12 col-md-7" data-aos1="fade-right">
                        <div class="m-0">
                            <p class="small fw-bolder text-uppercase tracking-wider mb-2 text-muted">Racing Essentials
                            </p>
                            <h2 class="display-5 fw-bold mb-6">Super Cars Collection</h2>
                        </div>
                    </div>
                </div>

                <div class="row g-5">
                    <div class="col-12 col-md-7" data-aos1="fade-right">
                        <div class="m-0">
                            <div class="px-8 position-relative">
                                <!-- Swiper-->
                                <div class="swiper-container swiper-linked-carousel-small">
                                    <!-- Add Pagination -->
                                    <div class="swiper-pagination-blocks mb-4">
                                        <div class="swiper-pagination-custom"></div>
                                    </div>
                                    <div class="swiper-wrapper">
                                        <!-- Swiper Slide-->
                                        <div class="swiper-slide overflow-hidden">
                                            <!-- Card-->
                                            <!-- Card Product-->
                                            <div class="card position-relative h-100 card-listing hover-trigger" v-if="superCars.length > 0">
                                                <div class="card-header">
                                                    <picture class="position-relative overflow-hidden d-block bg-light">
                                                        <img class="w-100 img-fluid position-relative" v-bind:title="superCars[0].MARKA_NAME + ' ' + superCars[0].MODEL_NAME" v-bind:src="superCars[0].IMAGES[0] ?? ''" v-bind:alt="superCars[0].MARKA_NAME + ' ' + superCars[0].MODEL_NAME" />
                                                    </picture>

                                                    <picture class="w-100 position-absolute z-index-20 start-0 top-0 hover-show bg-light d-block">
                                                        <img class="w-100 img-fluid position-relative z-index-10" v-bind:title="superCars[0].MARKA_NAME + ' ' + superCars[0].MODEL_NAME" v-bind:src="superCars[0].IMAGES[1] ?? ''" v-bind:alt="superCars[0].MARKA_NAME + ' ' + superCars[0].MODEL_NAME" />
                                                    </picture>

                                                    <div class="card-actions">
                                                        <span class="small text-uppercase tracking-wide fw-bolder text-center d-block" v-text="superCars[0].MILEAGE + ' km'"></span>
                                                        <div class="d-flex justify-content-center align-items-center flex-wrap mt-3">
                                                            <button class="btn btn-outline-dark btn-sm mx-2" v-text="'Grade: ' + superCars[0].RATE"></button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card-body px-0 text-center">
                                                    <router-link class="mb-0 mx-2 mx-md-4 fs-p link-cover text-decoration-none d-block text-center" v-bind:to="'/carDetail/' + superCars[0].ID" v-text="superCars[0].MARKA_NAME + ' ' + superCars[0].MODEL_NAME + ' - ' + superCars[0].YEAR"></router-link>
                                                    <p class="fw-bolder m-0 mt-2" v-html="'&yen;' + superCars[0].START"></p>
                                                </div>
                                            </div>
                                            <!--/ Card Product-->
                                            <!--/ Card-->
                                        </div>
                                        <!-- / Swiper Slide-->
                                    </div>
                                </div> <!-- /Swiper-->
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5 d-none d-md-flex" data-aos1="fade-left">
                        <div class="w-100 h-100">
                            <!-- Swiper-->
                            <div class="swiper-container h-100 swiper-linked-carousel-large">
                                <div class="swiper-wrapper h-100">
                                    <!-- Swiper Slide-->
                                    <div class="swiper-slide">
                                        <div class="row g-3">
                                            <template v-for="(d, index) in superCars">
                                                <div class="col-12 col-md-6" v-if="index < 5 && index > 0">
                                                    <!-- Card Product-->
                                                    <div class="card position-relative h-100 card-listing hover-trigger">
                                                        <div class="card-header">
                                                            <picture class="position-relative overflow-hidden d-block bg-light">
                                                                <img class="w-100 img-fluid position-relative z-index-10" v-bind:title="d.MARKA_NAME + ' ' + d.MODEL_NAME" v-bind:src="getImage(d.IMAGES, 0)" v-bind:alt="d.MARKA_NAME + ' ' + d.MODEL_NAME" />
                                                            </picture>

                                                            <picture class="position-absolute z-index-20 start-0 top-0 hover-show bg-light">
                                                                <img class="w-100 img-fluid position-relative z-index-10" v-bind:title="d.MARKA_NAME + ' ' + d.MODEL_NAME" v-bind:src="getImage(d.IMAGES, 1)" v-bind:alt="d.MARKA_NAME + ' ' + d.MODEL_NAME" />
                                                            </picture>

                                                            <div class="card-actions">
                                                                <span class="small text-uppercase tracking-wide fw-bolder text-center d-block" v-text="d.MILEAGE + ' km'"></span>
                                                                <div class="d-flex justify-content-center align-items-center flex-wrap mt-3">
                                                                    <button class="btn btn-outline-dark btn-sm mx-2" v-text="'Grade: ' + d.RATE"></button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card-body px-0 text-center">
                                                            <router-link class="mb-0 mx-2 mx-md-4 fs-p link-cover text-decoration-none d-block text-center" v-bind:to="'/carDetail/' + d.ID" v-text="d.MARKA_NAME + ' ' + d.MODEL_NAME + ' - ' + d.YEAR"></router-link>
                                                            <p class="fw-bolder m-0 mt-2" v-html="'&yen;' + d.START"></p>
                                                        </div>
                                                    </div>
                                                    <!--/ Card Product-->
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                    <!-- /Swiper Slide-->
                                </div>
                            </div> <!-- / Swiper-->
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- / Linked Product Carousels-->
        <!-- Sale Banner -->
        <section class="position-relative my-5 my-md-7 my-lg-9 bg-dark" data-aos1="fade-in">
            <!-- SVG Shape Divider-->
            <div class="position-absolute text-white z-index-50 top-0 end-0 start-0">
                <svg class="align-self-start d-flex" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1283 53.25">
                    <polygon fill="currentColor" points="1283 0 0 0 0 53.25 1283 0" /></svg></div>
            <!-- /SVG Shape Divider-->
            <div class="py-7 py-lg-10">
                <div class="container text-white py-4 py-md-6">
                    <div class="row g-5 align-items-center">
                        <div class="col-12 col-lg-4 justify-content-center d-flex justify-content-lg-start" data-aos1="fade-right" data-aos1-delay="250">
                            <h3 class="fs-1 fw-bold mb-0 lh-1"><i class="ri-timer-flash-line align-bottom"></i> Sale
                                Extended</h3>
                        </div>
                        <div class="col-12 col-lg-4 d-flex justify-content-center flex-column" data-aos1="fade-up" data-aos1-delay="250">
                            <router-link to="/search?query=japanese" class="btn btn-orange btn-orange-chunky text-white my-1"><span>Shop Japanese</span></router-link>
                            <router-link to="/search?query=european" class="btn btn-orange btn-orange-chunky text-white my-1"><span>Shop European</span></router-link>
                            <router-link to="/search?query=american" class="btn btn-orange btn-orange-chunky text-white my-1"><span>Shop American</span></router-link>
                            <router-link to="/search?query=japanese" class="btn btn-orange btn-orange-chunky text-white my-1"><span>Shop Super Cars</span></router-link>
                        </div>
                        <div class="col-12 col-lg-4 text-center text-lg-end" data-aos1="fade-left" data-aos1-delay="250">
                            <p class="lead fw-bolder">Discount applied to products at checkout.</p>
                            <a class="text-white fw-bolder text-link-border border-2 border-white align-self-start pb-1 transition-all opacity-50-hover" href="#">Exclusions apply. Learn more <i class="ri-arrow-right-line align-bottom"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- SVG Shape Divider-->
            <div class="position-absolute z-index-50 text-white bottom-0 start-0 end-0">
                <svg class="align-self-end d-flex" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1283 53.25">
                    <polygon fill="currentColor" points="0 53.25 1283 53.25 1283 0 0 53.25" /></svg></div>
            <!-- /SVG Shape Divider-->
        </section>
        <!-- /Sale Banner -->
        <!-- Reviews-->
        <section>
            <div class="container" data-aos1="fade-in">
                <h2 class="fs-1 fw-bold mb-3 text-center mb-5">Customer Reviews</h2>

                <div class="row" v-if="loading">
                    <div class="col-md-12 text-center">
                        <div class="spinner-border"></div>
                    </div>
                </div>

                <div class="row g-3" style="margin-top: 20px;">

                    <div class="col-12 col-lg-4" data-aos1="fade-left" v-for="(d, index) in testimonials">
                        <div class="bg-light p-4 d-flex h-100 justify-content-start align-items-center flex-column text-center">
                            <p class="fw-bolder lead" v-text="d.title"></p>
                            <p class="mb-3" v-text="d.content"></p>
                            <small class="text-muted d-block mb-2 fw-bolder" v-text="d.name"></small>

                            <div class="rating position-relative d-table">
                                <div class="position-absolute stars" v-bind:style="{
                                    'width': calculateStarsWidth(d.stars) + '%'
                                }">
                                    <i v-for="star in 5" class="ri-star-fill text-dark mr-1"></i>
                                </div>
                                <div class="stars">
                                    <i v-for="star in 5" class="ri-star-fill mr-1 text-muted opacity-25"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- /Reviews-->
        <!-- /Page Content -->
    </section>
    <!-- / Main Section-->
</template>

<script>

    import axios from "axios"
    import swal from "sweetalert2"
    import jQuery from "jquery"
    import "../assets/js/jquery.js"
    import "../assets/owl-carousel/owl.carousel.css"
    import "../assets/owl-carousel/owl.theme.default.css"
    import "../assets/owl-carousel/owl.carousel.js"

    export default {
        name: "HomeComponent",

        data() {
            return {
                loading: false,
                upcomingCars: [],
                superCars: [],
                featured: [],
                testimonials: []
            }
        },

        methods: {

            calculateStarsWidth(stars) {
                let result = Math.floor(100 - (100 / stars))
                const remainder = result % 5
                result = result - remainder
                if (stars == 3) {
                    return 60
                }
                return result
            },

            async getData() {
                const self = this
                this.loading = true
                try {
                    const response = await axios.post(
                        this.$apiURL + "/getHomeData",
                        null
                    )

                    if (response.data.status == "success") {
                        this.upcomingCars = response.data.upcomingAuctions
                        this.superCars = response.data.superCars
                        this.featured = response.data.carsForSale
                        this.testimonials = response.data.testimonials

                        setTimeout(function () {
                            jQuery(".owl-carousel").owlCarousel({
                                autoWidth: true,
                                dots: false,
                                nav: true
                            })
                            jQuery(".owl-upcoming-cars .owl-nav .owl-next").css({
                                fontSize: "50px"
                            })
                            jQuery(".owl-upcoming-cars .owl-nav .owl-prev").css({
                                fontSize: "50px"
                            })
                        }, 500)
                    }
                } catch (error) {
                    if (error?.response?.status == 401) {
                        swal.fire("Error", error.response.data.message || "Unauthorized", "error")
                    }
                } finally {
                    this.loading = false
                }
            }
        },

        mounted() {
            this.getData()
            document.title = "Home | USSGlobal.com"
        }
    }

</script>