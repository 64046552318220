<template>
	<!-- Navbar -->
    <div class="position-relative z-index-30">
        <!-- Navbar -->
        <nav
            v-bind:class="'navbar navbar-expand-lg navbar-light bg-white border-bottom mx-0 p-0 flex-column border-0 ' + ($route.path == '/' ? 'position-absolute w-100 z-index-30 bg-transparent navbar-dark navbar-transparent bg-white-hover transition-all' : '')">
            <div class="w-100 pb-lg-0 pt-lg-0 pt-4 pb-3">
                <div class="container-fluid d-flex justify-content-between align-items-center flex-wrap">
        
                    <!-- Logo-->
                    <router-link class="navbar-brand fw-bold fs-3 m-0 p-0 flex-shrink-0" to="/">
                        <!-- Start of Logo-->
                        <div class="d-flex align-items-center">
                            <div class="f-w-6 d-flex align-items-center me-2 lh-1">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 194 194"><path fill="currentColor" class="svg-logo-white" d="M47.45,60l1.36,27.58,53.41-51.66,50.87,50,3.84-26L194,100.65V31.94A31.94,31.94,0,0,0,162.06,0H31.94A31.94,31.94,0,0,0,0,31.94v82.57Z"/><path fill="currentColor" class="svg-logo-dark" d="M178.8,113.19l1,34.41L116.3,85.92l-14.12,15.9L88.07,85.92,24.58,147.53l.93-34.41L0,134.86v27.2A31.94,31.94,0,0,0,31.94,194H162.06A31.94,31.94,0,0,0,194,162.06V125.83Z"/></svg>
                            </div> <span class="fs-5">USSGlobal</span>
                        </div>
                        <!-- / Logo-->
                        
                    </router-link>
                    <!-- / Logo-->
        
                    <!-- Main Navigation-->
                    <div class="ms-5 flex-shrink-0 collapse navbar-collapse navbar-collapse-light w-auto flex-grow-1" id="navbarNavDropdown">
        
                        <!-- Mobile Nav Toggler-->
                        <button
                            class="btn btn-link px-2 text-decoration-none navbar-toggler border-0 position-absolute top-0 end-0 mt-3 me-2 btn-close-nav"
                            data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
                            aria-expanded="false" aria-label="Toggle navigation">
                            <i class="ri-close-circle-line ri-2x"></i>
                        </button>
                        <!-- / Mobile Nav Toggler-->
        
                        <ul class="navbar-nav py-lg-2 mx-auto">
                            <li class="nav-item me-lg-4 dropdown position-static">
                                <a class="nav-link fw-bolder dropdown-toggle py-lg-4" href="#" role="button" data-bs-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="false">
                                    Japanese
                                </a>
                                <!-- Menswear dropdown menu-->
                                <div class="dropdown-menu dropdown-megamenu">
                                    <div class="container">
                                        <div class="row g-0">
                                            <!-- Dropdown Menu Links Section-->
                                            <div class="col-12 col-lg-7">
                                                <div class="row py-lg-5">
                                                
                                                    <!-- menu row-->
                                                    <div class="col col-lg-6 mb-5 mb-sm-0">
                                                        <h6 class="dropdown-heading">JDM</h6>
                                                        <ul class="list-unstyled">
                                                            <li class="dropdown-list-item"><router-link class="dropdown-item" to="/search?query=toyota">Toyota</router-link></li>
                                                            <li class="dropdown-list-item"><router-link class="dropdown-item" to="/search?query=lexus">Lexus</router-link></li>
                                                            <li class="dropdown-list-item"><router-link class="dropdown-item" to="/search?query=honda">Honda</router-link></li>
                                                            <li class="dropdown-list-item"><router-link class="dropdown-item" to="/search?query=nissan">Nissan</router-link></li>
                                                            <li class="dropdown-list-item"><router-link class="dropdown-item" to="/search?query=diahatsu">Diahatsu</router-link></li>
                                                        </ul>
                                                
                                                    </div>
                                                    <!-- /menu row-->
                                                
                                                    <!-- menu row-->
                                                    <div class="col col-lg-6">
                                                        <h6 class="dropdown-heading"></h6>
                                                        <ul class="list-unstyled">
                                                            <li class="dropdown-list-item"><router-link class="dropdown-item" to="/search?query=subaru">Subaru</router-link></li>
                                                            <li class="dropdown-list-item"><router-link class="dropdown-item" to="/search?query=mazda">Mazda</router-link></li>
                                                            <li class="dropdown-list-item"><router-link class="dropdown-item" to="/search?query=hino">Hino</router-link></li>
                                                            <li class="dropdown-list-item"><router-link class="dropdown-item" to="/search?query=isuzu">Isuzu</router-link></li>
                                                            <li class="dropdown-list-item"><router-link class="dropdown-item" to="/search?query=mitsubishi">Mitsubishi</router-link></li>
                                                        </ul>
                                                    </div>
                                                    <!-- /menu row-->
                                                
                                                </div>                    </div>
                                            <!-- /Dropdown Menu Links Section-->
                        
                                            <!-- Dropdown Menu Images Section-->
                                            <div class="d-none d-lg-block col-lg-5">
                                                <div class="vw-50 h-100 bg-img-cover bg-pos-center-center position-absolute" style="background-image: url(./assets/images/banners/banner-2.jpg);"></div>
                                            </div>
                                            <!-- Dropdown Menu Images Section-->
                                        </div>
                                    </div>
                                </div>
                                <!-- / Menswear dropdown menu-->
                            </li>
                            <li class="nav-item me-lg-4 dropdown position-static">
                                <a class="nav-link fw-bolder dropdown-toggle py-lg-4" href="#" role="button" data-bs-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="false">
                                    European
                                </a>
                                <!-- Womenswear dropdown menu-->
                                <div class="dropdown-menu dropdown-megamenu">
                                    <div class="container">
                                        <div class="row g-0">
                                            <!-- Dropdown Menu Links Section-->
                                            <div class="col-12 col-lg-7">
                                                <div class="row py-lg-5">
                                                
                                                    <!-- menu row-->
                                                    <div class="col col-lg-2 mb-5 mb-sm-0">
                                                        <h6 class="dropdown-heading">Germany</h6>
                                                        <ul class="list-unstyled">
                                                            <li class="dropdown-list-item"><router-link class="dropdown-item" to="/search?query=mercedes-benz">Mercedes Benz</router-link></li>
                                                            <li class="dropdown-list-item"><router-link class="dropdown-item" to="/search?query=bmw">BMW</router-link></li>
                                                            <li class="dropdown-list-item"><router-link class="dropdown-item" to="/search?query=audi">Audi</router-link></li>
                                                            <li class="dropdown-list-item"><router-link class="dropdown-item" to="/search?query=porsche">Porsche</router-link></li>
                                                            <li class="dropdown-list-item"><router-link class="dropdown-item" to="/search?query=volkswagen">Volkswagen</router-link></li>
                                                            <li class="dropdown-list-item"><router-link class="dropdown-item" to="/search?query=amg">AMG</router-link></li>
                                                            <li class="dropdown-list-item"><router-link class="dropdown-item" to="/search?query=maybach">Maybach</router-link></li>
                                                        </ul>
                                                    </div>
                                                    <!-- /menu row-->
                                                
                                                    <!-- menu row-->
                                                    <div class="col col-lg-2">
                                                        <h6 class="dropdown-heading">England</h6>
                                                        <ul class="list-unstyled">
                                                            <li class="dropdown-list-item"><router-link class="dropdown-item" to="/search?query=rolls-royce">Rolls Royce</router-link></li>
                                                            <li class="dropdown-list-item"><router-link class="dropdown-item" to="/search?query=bentley">Bentley</router-link></li>
                                                            <li class="dropdown-list-item"><router-link class="dropdown-item" to="/search?query=jaguar">Jaguar</router-link></li>
                                                            <li class="dropdown-list-item"><router-link class="dropdown-item" to="/search?query=land-rover">Land Rover</router-link></li>
                                                            <li class="dropdown-list-item"><router-link class="dropdown-item" to="/search?query=rover">Rover</router-link></li>
                                                            <li class="dropdown-list-item"><router-link class="dropdown-item" to="/search?query=mg">MG</router-link></li>
                                                            <li class="dropdown-list-item"><router-link class="dropdown-item" to="/search?query=aston-martin">Aston Martin</router-link></li>
                                                            <li class="dropdown-list-item"><router-link class="dropdown-item" to="/search?query=mclaren">Mclaren</router-link></li>
                                                        </ul>
                                                    </div>
                                                    <!-- /menu row-->

                                                    <div class="col col-lg-2 mb-5 mb-sm-0">
                                                        <h6 class="dropdown-heading">Italy</h6>
                                                        <ul class="list-unstyled">
                                                            <li class="dropdown-list-item"><router-link class="dropdown-item" to="/search?query=fiat">Fiat</router-link></li>
                                                            <li class="dropdown-list-item"><router-link class="dropdown-item" to="/search?query=ferrari">Ferrari</router-link></li>
                                                            <li class="dropdown-list-item"><router-link class="dropdown-item" to="/search?query=lamborghini">Lamborghini</router-link></li>
                                                            <li class="dropdown-list-item"><router-link class="dropdown-item" to="/search?query=lancia">Lancia</router-link></li>
                                                            <li class="dropdown-list-item"><router-link class="dropdown-item" to="/search?query=maserati">Maserati</router-link></li>
                                                            <li class="dropdown-list-item"><router-link class="dropdown-item" to="/search?query=alfaromeo">Alfaromeo</router-link></li>
                                                        </ul>
                                                    </div>

                                                    <div class="col col-lg-2 mb-5 mb-sm-0">
                                                        <h6 class="dropdown-heading">France</h6>
                                                        <ul class="list-unstyled">
                                                            <li class="dropdown-list-item"><router-link class="dropdown-item" to="/search?query=renault">Renault</router-link></li>
                                                            <li class="dropdown-list-item"><router-link class="dropdown-item" to="/search?query=peugeot">Peugeot</router-link></li>
                                                            <li class="dropdown-list-item"><router-link class="dropdown-item" to="/search?query=citroen">Citroen</router-link></li>
                                                        </ul>
                                                    </div>

                                                    <div class="col col-lg-2 mb-5 mb-sm-0">
                                                        <h6 class="dropdown-heading">Sweden</h6>
                                                        <ul class="list-unstyled">
                                                            <li class="dropdown-list-item"><router-link class="dropdown-item" to="/search?query=volvo">Volvo</router-link></li>
                                                            <li class="dropdown-list-item"><router-link class="dropdown-item" to="/search?query=saab">Saab</router-link></li>
                                                        </ul>
                                                    </div>

                                                    <div class="col col-lg-2 mb-5 mb-sm-0">
                                                        <h6 class="dropdown-heading">Netherlands</h6>
                                                        <ul class="list-unstyled">
                                                            <li class="dropdown-list-item"><router-link class="dropdown-item" to="/search?query=donkervoort">Donkervoort</router-link></li>
                                                        </ul>
                                                    </div>
                                                
                                                </div>                    </div>
                                            <!-- /Dropdown Menu Links Section-->
                        
                                            <!-- Dropdown Menu Images Section-->
                                            <div class="d-none d-lg-block col-lg-5">
                                                <div class="vw-50 h-100 bg-img-cover bg-pos-center-center position-absolute" style="background-image: url(./assets/images/banners/banner-4.jpg);"></div>
                                            </div>
                                            <!-- Dropdown Menu Images Section-->
                                        </div>
                                    </div>
                                </div>
                                <!-- / Womenswear dropdown menu-->
                            </li>

                            <li class="nav-item me-lg-4 dropdown position-static">
                                <a class="nav-link fw-bolder dropdown-toggle py-lg-4" href="#" role="button" data-bs-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="false">
                                    American
                                </a>
                                <!-- Womenswear dropdown menu-->
                                <div class="dropdown-menu dropdown-megamenu">
                                    <div class="container">
                                        <div class="row g-0">
                                            <!-- Dropdown Menu Links Section-->
                                            <div class="col-12 col-lg-7">
                                                <div class="row py-lg-5">
                                                
                                                    <!-- menu row-->
                                                    <div class="col col-lg-6 mb-5 mb-sm-0">
                                                        <h6 class="dropdown-heading">Germany</h6>
                                                        <ul class="list-unstyled">
                                                            <li class="dropdown-list-item"><router-link class="dropdown-item" to="/search?query=cadillac">Cadillac</router-link></li>
                                                            <li class="dropdown-list-item"><router-link class="dropdown-item" to="/search?query=chevrolet">Chevrolet</router-link></li>
                                                            <li class="dropdown-list-item"><router-link class="dropdown-item" to="/search?query=gmc">GMC</router-link></li>
                                                            <li class="dropdown-list-item"><router-link class="dropdown-item" to="/search?query=pontiac">Pontiac</router-link></li>
                                                            <li class="dropdown-list-item"><router-link class="dropdown-item" to="/search?query=hummer"><span>Hummer<span class="tip tip-new">New</span></span></router-link></li>
                                                            <li class="dropdown-list-item"><router-link class="dropdown-item" to="/search?query=lincoln"><span>Lincoln</span></router-link></li>
                                                        </ul>
                                                    </div>

                                                    <div class="col col-lg-6 mb-5 mb-sm-0">
                                                        <h6 class="dropdown-heading"></h6>
                                                        <ul class="list-unstyled">
                                                            <li class="dropdown-list-item"><router-link class="dropdown-item" to="/search?query=ford">Ford</router-link></li>
                                                            <li class="dropdown-list-item"><router-link class="dropdown-item" to="/search?query=chrysler">Chrysler</router-link></li>
                                                            <li class="dropdown-list-item"><router-link class="dropdown-item" to="/search?query=dodge">Dodge</router-link></li>
                                                            <li class="dropdown-list-item"><router-link class="dropdown-item" to="/search?query=lexus">Lexus</router-link></li>
                                                            <li class="dropdown-list-item"><router-link class="dropdown-item" to="/search?query=infiniti">Infiniti</router-link></li>
                                                            <li class="dropdown-list-item"><router-link class="dropdown-item" to="/search?query=america">America</router-link></li>
                                                            <li class="dropdown-list-item"><router-link class="dropdown-item" to="/search?query=tesla">Tesla</router-link></li>
                                                            <li class="dropdown-list-item"><router-link class="dropdown-item" to="/search?query=usa">U.S.A</router-link></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <li class="nav-item dropdown me-lg-4">
                                <a class="nav-link fw-bolder dropdown-toggle py-lg-4" href="#" role="button" data-bs-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="false">
                                    Super Cars
                                </a>
                                <ul class="dropdown-menu">
                                    <li><router-link class="dropdown-item" to="/search?query=ferrari">Ferrari</router-link></li>
                                    <li><router-link class="dropdown-item" to="/search?query=lamborghini">Lamborghini</router-link></li>
                                    <li><router-link class="dropdown-item" to="/search?query=porsche">Porsche</router-link></li>
                                    <li><router-link class="dropdown-item" to="/search?query=mclaren">Mclaren</router-link></li>
                                    <li><router-link class="dropdown-item" to="/search?query=aston-martin">Aston Martin</router-link></li>
                                </ul>
                            </li>

                            <li class="nav-item dropdown me-lg-4">
                                <a class="nav-link fw-bolder dropdown-toggle py-lg-4" href="#" role="button" data-bs-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="false">
                                    Korean
                                </a>
                                <ul class="dropdown-menu">
                                    <li><router-link class="dropdown-item" to="/search?query=hyundai">Hyundai</router-link></li>
                                    <li><router-link class="dropdown-item" to="/search?query=kia">KIA</router-link></li>
                                  </ul>
                            </li>

                            <li class="nav-item me-lg-4">
                                <router-link class="nav-link fw-bolder py-lg-4" to="/countries">
                                    Countries
                                </router-link>
                            </li>

                            <li class="nav-item dropdown me-lg-4 user-menu" v-if="user != null">
                                <a class="nav-link fw-bolder dropdown-toggle py-lg-4" href="#" role="button" data-bs-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="false" v-text="user.name">
                                </a>
                                <ul class="dropdown-menu">
                                    <li><router-link class="dropdown-item" to="/account">My Account</router-link></li>
                                    <li><router-link class="dropdown-item" to="/chat">Chat</router-link></li>
                                    <li><a class="dropdown-item" href="javascript:void(0)" v-on:click="doLogout">Logout</a></li>
                                </ul>
                            </li>
                            <li class="nav-item me-lg-4" v-else>
                                <router-link class="nav-link fw-bolder py-lg-4" to="/login">
                                    Login/Sign Up
                                </router-link>
                            </li>
                        </ul>            
                    </div>
                    <!-- / Main Navigation-->
        
                    <!-- Navbar Icons-->
                    <ul class="list-unstyled mb-0 d-flex align-items-center">
        
                        <!-- Navbar Toggle Icon-->
                        <li class="d-inline-block d-lg-none">
                            <button
                                class="btn btn-link px-2 text-decoration-none navbar-toggler border-0 d-flex align-items-center"
                                data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
                                aria-expanded="false" aria-label="Toggle navigation">
                                <i class="ri-menu-line ri-lg align-middle"></i>
                            </button>
                        </li>
                        <!-- /Navbar Toggle Icon-->
        
                        <!-- Navbar Search-->
                        <li class="ms-1 d-inline-block">
                            <button
                                class="btn btn-link px-2 text-decoration-none d-flex align-items-center"
                                data-pr-search>
                                <i class="ri-search-2-line ri-lg align-middle"></i>
                            </button>
                        </li>
                        <!-- /Navbar Search-->

                        <li class="ms-1 d-inline-block notification" v-if="unreadNotifications > 0">
                            <router-link
                                class="btn btn-link px-2 text-decoration-none d-flex align-items-center"
                                to="/notifications">
                                <i class="ri-notification-line ri-lg align-middle"></i>
                                <i class="badge" v-text="'(' + unreadNotifications + ')'"></i>
                            </router-link>
                        </li>
                    </ul>
                    <!-- Navbar Icons-->
        
                </div>
            </div>
        </nav>
        <!-- / Navbar-->
    </div>
    <!-- / Navbar-->
    <div ref="snackbar" id="snackbar">New message has been received.</div>
</template>

<script>

	import "../../assets/css/libs.bundle.css"
	import "../../assets/css/theme.bundle.css"
    import "../../assets/css/custom.css"

    import axios from "axios"
    import swal from "sweetalert2"
    import store from "../../vuex/store"
    import { io } from "socket.io-client"

	export default {
		name: "AppHeader",

        computed: {
            user() {
                return store.getters.getUser
            },

            unreadNotifications() {
                return store.getters.getUnreadNotifications
            }
        },

        data() {
            return {
                loggingOut: false
            }
        },

        methods: {

            doLogout: async function () {
                const self = this

                if (localStorage.getItem(this.$accessToken) == null) {
                    this.$headers.Authorization = "Bearer "
                    store.commit("setUser", null)
                    store.commit("setUnreadNotifications", 0)
                    store.commit("setLoadingUser", false)
                    this.$router.push("/login")

                    return
                }

                this.loggingOut = true

                const response = await axios.post(
                    this.$apiURL + "/logout",
                    null,
                    {
                        headers: this.$headers
                    }
                )

                this.loggingOut = false
             
                if (response.data.status == "success") {
                    // remove access token from local storage
                    localStorage.removeItem(this.$accessToken)

                    this.$headers.Authorization = "Bearer "
                    store.commit("setUser", null)
                    store.commit("setUnreadNotifications", 0)
                    store.commit("setLoadingUser", false)
             
                    // swal.fire("Logout", response.data.message, "success")
                    //     .then(function () {
                            self.$router.push("/login")
                        // })
                } else {
                    swal.fire("Error", response.data.message, "error")
                }
            },

            getUser: async function () {
                const self = this
                if (localStorage.getItem(this.$accessToken) == null) {
                    // this.$router.push("/login")
                    return
                }

                store.commit("setLoadingUser", true)

                try {
                    const response = await axios.post(
                        this.$apiURL + "/getUser",
                        null,
                        {
                            headers: this.$headers
                        }
                    )

                    if (response.data.status == "success") {
                        store.commit("setUser", response.data.user)
                        store.commit("setUnreadNotifications", response.data.unreadNotifications)
                        this.initializeUserMenu()
                        // global.socketIO = io(this.$nodeURL)
                        // global.socketIO.emit("connected", response.data.user.email)
                        // global.socketIO.on("newMessage", function (message) {
                        //     self.showNewMessageNotification()
                        // })
                    } else {
                        // this.$router.push("/login")
                    }
                } catch (error) {
                    if (error?.response?.status == 401) {
                        // this.$router.push("/login")
                    }
                } finally {
                    store.commit("setLoadingUser", false)
                }
            },
            showNewMessageNotification: function () {
                var x = this.$refs["snackbar"]
                x.className = "show"
                setTimeout(function(){ x.className = x.className.replace("show", "") }, 3000)
            }
        },

        mounted: function () {
            this.getUser()
        }
	}
</script>

<style scoped>
    .bg-white-hover:hover .notification .badge,
    .bg-white .notification .badge {
        color: black;
    }
    .bg-white-hover .notification .badge {
        color: white;
    }
    #snackbar {
        visibility: hidden;
        min-width: 250px;
        margin-left: -125px;
        background-color: #333;
        color: #fff;
        text-align: center;
        border-radius: 2px;
        padding: 16px;
        position: fixed;
        z-index: 1;
        left: 50%;
        bottom: 30px;
        font-size: 17px;
      }

      #snackbar.show {
        visibility: visible;
        -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
        animation: fadein 0.5s, fadeout 0.5s 2.5s;
      }

      @-webkit-keyframes fadein {
        from {bottom: 0; opacity: 0;} 
        to {bottom: 30px; opacity: 1;}
      }

      @keyframes fadein {
        from {bottom: 0; opacity: 0;}
        to {bottom: 30px; opacity: 1;}
      }

      @-webkit-keyframes fadeout {
        from {bottom: 30px; opacity: 1;} 
        to {bottom: 0; opacity: 0;}
      }

      @keyframes fadeout {
        from {bottom: 30px; opacity: 1;}
        to {bottom: 0; opacity: 0;}
      }
</style>