<template>
	<!-- Main Section-->
    <section class="mt-0 ">
        <!-- Category Top Banner -->
        <div class="py-6 bg-img-cover bg-dark bg-overlay-gradient-dark position-relative overflow-hidden mb-4 bg-pos-center-center" style="background-image: url(./assets/images/banners/banner-1.jpg);">
            <div class="container position-relative z-index-20" data-aos="fade-right" data-aos-delay="300">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item breadcrumb-light">
                            <router-link to="/">Home</router-link>
                        </li>
                        <li class="breadcrumb-item active breadcrumb-light" aria-current="page">Notifications</li>
                    </ol>
                </nav>
                <h1 class="fw-bold display-6 mb-4 text-white">Notifications</h1>
            </div>
        </div>

        <!-- Category Top Banner -->
        <div class="container">
        	<div class="text-center loader" v-if="loading">
                <div class="spinner-border"></div>
            </div>

            <div v-for="(d, index) in data" class="row">
            	<div class="offset-3 col-6 notification">
            		<div>
            			<p class="heading">
            				<router-link v-bind:to="'/account?tab=myBids&id=' + d.id" v-text="d.action"></router-link>
            			</p>
            			<p v-text="getDateTimeFormat(d.dateTime)" class="time"></p>
            		</div>
            	</div>
            </div>

			<div v-if="pages > 0" id="pagination">
				<nav aria-label="Page navigation">
					<ul class="pagination">
						<li v-for="page in pages" v-bind:class="'page-item ' + (page == pageNumber ? 'active' : '')">
							<a class="page-link" href="javascript:void(0)" v-on:click="paginate(page)" v-text="page"></a>
						</li>
					</ul>
				</nav>
			</div>
        </div>
    </section>
</template>

<script>
	import axios from "axios"
    import swal from "sweetalert2"
    import jQuery from "jquery"
    import store from "../vuex/store"

    import "../assets/js/jquery.js"
    import "../assets/datetimepicker/jquery.datetimepicker.min.css"
    import "../assets/datetimepicker/jquery.datetimepicker.full.js"

    import "../assets/datatable/datatables.css"
    import "../assets/datatable/datatables.min.js"

	export default {
		name: "NotificationsComponent",

		data() {
            return {
                loading: false,
                data: [],
                dataTable: null,
                pages: 0,
				pageNumber: this.$route.query.page || 1
            }
        },

        methods: {

        	paginate: function (page) {
				this.pageNumber = page
				this.addOrUpdateURLParam("page", page)
				this.getData()
			},

            async getData() {
            	const self = this
                this.loading = true

                if (this.dataTable != null) {
	            	this.dataTable.destroy()
	            }

                try {
                    const formData = new FormData()
                    formData.append("page", this.pageNumber)

                    const response = await axios.post(
                        this.$apiURL + "/notifications",
                        formData,
                        {
                            headers: this.$headers
                        }
                    )

                    if (response.data.status == "success") {
                        this.data = response.data.data
                        this.pages = response.data.pages

                        store.commit("setUnreadNotifications", 0)
                    }
                } catch (error) {
                    if (error?.response?.status == 401) {
                        swal.fire("Error", error.response.data.message || "Unauthorized", "error")
                    }
                } finally {
                    this.loading = false

                    setTimeout(function () {
			    		self.initializeTable()
			    	}, 100)
                }
            },

            initializeTable() {
	            if (this.$isMobile()) {
	            	this.dataTable = jQuery("#data-table").DataTable({
		            	ordering: false,
	                    pageLength: 1
		            })
	            } else {
	            	this.dataTable = jQuery("#data-table").DataTable({
		            	ordering: false
		            })
	            }
	        }
        },

		mounted() {
            this.getData()
            document.title = "Notifications"
        }
	}
</script>

<style scoped>
	.notification .time {
		color: gray;
		font-size: 14px;
		margin-bottom: 0px;
	}
	.notification .heading {
		font-size: 30px;
	}
	.notification {
		margin-top: 20px;
		background-color: #f0f0f0;
	    padding: 20px;
	    border-radius: 10px;
	}
</style>