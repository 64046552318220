<template>
	<!-- Main Section-->
    <section class="mt-0">
        <!-- Category Top Banner -->
        <div class="py-6 bg-img-cover bg-dark bg-overlay-gradient-dark position-relative overflow-hidden mb-4 bg-pos-center-center" style="background-image: url(./assets/images/banners/banner-1.jpg);">
            <div class="container position-relative z-index-20" data-aos="fade-right" data-aos-delay="300">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item breadcrumb-light">
                            <router-link to="/">Home</router-link>
                        </li>
                        <li class="breadcrumb-item breadcrumb-light">Invoice</li>
                        <li class="breadcrumb-item active breadcrumb-light" aria-current="page" v-text="id"></li>
                    </ol>
                </nav>
                <h1 class="fw-bold display-6 mb-4 text-white" v-text="'Invoice #' + id"></h1>
            </div>
        </div>

        <!-- Category Top Banner -->
        <div class="container">
            <div class="row">
                <!-- Category Aside/Sidebar -->
                <div class="col-lg-12">
                	<div class="text-center loader" v-if="loading">
                        <div class="spinner-border"></div>
                    </div>

                    <div id="invoice" class="print-container" v-if="data != null">
		                <div class="toolbar hidden-print">
		                    <div class="text-end">
		                        <button type="button" class="btn btn-dark" onclick="window.print()">
		                        	<i class="fa fa-print"></i>
		                        	&nbsp;Print
		                        </button>
		                    </div>

		                    <hr />
		                </div>

		                <div class="invoice overflow-auto">
		                    <div style="min-width: 600px">
		                        <header>
		                            <div class="row">
		                                <div class="col">
		                                    <a href="javascript:void(0)">
												<img src="../assets/logo.png" width="180" alt="USSGlobal.com" />
											</a>

											<h2 style="margin-top: 20px; text-transform: uppercase;" v-text="data.status" v-bind:class="'text-' + data.status"></h2>
		                                </div>
		                                <div class="col company-details">
		                                    <h2 class="name text-right" v-text="data.paymentMethod.toUpperCase()"></h2>
		                                    <template v-if="data.paymentMethod == 'bank' && data.bank != null">
		                                    	<table class="bank-details" style="float: right; margin-top: 20px; margin-bottom: 20px;">
		                                    		<tr>
		                                    			<td>Bank Name</td>
		                                    			<td v-text="data.bank.name"></td>
		                                    		</tr>

		                                    		<tr>
		                                    			<td>Account Number</td>
		                                    			<td v-text="data.bank.accountNumber"></td>
		                                    		</tr>

		                                    		<tr>
		                                    			<td>Account Title</td>
		                                    			<td v-text="data.bank.accountTitle"></td>
		                                    		</tr>

		                                    		<tr v-if="data.bank.swiftCode != ''">
		                                    			<td>Swift Code</td>
		                                    			<td v-text="data.bank.swiftCode"></td>
		                                    		</tr>

		                                    		<tr v-if="data.bank.branch != ''">
		                                    			<td>Branch</td>
		                                    			<td v-text="data.bank.branch"></td>
		                                    		</tr>

		                                    		<tr v-if="data.bank.address != ''">
		                                    			<td>Address</td>
		                                    			<td v-text="data.bank.address"></td>
		                                    		</tr>
		                                    	</table>
		                                    </template>
		                                </div>
		                            </div>
		                        </header>
		                        <main style="clear: both;">
		                            <div class="row contacts">
		                                <div class="col invoice-to">
		                                    <div class="text-gray-light">INVOICE TO:</div>
		                                    <h2 class="to" v-text="data.name"></h2>
		                                    <div class="address" v-html="data.address"></div>
		                                    <div class="email"><a v-bind:href="'mailto:' + data.email" v-text="data.email"></a>
		                                    </div>
		                                </div>
		                                <div class="col invoice-details">
		                                    <h1 class="invoice-id">
		                                    	INVOICE
		                                    	<span v-text="'#' + data.id"></span>
		                                    </h1>
		                                    <div class="date">Date of Invoice:
		                                    	<span v-text="dateFormat(data.created_at)"></span>
		                                    </div>
		                                    <div class="date">Due Date:
		                                    	<span v-text="dateFormat(data.dueDate)"></span>
		                                    </div>
		                                </div>
		                            </div>
		                            <table id="invoice-items">
		                                <thead>
		                                    <tr>
		                                        <th>#</th>
		                                        <th class="text-left" colspan="3">DESCRIPTION</th>
		                                        <th class="text-right">AMOUNT</th>
		                                    </tr>
		                                </thead>
		                                <tbody>
		                                    <tr v-if="data.amount > 0">
		                                        <td class="no" v-text="counter()"></td>
		                                        <td class="text-left" colspan="3">
		                                            <h3>
		                                                <a target="_blank" href="javascript:void(0)" v-text="data.invoiceFor.replace(/([a-z])([A-Z])/g, '$1 $2')" style="text-transform: capitalize; color: black;"></a>
		                                            </h3>
		                                        </td>
		                                        <td class="total" v-text="data.currency + ' ' + data.amount"></td>
		                                    </tr>

		                                    <tr v-if="data.bankingFee > 0">
		                                        <td class="no" v-text="counter()"></td>
		                                        <td class="text-left" colspan="3">
		                                            <h3>
		                                                <a target="_blank" href="javascript:void(0)">Banking Fee</a>
		                                            </h3>
		                                        </td>
		                                        <td class="total" v-text="data.currency + ' ' + data.bankingFee"></td>
		                                    </tr>

		                                    <tr v-if="data.shippingCharges > 0">
		                                        <td class="no" v-text="counter()"></td>
		                                        <td class="text-left" colspan="3">
		                                            <h3>
		                                                <a target="_blank" href="javascript:void(0)">Shipping Charges</a>
		                                            </h3>
		                                        </td>
		                                        <td class="total" v-text="data.currency + ' ' + data.shippingCharges"></td>
		                                    </tr>

		                                    <tr v-if="data.shippingDiscount > 0">
		                                        <td class="no" v-text="counter()"></td>
		                                        <td class="text-left" colspan="3">
		                                            <h3>
		                                                <a target="_blank" href="javascript:void(0)">Shipping Discount</a>
		                                            </h3>
		                                        </td>
		                                        <td class="total" v-text="data.currency + ' ' + data.shippingDiscount"></td>
		                                    </tr>

		                                    <tr v-if="data.totalPreviousCarBalance > 0">
		                                        <td class="no" v-text="counter()"></td>
		                                        <td class="text-left" colspan="3">
		                                            <h3>
		                                                <a target="_blank" href="javascript:void(0)">Total Previous Car Balance</a>
		                                            </h3>
		                                        </td>
		                                        <td class="total" v-text="data.currency + ' ' + data.totalPreviousCarBalance"></td>
		                                    </tr>

		                                    <tr v-if="data.balanceAmount > 0">
		                                        <td class="no" v-text="counter()"></td>
		                                        <td class="text-left" colspan="3">
		                                            <h3>
		                                                <a target="_blank" href="javascript:void(0)">Balance Amount</a>
		                                            </h3>
		                                        </td>
		                                        <td class="total" v-text="data.currency + ' ' + data.balanceAmount"></td>
		                                    </tr>

		                                    <tr v-if="data.vat > 0">
		                                        <td class="no" v-text="counter()"></td>
		                                        <td class="text-left" colspan="3">
		                                            <h3>
		                                                <a target="_blank" href="javascript:void(0)">VAT</a>
		                                            </h3>
		                                        </td>
		                                        <td class="total" v-text="data.currency + ' ' + data.vat"></td>
		                                    </tr>
		                                    
		                                </tbody>
		                                <tfoot>
		                                    <tr>
		                                        <td colspan="2"></td>
		                                        <td colspan="2">SUBTOTAL</td>
		                                        <td v-text="data.currency + ' ' + subTotal()"></td>
		                                    </tr>
		                                    <tr v-if="data.taxes > 0">
		                                        <td colspan="2"></td>
		                                        <td colspan="2">TAX</td>
		                                        <td v-text="data.currency + ' ' + data.taxes"></td>
		                                    </tr>
		                                    <tr v-if="data.securityDepositAdjusted > 0">
		                                        <td colspan="2"></td>
		                                        <td colspan="2">Security Deposit Adjusted</td>
		                                        <td v-text="data.currency + ' ' + data.securityDepositAdjusted"></td>
		                                    </tr>
		                                    <tr>
		                                        <td colspan="2"></td>
		                                        <td colspan="2">GRAND TOTAL</td>
		                                        <td v-text="data.currency + ' ' + grandTotal()"></td>
		                                    </tr>
		                                </tfoot>
		                            </table>
		                            <div class="thanks">Thank you!</div>
		                            <div class="notices">
		                                <div>NOTICE:</div>
		                                <div class="notice">A finance charge of 1.5% will be made on unpaid balances after 30 days.</div>
		                            </div>
		                        </main>
		                        <footer>Invoice was created on a computer and is valid without the signature and seal.</footer>
		                    </div>
		                    <div></div>
		                </div>
		            </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
	import axios from "axios"
    import swal from "sweetalert2"
    import jQuery from "jquery"

	export default {
		name: "InvoiceComponent",
		data() {
			return {
				loading: false,
				id: this.$route.params.id || 0,
				data: null,
				count: 1
			}
		},
		methods: {
			grandTotal() {
				let total = this.subTotal()
				total = total + this.data.taxes
				total = total - this.data.securityDepositAdjusted
				return total
			},

			subTotal() {
				let total = 0
				total += this.data.amount
				total += this.data.bankingFee
				total += this.data.shippingCharges
				total = total - this.data.shippingDiscount
				total += this.data.vat
				return total
			},

			dateFormat(date) {
				date = new Date(date)
				return ("0" + date.getDate()).slice(-2) + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear()
			},

			counter() {
				const str = ("0" + this.count++).slice(-2)
				return str
			},

            async getData() {
            	const self = this
                this.loading = true

                try {
                    const formData = new FormData()
                    formData.append("id", this.id)

                    const response = await axios.post(
                        this.$apiURL + "/fetchInvoice",
                        formData,
                        {
                            headers: this.$headers
                        }
                    )

                    if (response.data.status == "success") {
                        this.data = response.data.data
                    }
                } catch (error) {
                    if (error?.response?.status == 401) {
                        swal.fire("Error", error.response.data.message || "Unauthorized", "error")
                    }
                } finally {
                    this.loading = false
                }
            }
        },

		mounted() {
            this.getData()
            document.title = "Invoice #" + this.id
        }
	}
</script>

<style scoped>
	.text-declined {
		color: red;
	}
	.text-paid {
		color: limegreen;
	}
	.text-processed {
		color: #09c89f;
	}
	.text-created {
		color: #0b6efd;
	}
	.bank-details {
		border: 1px solid black;
	}
	.bank-details th,
	.bank-details td {
		border: 1px solid black;
		border-collapse: collapse;
		padding: 15px;
	}
	.invoice table td h3 a {
		color: black !important;
	}
	#invoice {
	    padding: 0px;
	}

	.invoice {
	    position: relative;
	    background-color: #FFF;
	    min-height: 680px;
	    padding: 15px
	}

	.invoice header {
	    padding: 10px 0;
	    margin-bottom: 20px;
	    border-bottom: 1px solid #0d6efd
	}

	.invoice .company-details {
	    text-align: right
	}

	.invoice .company-details .name {
	    margin-top: 0;
	    margin-bottom: 0
	}

	.invoice .contacts {
	    margin-bottom: 20px
	}

	.invoice .invoice-to {
	    text-align: left
	}

	.invoice .invoice-to .to {
	    margin-top: 0;
	    margin-bottom: 0
	}

	.invoice .invoice-details {
	    text-align: right
	}

	.invoice .invoice-details .invoice-id {
	    margin-top: 0;
	    color: #0d6efd
	}

	.invoice main {
	    padding-bottom: 50px
	}

	.invoice main .thanks {
	    /*margin-top: -100px;*/
	    font-size: 2em;
	    margin-bottom: 50px
	}

	.invoice main .notices {
	    padding-left: 6px;
	    border-left: 6px solid #0d6efd;
	    background: #e7f2ff;
	    padding: 10px;
	}

	.invoice main .notices .notice {
	    font-size: 1.2em
	}

	.invoice table#invoice-items {
	    width: 100%;
	    border-collapse: collapse;
	    border-spacing: 0;
	    margin-bottom: 20px
	}

	.invoice table#invoice-items td,
	.invoice table#invoice-items th {
	    padding: 15px;
	    background: #eee;
	    border-bottom: 1px solid #fff
	}

	.invoice table#invoice-items th {
	    white-space: nowrap;
	    font-weight: 400;
	    font-size: 16px
	}

	.invoice table#invoice-items td h3 {
	    margin: 0;
	    font-weight: 400;
	    color: #0d6efd;
	    font-size: 1.2em
	}

	.invoice table#invoice-items .qty,
	.invoice table#invoice-items .total,
	.invoice table#invoice-items .unit {
	    text-align: right;
	    font-size: 1.2em
	}

	.invoice table#invoice-items .no {
	    color: #fff;
	    font-size: 1.6em;
	    background: #0d6efd
	}

	.invoice table#invoice-items .unit {
	    background: #ddd
	}

	.invoice table#invoice-items .total {
	    background: #0d6efd;
	    color: #fff
	}

	.invoice table#invoice-items tbody tr:last-child td {
	    border: none
	}

	.invoice table#invoice-items tfoot td {
	    background: 0 0;
	    border-bottom: none;
	    white-space: nowrap;
	    text-align: right;
	    padding: 10px 20px;
	    font-size: 1.2em;
	    border-top: 1px solid #aaa
	}

	.invoice table#invoice-items tfoot tr:first-child td {
	    border-top: none
	}
	.card {
	    position: relative;
	    display: flex;
	    flex-direction: column;
	    min-width: 0;
	    word-wrap: break-word;
	    background-color: #fff;
	    background-clip: border-box;
	    border: 0px solid rgba(0, 0, 0, 0);
	    border-radius: .25rem;
	    margin-bottom: 1.5rem;
	    box-shadow: 0 2px 6px 0 rgb(218 218 253 / 65%), 0 2px 6px 0 rgb(206 206 238 / 54%);
	}

	.invoice table tfoot tr:last-child td {
	    color: #0d6efd;
	    font-size: 1.4em;
	    border-top: 1px solid #0d6efd
	}

	.invoice table tfoot tr td:first-child {
	    border: none
	}

	.invoice footer {
	    width: 100%;
	    text-align: center;
	    color: #777;
	    border-top: 1px solid #aaa;
	    padding: 8px 0
	}

	.invoice main .notices {
	    padding-left: 6px;
	    border-left: 6px solid #0d6efd;
	    background: #e7f2ff;
	    padding: 10px;
	}
</style>

<style>
	/*@media print {
		@page {
			size: A4;
			margin: 0;
	    }
		body * {
			visibility: hidden;
		}
		.invoice, .invoice * {
			visibility: visible;
		}
	    .invoice {
	        font-size: 11px !important;
	        overflow: hidden !important;
	    }
	    .invoice footer {
	        position: absolute;
	        bottom: 10px;
	        page-break-after: always
	    }
	    .invoice>div:last-child {
	        page-break-before: always
	    }
	}*/
</style>