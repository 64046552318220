<template>
	<!-- Main Section-->
    <section class="mt-5 ">
        <!-- Page Content Goes Here -->
        <!-- Product Top-->
        <section class="container">
            <!-- Breadcrumbs-->
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item active" aria-current="page">Forgot Password</li>
                </ol>
            </nav> <!-- /Breadcrumbs-->
            <div class="row g-5">
                <!-- Images Section-->
                <div class="offset-md-3 col-md-6">
                	<br />
                	<h2>Forgot Password</h2>
                	<br />
                	<form style="display: contents;" v-on:submit.prevent="sendRecoveryLink">
                		<div class="product-option">
                            <small class="text-uppercase d-block fw-bolder mb-2">
                                Email:
                            </small>
                            <div class="form-group">
                                <input name="email" type="email" class="form-control" required />
                            </div>
                        </div>

                        <button type="submit" v-bind:disabled="sending" class="btn btn-dark btn-dark-chunky flex-grow-1 me-2 text-white">Send Recovery Link</button>
                	</form>
                </div>
            </div>
        </section>
    </section>
</template>

<script>
	import axios from "axios"
	import swal from "sweetalert2"

	export default {
		name: "ForgotPasswordComponent",
		data() {
			return {
				sending: false
			}
		},
		methods: {
			async sendRecoveryLink() {
                this.sending = true
                try {
                    const form = event.target
                    const formData = new FormData(form)
                    const response = await axios.post(
                        this.$apiURL + "/sendRecoveryLink",
                        formData
                    )
                    swal.fire("Forgot Password", response.data.message, response.data.status)
                    if (response.data.status == "success") {
                        this.$router.push("/resetPassword?email=" + form.email.value)
                    }
                } catch (error) {
                    if (error?.response?.status == 401) {
                        swal.fire("Error", error.response.data.message || "Unauthorized", "error")
                    }
                } finally {
                    this.sending = false
                }
            }
        },

        mounted() {
            document.title = "Forgot Password"
        }
	}
</script>