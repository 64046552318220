<template>
	<!-- Main Section-->
    <section class="mt-5 ">
        <!-- Page Content Goes Here -->
        <!-- Product Top-->
        <section class="container">
            <!-- Breadcrumbs-->
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
                    <li class="breadcrumb-item active" aria-current="page" v-text="car ? (car.MARKA_NAME + ' ' + car.MODEL_NAME) : ''"></li>
                </ol>
            </nav> <!-- /Breadcrumbs-->

            <div class="row">
                <div class="col-md-12">
                    <div class="text-center loader" v-if="loading">
                        <div class="spinner-border"></div>
                    </div>
                </div>
            </div>

            <div class="row g-5" v-if="car != null">
                <!-- Images Section-->
                <div class="col-12 col-lg-7">
                    <div class="row g-1">
                        <div class="swiper-container gallery-thumbs-vertical col-2 pb-4">
                            <div class="swiper-wrapper" style="display: block !important;">
                                <div class="swiper-slide bg-light bg-light h-auto" v-for="(image, index) in car.IMAGES" style="height: fit-content !important;" v-on:click="selectedImageIndex = index">
                                    <video v-if="isVideo(getImage(car.IMAGES, index))" v-bind:src="getImage(car.IMAGES, index)" class="img-fluid mx-auto d-table"></video>
                                    <picture v-else>
                                        <img class="img-fluid mx-auto d-table" v-bind:src="getImage(car.IMAGES, index)" v-bind:alt="car.MARKA_NAME + ' ' + car.MODEL_NAME" />
                                    </picture>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-container gallery-top-vertical col-10">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide bg-white h-auto">
                                    <video v-if="isVideo(getImage(car.IMAGES, selectedImageIndex))" v-bind:src="getImage(car.IMAGES, selectedImageIndex)" class="img-fluid mx-auto d-table" controls></video>
                                    <picture v-else>
                                        <img class="img-fluid d-table mx-auto" v-bind:src="getImage(car.IMAGES, selectedImageIndex)" v-bind:alt="car.MARKA_NAME + ' ' + car.MODEL_NAME" data-zoomable style="cursor: pointer;" />
                                    </picture>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- /Images Section-->
                <!-- Product Info Section-->
                <div class="col-12 col-lg-5">
                    <div class="pb-3">
                        <!-- Product Name, Review, Brand, Price-->
                        <div class="d-flex justify-content-between align-items-center mb-2">
                            <p class="small fw-bolder text-uppercase tracking-wider text-muted mb-0 lh-1" v-text="car.YEAR"></p>
                        </div>
                        <h1 class="mb-2 fs-2 fw-bold" v-text="car.MARKA_NAME + ' ' + car.MODEL_NAME"></h1>
                        <div class="justify-content-start align-items-center">
                            <p class="lead fw-bolder m-0 fs-3 lh-1 text-danger me-2" v-if="car.carForSaleStatus == 'active'" v-html="car.currency + car.price"></p>
                            <p class="lead fw-bolder m-0 fs-3 lh-1 text-success me-2" style="margin-top: 10px !important;" v-if="car.carForSaleStatus == 'sold'">
                                <span v-html="'Sold at ' + car.currency + car.price" style="text-transform: capitalize;"></span>
                            </p>
                        </div>
                        <!-- /Product Name, Review, Brand, Price-->
                        <!-- Product Views-->
                        <div class="d-flex justify-content-start mt-3">
                            <div class="alert bg-light rounded py-1 px-2 d-table m-0">
                                <div class="d-flex justify-content-start align-items-center">
                                    <!-- <i class="ri-fire-fill lh-1 text-orange"></i> -->
                                    <div class="ms-2temp">
                                        <small class="opacity-75 fw-bolder lh-1" v-text="car.RATE + ' Graded'"></small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Product Options-->
                        <div class="border-top mt-4 mb-3">
                            <div class="product-option mb-4 mt-4">
                                <small class="text-uppercase d-block fw-bolder mb-2">
                                    Color : <span class="selected-option fw-bold" v-text="car.COLOR"></span>
                                </small>
                            </div>
                        </div>
                        <!-- /Product Options-->
                        <table class="table">
                            <tbody>
                                <tr>
                                    <th>Engine</th>
                                    <td v-html="car.ENG_V + ' cc'"></td>
                                </tr>
                                <tr>
                                    <th>Package</th>
                                    <td v-html="car.KUZOV"></td>
                                </tr>
                                <tr>
                                    <th>Transmission</th>
                                    <td v-html="car.KPP"></td>
                                </tr>
                                <tr>
                                    <th>Mileage</th>
                                    <td v-html="car.MILEAGE + ' km'"></td>
                                </tr>
                                <tr>
                                    <th>Grade</th>
                                    <td v-html="car.RATE"></td>
                                </tr>
                            </tbody>
                        </table>
                        <!-- Add To Cart-->
                        <div class="d-flex justify-content-between mt-3">
                            <router-link class="btn btn-dark btn-dark-chunky flex-grow-1 me-2 text-white" v-bind:to="'/chat/' + car.userId">Talk with Seller</router-link>
                        </div>
                        <!-- /Add To Cart-->
                    </div>
                </div>
                <!-- / Product Info Section-->
            </div>
        </section>
    </section>
</template>

<script>
	import axios from "axios"
    import swal from "sweetalert2"

	export default {
		name: "CarsForSaleComponent",
		data() {
			return {
				id: this.$route.params.id || 0,
				car: null,
				loading: false,
				selectedImageIndex: 0
			}
		},
		methods: {
			async getData() {
                this.loading = true
                try {
                    const formData = new FormData()
                    formData.append("id", this.id)
                    const response = await axios.post(
                        this.$apiURL + "/fetchCarForSale",
                        formData,
                        {
                            headers: this.$headers
                        }
                    )
                    if (response.data.status == "success") {
                        this.car = response.data.car
                        this.selectedImageIndex = 0
                    } else if (response.data.status == "premiumMembersOnly") {
                    	swal.fire("Error", response.data.message || "Unauthorized", "error") 
                    } else {
                    	swal.fire("Error", response.data.message || "Unauthorized", "error")
                    }
                } catch (error) {
                    if (error?.response?.status == 401) {
                        swal.fire("Error", error.response.data.message || "Unauthorized", "error")
                    }
                } finally {
                    this.loading = false
                }
            }
        },
		mounted() {
            this.getData()
            document.title = "Cars for Sale"
        }
	}
</script>