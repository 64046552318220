<template>
	<!-- Main Section-->
    <section class="mt-5 ">
        <!-- Page Content Goes Here -->
        <!-- Product Top-->
        <section class="container">
            <!-- Breadcrumbs-->
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item active" aria-current="page">Contact Us</li>
                </ol>
            </nav> <!-- /Breadcrumbs-->
            <div class="row g-5">
                <!-- Images Section-->
                <div class="offset-md-3 col-md-6">
                	<br />
                	<h2 class="text-center">Contact Us</h2>
                	<br />
                	<form style="display: contents;" v-on:submit.prevent="contactUs">
                		<div class="product-option">
                            <small class="text-uppercase d-block fw-bolder mb-2">
                                Name:
                            </small>
                            <div class="form-group">
                                <input name="name" type="text" class="form-control" required />
                            </div>
                        </div>
                		<div class="product-option">
                            <small class="text-uppercase d-block fw-bolder mb-2">
                                Email:
                            </small>
                            <div class="form-group">
                                <input name="email" type="email" class="form-control" required />
                            </div>
                        </div>
                        <div class="product-option">
                            <small class="text-uppercase d-block fw-bolder mb-2">
                                Message:
                            </small>
                            <div class="form-group">
                                <textarea name="message" rows="7" class="form-control" required></textarea>
                            </div>
                        </div>
                        <button type="submit" v-bind:disabled="contacting" class="btn btn-dark btn-dark-chunky flex-grow-1 me-2 text-white">Send Message</button>
                	</form>
                </div>
            </div>
        </section>
    </section>
</template>

<script>
	import axios from "axios"
	import swal from "sweetalert2"

	export default {
		name: "ContactUsComponent",

		data() {
			return {
				contacting: false
			}
		},

		methods: {
			async contactUs() {
				this.contacting = true
				const form = event.target
				const formData = new FormData(form)
                formData.append("homeUrl", this.$homeURL)
				try {
					const response = await axios.post(
						this.$apiURL + "/contactUs",
						formData,
						{
							headers: this.$headers
						}
					)
					swal.fire("Contact Us", response.data.message, response.data.status)
				} catch (error) {
                    if (error?.response?.status == 401) {
                        swal.fire("Error", error.response.data.message || "Unauthorized", "error")
                    }
                } finally {
                    this.contacting = false
                }
			}
		},

        mounted() {
            document.title = "Contact Us"
        }
	}
</script>