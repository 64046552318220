import { createApp } from 'vue'
import App from './App.vue'

// import the vue router necessary functions
import { createRouter, createWebHistory } from 'vue-router'
import VueMobileDetection from "vue-mobile-detection"

import HomeComponent from "./components/HomeComponent.vue"
import SearchComponent from "./components/SearchComponent.vue"
import CarDetailComponent from "./components/CarDetailComponent.vue"
import LoginComponent from "./components/LoginComponent.vue"
import AccountComponent from "./components/AccountComponent.vue"
import AboutUsComponent from "./components/AboutUsComponent.vue"
import FAQComponent from "./components/FAQComponent.vue"
import ContactUsComponent from "./components/ContactUsComponent.vue"
import InvestorComponent from "./components/Investor/InvestorComponent.vue"
import ForgotPasswordComponent from "./components/ForgotPasswordComponent.vue"
import ResetPasswordComponent from "./components/ResetPasswordComponent.vue"
import CarsForSaleComponent from "./components/CarsForSaleComponent.vue"
import ChatComponent from "./components/ChatComponent.vue"
import SaveLocalStorageComponent from "./components/SaveLocalStorageComponent.vue"
import InvoiceComponent from "./components/InvoiceComponent.vue"
import NotificationsComponent from "./components/NotificationsComponent.vue"
import CountriesComponent from "./components/CountriesComponent.vue"
import SingleCountryComponent from "./components/SingleCountryComponent.vue"

// Define some routes
// Each route should map to a component.
const routes = [
    { path: "/country/:name", component: SingleCountryComponent },
    { path: "/countries", component: CountriesComponent },
    { path: "/notifications", component: NotificationsComponent },
    { path: "/invoice/:id", component: InvoiceComponent },
    { path: "/saveLocalStorage", component: SaveLocalStorageComponent },
    { path: "/chat/:id?", component: ChatComponent },
    { path: "/investor", component: InvestorComponent },
    { path: "/carsForSale/:id", component: CarsForSaleComponent },
    { path: "/contactUs", component: ContactUsComponent },
    { path: "/faq", component: FAQComponent },
    { path: "/aboutUs", component: AboutUsComponent },
    { path: "/account", component: AccountComponent },
    { path: "/resetPassword", component: ResetPasswordComponent },
    { path: "/forgotPassword", component: ForgotPasswordComponent },
    { path: "/login", component: LoginComponent },
    { path: "/carDetail/:id", component: CarDetailComponent },
    { path: "/search", component: SearchComponent },
	{ path: "/", component: HomeComponent }
]

// Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = createRouter({
    // Provide the history implementation to use. We are using the hash history for simplicity here.
    history: createWebHistory(),
    routes, // short for `routes: routes`
})

const app = createApp(App)
app.use(VueMobileDetection)

app.config.globalProperties.$apiBaseUrl = "https://betaapi.usstokyo.com"
app.config.globalProperties.$apiURL = "https://betaapi.usstokyo.com/api"
app.config.globalProperties.$homeURL = "https://beta.usstokyo.com"
app.config.globalProperties.$postMessageURL = "https://betaadmin.usstokyo.com"
app.config.globalProperties.$nodeURL = "https://node.usstokyo.com:8000"
// app.config.globalProperties.$nodeURL = "http://node.usstokyo.com:3000"

// app.config.globalProperties.$apiBaseUrl = "http://localhost:8888/ussglobal/api"
// app.config.globalProperties.$apiURL = "http://localhost:8888/ussglobal/api/api"
// app.config.globalProperties.$homeURL = "http://localhost:8080"
// app.config.globalProperties.$postMessageURL = "http://localhost:8081"
// app.config.globalProperties.$nodeURL = "http://localhost:3000"

app.config.globalProperties.$months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
app.config.globalProperties.$accessToken = "accessToken"
app.config.globalProperties.$headers = {
    Authorization: "Bearer " + localStorage.getItem("accessToken")
}

app.mixin({
    methods: {
        prependArray(value, array) {
            const newArray = array.slice()
            newArray.unshift(value)
            return newArray
        },

        formatAMPM(date) {
            var hours = date.getHours();
            var minutes = date.getMinutes();
            var ampm = hours >= 12 ? 'pm' : 'am';
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            minutes = minutes < 10 ? '0'+minutes : minutes;
            var strTime = hours + ':' + minutes + ' ' + ampm;
            return strTime;
        },

        getDateTimeFormat(date) {
            const dateObj = new Date(date)
            return (dateObj.getDate() + "").padZero() + " " + this.$months[dateObj.getMonth()] + ", " + dateObj.getFullYear() + " " + this.formatAMPM(dateObj)
        },

        getDateFormat(date) {
            const dateObj = new Date(date)
            return dateObj.getDate() + " " + this.$months[dateObj.getMonth()] + ", " + dateObj.getFullYear()
        },

        closeModal(modelSelector) {
            // Get the modal
            var modal = document.querySelector(modelSelector)
            modal.style.display = "none"
        },

        showModal(modelSelector) {
            // Get the modal
            var modal = document.querySelector(modelSelector)
            // Get the <span> element that closes the modal
            var span = modal.querySelector(".close")
            // When the user clicks on <span> (x), close the modal
            span.onclick = function() {
                modal.style.display = "none"
            }
            // When the user clicks anywhere outside of the modal, close it
            window.onclick = function(event) {
                if (event.target == modal) {
                    modal.style.display = "none"
                }
            }
            modal.style.display = "block"
        },

        initializeUserMenu() {
            setTimeout(function () {
                document.querySelector(".user-menu").addEventListener("mouseover", function () {
                    this.className = "nav-item dropdown me-lg-4 user-menu show"
                    this.querySelector(".dropdown-menu").className = "dropdown-menu show"
                })

                document.querySelector(".user-menu").addEventListener("mouseout", function () {
                    this.className = "nav-item dropdown me-lg-4 user-menu"
                    this.querySelector(".dropdown-menu").className = "dropdown-menu"
                })
            }, 500)
        },

        getCurrentDate() {
            const date = new Date()
            return date.getFullYear() + "-" + ((date.getMonth() + 1) + "").padZero() + "-" + (date.getDate() + "").padZero()
        },

        addOrUpdateURLParam (key, value) {
            const searchParams = new URLSearchParams(window.location.search)
            searchParams.set(key, value)
            const newRelativePathQuery = window.location.pathname + "?" + searchParams.toString()
            history.pushState(null, "", newRelativePathQuery)
        },

        isVideo(file) {
            return file.includes("mp4") || file.includes("mkv") || file.includes("flv") || file.includes("avi")
        },

        isImage(image) {
            return image.includes("jpeg") || image.includes("jpg") || image.includes("png") || image.includes("heic")
        },

        getImage(imageArr, index) {
            if (index < imageArr.length) {
                return imageArr[index].replace("h=50", "w=320")
            }
            return ""
        }
    }
})

String.prototype.padZero = function () {
    return this < 10 ? "0" + this : this
}

app.use(router)
app.mount('#app')
