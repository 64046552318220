<template>
    <!-- Main Section-->
    <section class="mt-0 ">
        <!-- Category Top Banner -->
        <div class="py-6 bg-img-cover bg-dark bg-overlay-gradient-dark position-relative overflow-hidden mb-4 bg-pos-center-center" style="background-image: url(./assets/images/banners/banner-1.jpg);">
            <div class="container position-relative z-index-20" data-aos="fade-right" data-aos-delay="300" v-if="data != null">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item breadcrumb-light">
                            <router-link to="/">Home</router-link>
                        </li>
                        <li class="breadcrumb-item breadcrumb-light">Countries</li>
                        <li class="breadcrumb-item active breadcrumb-light" aria-current="page" v-text="name + ' | ' + data.region"></li>
                    </ol>
                </nav>
                <h1 class="fw-bold display-6 mb-4 text-white" v-text="name + ' | ' + data.region"></h1>
            </div>
        </div>
        <!-- Category Top Banner -->
        <div class="container">
            <div class="row">
                <!-- Category Aside/Sidebar -->
                <div class="col-lg-12">
                    <div class="text-center loader" v-if="loading">
                        <div class="spinner-border"></div>
                    </div>
                    <div id="content" v-if="data != null">
                    	<h2>Introduction</h2>
                    	<p v-html="data.intro"></p>

                        <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                            <ol class="carousel-indicators">
                                <li v-for="(s, index) in data.slides" data-target="#carouselExampleIndicators" v-bind:data-slide-to="index" v-bind:class="(index == 0) ? 'active' : ''"></li>
                            </ol>

                            <div class="carousel-inner">
                                <div v-for="(s, index) in data.slides" v-bind:class="'carousel-item' + (index == 0) ? ' active' : ''" style="display: inline-block;">
                                    <img v-bind:src="s" style="width: 300px;" />
                                </div>
                            </div>
                            
                            <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="sr-only">Previous</span>
                            </a>
                            <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="sr-only">Next</span>
                            </a>
                        </div>

                        <img v-bind:src="data.flag" style="width: 300px; margin-top: 20px; margin-bottom: 20px;" />

                        <table class="table table-bordered country-info">
                            <tr>
                        		<td>
                        			Currency:
                        			<span v-html="data.currency"></span>
                        		</td>

                                <td>
                                    Time Zone:
                                    <span v-html="data.timeZone"></span>
                                </td>
                        	</tr>

                        	<tr>
                        		<td>
                        			Shipping Time:
                        			<span v-html="data.shippingTime"></span>
                        		</td>

                                <td>
                                    Country Code:
                                    <span v-html="data.countryCode"></span>
                                </td>
                        	</tr>

                            <tr>
                                <td>
                                    Port:
                                    <span v-html="data.port"></span>
                                </td>

                                <td>
                                    Shipping Line:
                                    <span v-html="data.shippingLine"></span>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    Age Restrictions:
                                    <span v-html="data.ageRestrictions"></span>
                                </td>

                                <td>
                                    Inspection:
                                    <span v-html="data.inspection"></span>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    Import Duties and Taxes:
                                    <span v-html="data.importDutiesTaxes"></span>
                                </td>

                                <td>
                                    Logo:
                                    <img style="width: 300px;" v-bind:src="data.logo" />
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    Flag Big:
                                    <img style="width: 300px;" v-bind:src="data.flagBig" />
                                </td>

                                <td>
                                    Map:
                                    <img style="width: 300px;" v-bind:src="data.map" />
                                </td>
                            </tr>
                        </table>

                        <h2>Policy</h2>
                        <p v-html="data.policy"></p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import axios from "axios"
import swal from "sweetalert2"
import jQuery from "jquery"

export default {
    name: "SingleCountryComponent",

    data() {
        return {
            loading: false,
            name: this.$route.params.name || "",
            data: null
        }
    },

    methods: {
        async getData() {
            this.loading = true
            const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
            try {
                const formData = new FormData()
                formData.append("name", this.name)

                const response = await axios.post(
                    this.$apiURL + "/fetchSingleCountry",
                    formData, {
                        headers: this.$headers
                    }
                )
                if (response.data.status == "success") {
                    this.data = response.data.data
                    document.title = this.name + " | " + this.data.region
                }
            } catch (error) {
                if (error?.response?.status == 401) {
                    // swal.fire("Error", error.response.data.message || "Unauthorized", "error")
                }
            } finally {
                this.loading = false
            }
        }
    },

    mounted() {
        this.getData()
        document.title = this.name
    }
}

</script>

<style scoped>
	.country-info,
	.country-info th,
	.country-info td {
		padding: 10px;
	}
</style>