<template>
    <!-- Main Section-->
    <section class="mt-5 ">
        <!-- Page Content Goes Here -->
        <!-- Product Top-->
        <section class="container">
            <!-- Breadcrumbs-->
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
                    <li class="breadcrumb-item active" aria-current="page" v-text="car ? (car.MARKA_NAME + ' ' + car.MODEL_NAME) : ''"></li>
                </ol>
            </nav> <!-- /Breadcrumbs-->

            <div class="row">
                <div class="col-md-12">
                    <div class="text-center loader" v-if="loading">
                        <div class="spinner-border"></div>
                    </div>
                </div>
            </div>

            <div class="row g-5" v-if="car != null">
                <!-- Images Section-->
                <div class="col-12 col-lg-7">
                    <div class="row g-1">
                        <div class="swiper-container gallery-thumbs-vertical col-2 pb-4">
                            <div class="swiper-wrapper" style="display: block !important;">
                                <div class="swiper-slide bg-light bg-light h-auto" v-for="(image, index) in car.IMAGES" style="height: fit-content !important;" v-on:click="selectedImageIndex = index">
                                    <video v-if="isVideo(getImage(car.IMAGES, index))" v-bind:src="getImage(car.IMAGES, index)" class="img-fluid mx-auto d-table"></video>

                                    <picture v-else>
                                        <img class="img-fluid mx-auto d-table" v-bind:src="getImage(car.IMAGES, index)" v-bind:alt="car.MARKA_NAME + ' ' + car.MODEL_NAME" />
                                    </picture>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-container gallery-top-vertical col-10">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide bg-white h-auto">
                                    <video v-if="isVideo(getImage(car.IMAGES, selectedImageIndex))" v-bind:src="getImage(car.IMAGES, selectedImageIndex)" class="img-fluid mx-auto d-table" controls></video>

                                    <picture v-else>
                                        <img class="img-fluid d-table mx-auto" v-bind:src="getImage(car.IMAGES, selectedImageIndex)" v-bind:alt="car.MARKA_NAME + ' ' + car.MODEL_NAME" data-zoomable v-on:click="requestMoreImages" style="cursor: pointer;" />
                                    </picture>

                                    <button v-if="selectedImageIndex == 0" style="margin-top: 20px; margin-left: 30px;" type="button" class="btn btn-primary" v-on:click="requestTranslateAuctionSheet" v-bind:disabled="sendingTranslationRequest">Translate Auction Sheet</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- /Images Section-->
                <!-- Product Info Section-->
                <div class="col-12 col-lg-5">
                    <div class="pb-3">
                        <!-- Product Name, Review, Brand, Price-->
                        <div class="d-flex justify-content-between align-items-center mb-2">
                            <p class="small fw-bolder text-uppercase tracking-wider text-muted mb-0 lh-1" v-text="car.YEAR"></p>
                        </div>
                        <h1 class="mb-2 fs-2 fw-bold" v-text="car.MARKA_NAME + ' ' + car.MODEL_NAME"></h1>
                        <div class="justify-content-start align-items-center">
                            <p class="lead fw-bolder m-0 fs-3 lh-1 text-danger me-2" v-html="'Start at: &yen;' + car.START"></p>
                            <p class="lead fw-bolder m-0 fs-3 lh-1 text-success me-2" v-if="car.STATUS != '' && car.STATUS.toLowerCase() != 'removed'" style="margin-top: 10px !important;">
                                <span v-if="car.STATUS.toLowerCase() == 'cancelled'" v-html="car.STATUS" style="text-transform: capitalize;"></span>
                                <span v-else v-html="car.STATUS + ' at &yen;' + car.FINISH" style="text-transform: capitalize;"></span>
                            </p>
                        </div>
                        <!-- /Product Name, Review, Brand, Price-->
                        <!-- Product Views-->
                        <div class="d-flex justify-content-start mt-3">
                            <div class="alert bg-light rounded py-1 px-2 d-table m-0">
                                <div class="d-flex justify-content-start align-items-center">
                                    <!-- <i class="ri-fire-fill lh-1 text-orange"></i> -->
                                    <div class="ms-2temp">
                                        <small class="opacity-75 fw-bolder lh-1" v-text="car.RATE + ' Graded'"></small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- /Product Views-->
                        <form style="display: contents;" v-on:submit.prevent="placeBid">
                            <!-- Product Options-->
                            <div class="border-top mt-4 mb-3">
                                <div class="product-option mb-4 mt-4">
                                    <small class="text-uppercase d-block fw-bolder mb-2">
                                        Color : <span class="selected-option fw-bold" v-text="car.COLOR"></span>
                                    </small>
                                </div>
                                <div class="product-option">
                                    <small class="text-uppercase d-block fw-bolder mb-2">
                                        Your Bid:
                                    </small>
                                    <div class="form-group">
                                        <input name="bidAmount" type="number" min="0" v-model="bidValue" class="form-control" required />
                                    </div>
                                </div>

                                <div class="product-option">
                                    <small class="text-uppercase d-block fw-bolder mb-2">
                                        Comments:
                                    </small>
                                    <div class="form-group">
                                        <textarea name="comments" class="form-control" v-model="bidComments"></textarea>
                                    </div>
                                </div>
                                <div class="product-option" v-if="bid != null">
                                    <small class="text-uppercase d-block fw-bolder mb-2">
                                        Admin Comments:
                                    </small>
                                    <div class="form-group">
                                        <textarea name="comments" class="form-control" v-model="bid.adminComments" readonly></textarea>
                                    </div>
                                </div>
                            </div>
                            <!-- /Product Options-->
                            <!-- Add To Cart-->
                            <div class="d-flex justify-content-between mt-3">
                                <button type="submit" v-bind:disabled="bidding" class="btn btn-dark btn-dark-chunky flex-grow-1 me-2 text-white">Place Bid</button>
                                <button type="button" v-bind:disabled="wishlisting" v-on:click="toggleWishlist" class="btn btn-orange btn-orange-chunky"><i v-bind:class="wishlist != null ? 'ri-heart-fill' : 'ri-heart-line'"></i></button>
                            </div>
                        </form>
                        <!-- /Add To Cart-->
                        <!-- Socials-->
                        <div class="my-4">
                            <div class="d-flex justify-content-start align-items-center">
                                <p class="fw-bolder lh-1 mb-0 me-3">Share</p>
                                <ul class="list-unstyled p-0 m-0 d-flex justify-content-start lh-1 align-items-center mt-1">
                                    <li class="me-2">
                                        <a class="text-decoration-none" v-bind:href="'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent($homeURL + '/' + $route.path)" role="button" target="_blank" rel="noopener">
                                            <i class="ri-facebook-box-fill"></i>
                                        </a>
                                    </li>
                                    <li class="me-2">
                                        <a class="text-decoration-none" v-bind:href="'https://www.instagram.com/?url=' + ($homeURL + '/' + $route.path)" target="_blank" rel="noopener" role="button">
                                            <i class="ri-instagram-fill"></i>
                                        </a>
                                    </li>
                                    <li class="me-2">
                                        <a class="text-decoration-none" v-bind:href="'https://twitter.com/intent/tweet?url=' + encodeURIComponent($homeURL + '/' + $route.path) + '&amp;text=' + car.MARKA_NAME + ' ' + car.MODEL_NAME + ' - ' + car.YEAR" role="button" target="_blank" rel="noopener">
                                            <i class="ri-twitter-fill"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <!-- Socials-->
                        <!-- Special Offers-->
                        <div class="bg-light rounded py-2 px-3">
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item d-flex border-0 px-0 bg-transparent">
                                    <i class="ri-truck-line"></i>
                                    <span class="fs-6 ms-3">Standard delivery free for orders over $99. Next day delivery $9.99</span>
                                </li>
                            </ul>
                        </div>
                        <!-- /Special Offers-->
                    </div>
                </div>
                <!-- / Product Info Section-->
            </div>
        </section>
        <!-- / Product Top-->
        <section>
            <!-- Product Tabs-->
            <div class="mt-7 pt-5 border-top">
                <div class="container">
                    <!-- Tab Nav-->
                    <ul class="nav justify-content-center nav-tabs nav-tabs-border mb-4" id="myTab" role="tablist">
                        <li class="nav-item w-100 mb-2 mb-sm-0 w-sm-auto mx-sm-3" role="presentation">
                            <a class="nav-link fs-5 fw-bolder nav-link-underline mx-sm-3 px-0 active" id="details-tab" data-bs-toggle="tab" href="#details" role="tab" aria-controls="details" aria-selected="true">The Details</a>
                        </li>
                        <li class="nav-item w-100 mb-2 mb-sm-0 w-sm-auto mx-sm-3" role="presentation">
                            <a class="nav-link fs-5 fw-bolder nav-link-underline mx-sm-3 px-0" id="returns-tab" data-bs-toggle="tab" href="#returns" role="tab" aria-controls="returns" aria-selected="false">Returns</a>
                        </li>
                    </ul>
                    <!-- / Tab Nav-->
                    <!-- Tab Content-->
                    <div class="tab-content" id="myTabContent" v-if="car != null">
                        <!-- Tab Details Content-->
                        <div class="tab-pane fade show active py-5" id="details" role="tabpanel" aria-labelledby="details-tab">
                            <div class="col-12 col-lg-10 mx-auto">
                                <div class="row g-5">
                                    <div class="col-12 col-md-6">
                                        <ul>
                                            <li v-html="'LOT: ' + car.LOT"></li>
                                            <li v-html="'Auctioned at: ' + car.AUCTION_DATE.split(' ')[0]"></li>
                                            <li v-html="'Name: ' + car.MARKA_NAME + ' ' + car.MODEL_NAME"></li>
                                            <li v-html="'Year: ' + car.YEAR"></li>
                                            <li v-html="'Engine: ' + car.ENG_V + 'cc'"></li>
                                        </ul>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <ul>
                                            <li v-html="'Package: ' + car.KUZOV"></li>
                                            <li v-html="'Color: ' + car.COLOR"></li>
                                            <li v-html="'Transmission: ' + car.KPP"></li>
                                            <li v-html="'Mileage: ' + car.MILEAGE + ' km'"></li>
                                            <li v-html="'Grade: ' + car.RATE"></li>
                                            <li v-html="'Starting Price: &yen;' + car.START"></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Tab Details Content-->
                        <!-- Returns Tab Content-->
                        <div class="tab-pane fade py-5" id="returns" role="tabpanel" aria-labelledby="returns-tab">
                            <div class="col-12 col-md-10 col-lg-8 mx-auto">
                                <p>We believe you will completely happy with your item, however if you aren't, there's no need to worry. We've
                                    listed below the ways you can return your item to us.</p>
                                <ul class="list-group list-group-flush mb-4">
                                    <li class="list-group-item px-0 py-4 bg-transparent">
                                        <p class="fw-bolder">Return via post</p>
                                        <p class="fs-6">To return your items for free through the postal system, please complete the returns form that
                                            comes with your order. You'll find a label at the bottom of the form. Simply peel the label and head to your
                                            nearest post office.</p>
                                    </li>
                                    <li class="list-group-item px-0 py-4 bg-transparent">
                                        <p class="fw-bolder">Return in person</p>
                                        <p class="fs-6">To return your items for free in person, simply stop into any one of our locations and speak
                                            to a member of our in-store team.</p>
                                    </li>
                                </ul>
                                <div class="bg-light rounded p-3">
                                    <p class="fs-6">Form more information, please see our returns FAQs <a href="#">here</a></p>
                                    <p class="m-0 fs-6">If you do not find the answer to your query, please contact our customer support team on
                                        <b>0800 123 456</b> or email us at <b>support@domain.com</b>. We aim to respond within 48 hours to queries.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <!-- / Returns Tab Content-->
                    </div>
                    <!-- / Tab Content-->
                </div>
            </div>
            <!-- / Product Tabs-->
        </section>
    </section>
    <!-- / Main Section-->
</template>

<script>

	import axios from "axios"
    import swal from "sweetalert2"

	export default {
	    name: "CarDetailComponent",

	    data() {
            return {
                id: this.$route.params.id || "",
                loading: false,
                car: null,
                bid: null,
                wishlist: null,
                selectedImageIndex: 0,
                bidding: false,
                bidValue: 0,
                bidComments: "",
                wishlisting: false,
                sendingTranslationRequest: false
            }
        },

        methods: {

            async requestTranslateAuctionSheet() {
                this.sendingTranslationRequest = true

                const formData = new FormData()
                formData.append("id", this.id)
                formData.append("type", "auctionSheet")

                try {
                    const response = await axios.post(
                        this.$apiURL + "/sendRequest",
                        formData,
                        {
                            headers: this.$headers
                        }
                    )
                 
                    if (response.data.status == "success") {
                        swal.fire("Translation of Auction Sheet Requested", response.data.message, "success")
                    } else {
                        swal.fire("Translation of Auction Sheet Requested", response.data.message, "info")
                    }
                } catch (error) {
                    if (error.response.status == 401) {
                        swal.fire("Error", error.response.data.message || "Unauthorized", "error")
                    }
                } finally {
                    this.sendingTranslationRequest = false
                }
            },

            requestMoreImages: async function () {
                if (!(this.car.AUCTION.includes('USS') && this.selectedImageIndex == this.car.IMAGES.length - 1)) {
                    return
                }

                this.requestingMoreImages = true

                const formData = new FormData()
                formData.append("id", this.id)
                formData.append("type", "images")

                try {
                    const response = await axios.post(
                        this.$apiURL + "/sendRequest",
                        formData,
                        {
                            headers: this.$headers
                        }
                    )

                    this.requestingMoreImages = false
                 
                    if (response.data.status == "success") {
                        swal.fire("More Images Requested", response.data.message, "success")
                    } else {
                        swal.fire("More Images", response.data.message, "info")
                    }
                } catch (error) {
                    this.requestingMoreImages = false

                    if (error.response.status == 401) {
                        swal.fire("Error", error.response.data.message || "Unauthorized", "error")
                    }
                }
            },

            async toggleWishlist() {
                this.wishlisting = true
                try {
                    const formData = new FormData()
                    formData.append("carId", this.id)

                    const response = await axios.post(
                        this.$apiURL + "/toggleWishlist",
                        formData,
                        {
                            headers: this.$headers
                        }
                    )

                    if (response.data.status == "success") {
                        this.wishlist = response.data.wishlist
                        // swal.fire("Wishlist", response.data.message, "success")
                    } else {
                        swal.fire("Error", response.data.message, "error")
                    }
                } catch (error) {
                    if (error?.response?.status == 401) {
                        swal.fire("Error", error.response.data.message || "Unauthorized", "error")
                    }
                } finally {
                    this.wishlisting = false
                }
            },

            async placeBid() {
                this.bidding = true
                try {
                    const form = event.target
                    const formData = new FormData(form)
                    formData.append("id", this.id)

                    const response = await axios.post(
                        this.$apiURL + "/placeBid",
                        formData,
                        {
                            headers: this.$headers
                        }
                    )

                    if (response.data.status == "success") {
                        this.bid = response.data.bid
                        this.bidValue = form.bidAmount.value
                        this.bidComments = form.comments.value
                        swal.fire("Bid Placed", response.data.message, "success")
                    } else {
                        swal.fire("Error", response.data.message, "error")
                    }
                } catch (error) {
                    if (error?.response?.status == 401) {
                        swal.fire("Error", error.response.data.message || "Unauthorized", "error")
                    }
                } finally {
                    this.bidding = false
                }
            },

        	async getData() {
                this.loading = true
                try {
                    const formData = new FormData()
                    formData.append("id", this.id)

                    const response = await axios.post(
                        this.$apiURL + "/carDetail",
                        formData,
                        {
                            headers: this.$headers
                        }
                    )

                    if (response.data.status == "success") {
                        this.car = response.data.car
                        this.bid = response.data.bid
                        this.wishlist = response.data.wishlist
                        this.selectedImageIndex = 0
                        if (this.bid == null) {
                            this.bidValue = parseInt(this.car.START) + 10000
                        } else {
                            this.bidValue = this.bid.bidAmount
                            this.bidComments = this.bid.comments ?? ""
                        }

                        document.title = this.car.MARKA_NAME + " " + this.car.MODEL_NAME + " - " + this.car.YEAR
                    } else if (response.data.status == "premiumMembersOnly") {
                    	swal.fire("Error", response.data.message || "Unauthorized", "error") 
                    } else {
                    	swal.fire("Error", response.data.message || "Unauthorized", "error")
                    }
                } catch (error) {
                    if (error?.response?.status == 401) {
                        swal.fire("Error", error.response.data.message || "Unauthorized", "error")
                    }
                } finally {
                    this.loading = false
                }
            }
        },

        mounted() {
            this.getData()
        },

        watch: {
            car(to, from) {
                // console.log(to)
            }
        }
	}
</script>
