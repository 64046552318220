<template>
	<div class="row">
		<div class="col-md-12">
			<div class="text-center loader" v-if="loading">
		        <div class="spinner-border"></div>
		    </div>

		    <div class="table-responsive">
				<table v-if="!loading" class="table" id="data-table">

					<thead>
		                <tr v-if="$isMobile()">
		                    <th></th>
		                </tr>

		                <tr v-else>
		                	<th>LOT</th>
		                	<th>Images</th>
		                	<th>Time</th>
		                	<th>Year</th>
		                	<th>Name</th>
		                	<th>Chassis</th>
		                	<th>Package</th>
		                	<th>Mileage</th>
		                	<th>Displace</th>
		                	<th>Transmission</th>
		                	<th>Grade</th>
		                	<th>Color</th>
		                	<th>Starting Price</th>
		                	<th>Bid Price</th>
		                	<th>Awarded Price</th>
		                	<th>Actions</th>
		                </tr>
		            </thead>

		            <tbody>
	                    <template v-for="(d, index) in data">
	                    	<tr v-if="$isMobile()">
	                    		<th>
	                    			<table>
	                    				<tr>
											<th>LOT</th>
											<td v-text="d.auctionCar.LOT"></td>
										</tr>

										<tr>
											<th>Images</th>
											<td>
												<router-link v-bind:to="'/carDetail/' + d.auctionCar.CAR_ID">
													<template v-for="(img, index) in d.auctionCar.IMAGES">
														<img v-if="index < 3" v-bind:src="img.replaceAll('h=50', 'w=320')" style="height: 100px; display: inline-block;" />
													</template>
												</router-link>
											</td>
										</tr>

										<tr>
											<th>Time</th>
											<td v-text="d.auctionCar.TIME"></td>
										</tr>

										<tr>
											<th>Year</th>
											<td v-text="d.auctionCar.YEAR"></td>
										</tr>

										<tr>
											<th>Name</th>
											<td v-text="d.auctionCar.MARKA_NAME + ' ' + d.auctionCar.MODEL_NAME"></td>
										</tr>

										<tr>
											<th>Chassis</th>
											<td v-text="d.auctionCar.KUZOV"></td>
										</tr>

										<tr>
											<th>Package</th>
											<td v-html="d.auctionCar.GRADE"></td>
										</tr>

										<tr>
											<th>Mileage</th>
											<td v-text="d.auctionCar.MILEAGE + ' km'"></td>
										</tr>

										<tr>
											<th>Displace</th>
											<td v-text="d.auctionCar.ENG_V + ' cc'"></td>
										</tr>

										<tr>
											<th>Transmission</th>
											<td v-text="d.auctionCar.KPP"></td>
										</tr>
										
										<tr>
											<th>Grade</th>
											<td v-text="d.auctionCar.RATE"></td>
										</tr>

										<tr>
											<th>Color</th>
											<td v-text="d.auctionCar.COLOR"></td>
										</tr>
										
										<tr>
											<th>Starting Price</th>
											<td v-html="'&yen;' + d.auctionCar.START"></td>
										</tr>

										<tr>
											<th>Bid Price</th>
											<td v-html="'&yen;' + d.bidAmount"></td>
										</tr>

										<tr>
											<th>Awarded Price</th>
											<td v-html="'&yen;' + d.awardedPrice"></td>
										</tr>

										<tr>
											<th class="border-top-0">Actions</th>
											<td>
												<router-link v-bind:to="'/account?tab=tracking&id=' + d.auctionCarId">
													Tracking
												</router-link>
											</td>
										</tr>
	                    			</table>
	                    		</th>
	                    	</tr>

	                    	<tr v-else>
	                    		<td v-text="d.auctionCar.LOT"></td>
								<td>
									<router-link v-bind:to="'/carDetail/' + d.auctionCar.CAR_ID">
										<template v-for="(img, index) in d.auctionCar.IMAGES">
											<img v-if="index < 3" v-bind:src="img.replaceAll('h=50', 'w=320')" style="height: 100px; display: inline-block;" />
										</template>
									</router-link>
								</td>
								<td v-text="d.auctionCar.TIME"></td>
								<td v-text="d.auctionCar.YEAR"></td>
								<td v-text="d.auctionCar.MARKA_NAME + ' ' + d.auctionCar.MODEL_NAME"></td>
								<td v-text="d.auctionCar.KUZOV"></td>
								<td v-html="d.auctionCar.GRADE"></td>
								<td v-text="d.auctionCar.MILEAGE + ' km'"></td>
								<td v-text="d.auctionCar.ENG_V + ' cc'"></td>
								<td v-text="d.auctionCar.KPP"></td>
								<td v-text="d.auctionCar.RATE"></td>
								<td v-text="d.auctionCar.COLOR"></td>
								<td v-html="'&yen;' + d.auctionCar.START"></td>
								<td v-html="'&yen;' + d.bidAmount"></td>
								<td v-html="'&yen;' + d.awardedPrice"></td>
								<td>
									<router-link v-bind:to="'/account?tab=tracking&id=' + d.auctionCarId">
										Tracking
									</router-link>
								</td>
	                    	</tr>
	                    </template>
	                </tbody>
	            </table>
	        </div>

            <div v-if="pages > 0" id="pagination">
				<nav aria-label="Page navigation">
					<ul class="pagination">
						<li v-for="page in pages" v-bind:class="'page-item ' + (page == pageNumber ? 'active' : '')">
							<a class="page-link" href="javascript:void(0)" v-on:click="paginate(page)" v-text="page"></a>
						</li>
					</ul>
				</nav>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from "axios"
    import swal from "sweetalert2"
    import jQuery from "jquery"
    import store from "../vuex/store"

	export default {
		name: "MyPurchasesComponent",

		data() {
			return {
				loading: false,
				data: [],
				dataTable: null,
				pages: 0,
				pageNumber: this.$route.query.page || 1
			}
		},

		methods: {
			paginate: function (page) {
				this.pageNumber = page
				this.addOrUpdateURLParam("page", page)

				this.getData()
			},
        	getData: async function () {
        		const self = this

        		this.loading = true
        		this.data = []

        		if (this.dataTable != null) {
	            	this.dataTable.destroy()
	            }

        		const formData = new FormData()
        		formData.append("page", this.pageNumber)

        		try {
        			const response = await axios.post(
				        this.$apiURL + "/myPurchases",
				        formData,
				        {
				            headers: this.$headers
				        }
				    )
				 
				    if (response.data.status == "success") {
				    	this.data = response.data.data
				    	this.pages = response.data.pages
						this.pageNumber = response.data.pageNumber
				    } else {
				        swal.fire("Error", response.data.message, "error")
				    }
        		} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.loading = false
					setTimeout(function () {
			    		self.initialize()
			    	}, 100)
				}
			},
			initialize() {
				if (this.dataTable != null) {
					return
				}
				if (this.$isMobile()) {
					this.dataTable = jQuery("#data-table").DataTable({
		            	ordering: false,
	                    pageLength: 1
		            }) 
				} else {
					this.dataTable = jQuery("#data-table").DataTable({
		            	ordering: false
		            })
				}
	        }
		},

		mounted() {
        	this.getData()

        	document.title = "My Purchases"
        }
	}
</script>