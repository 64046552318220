<template>
	<div class="row">
		<div class="col-md-12">
			<input type="text" name="date" class="form-control" v-model="date" />
		</div>
	</div>

	<div class="row" style="margin-top: 50px;">
		<div class="col-md-12">

			<div class="text-center loader" v-if="loading">
		        <div class="spinner-border"></div>
		    </div>

		    <div class="table-responsive">
				<table v-if="!loading" class="table" id="data-table">

					<thead>
		                <tr v-if="$isMobile()">
		                    <th></th>
		                </tr>

		                <tr v-else>
		                	<th>LOT</th>
		                	<th>Images</th>
		                	<th>Time</th>
		                	<th>Year</th>
		                	<th>Name</th>
		                	<th>Chassis</th>
		                	<th>Package</th>
		                	<th>Mileage</th>
		                	<th>Displace</th>
		                	<th>Transmission</th>
		                	<th>Grade</th>
		                	<th>Color</th>
		                	<th>Starting Price</th>
		                	<th>Bid Price</th>
		                	<th>Comments</th>
		                	<th>Status</th>
		                	<th>Result</th>
		                </tr>
		            </thead>

		            <tbody>
	                    <template v-for="(d, index) in data">
	                    	<tr v-if="$isMobile()">
	                    		<th>
	                    			<table>
	                    				<tr v-if="d.type == 'bookmark'">
	                    					<th style="color: #a6c76c;">Wishlist</th>
	                    					<td></td>
	                    				</tr>

	                    				<tr v-if="d.isTrashed">
	                    					<th style="color: red;">Deleted</th>
	                    					<td></td>
	                    				</tr>

	                    				<tr>
											<th>LOT</th>
											<td v-text="d.auctionCar.LOT"></td>
										</tr>

										<tr>
											<th>Images</th>
											<td>
												<router-link v-bind:to="'/carDetail/' + d.auctionCar.CAR_ID">
													<template v-for="(img, index) in 2">
														<img v-if="index < d.auctionCar.IMAGES.length" v-bind:src="d.auctionCar.IMAGES[index].replaceAll('h=50', 'w=320')" style="height: 100px; display: inline-block;" />
													</template>
												</router-link>
											</td>
										</tr>

										<tr>
											<th>Time</th>
											<td v-text="d.auctionCar.TIME"></td>
										</tr>

										<tr>
											<th>Year</th>
											<td v-text="d.auctionCar.YEAR"></td>
										</tr>

										<tr>
											<th>Name</th>
											<td v-text="d.auctionCar.MARKA_NAME + ' ' + d.auctionCar.MODEL_NAME"></td>
										</tr>

										<tr>
											<th>Chassis</th>
											<td v-text="d.auctionCar.KUZOV"></td>
										</tr>

										<tr>
											<th>Package</th>
											<td v-html="d.auctionCar.GRADE"></td>
										</tr>

										<tr>
											<th>Mileage</th>
											<td v-text="d.auctionCar.MILEAGE + ' km'"></td>
										</tr>

										<tr>
											<th>Displace</th>
											<td v-text="d.auctionCar.ENG_V + ' cc'"></td>
										</tr>

										<tr>
											<th>Transmission</th>
											<td v-text="d.auctionCar.KPP"></td>
										</tr>
										
										<tr>
											<th>Grade</th>
											<td v-text="d.auctionCar.RATE"></td>
										</tr>

										<tr>
											<th>Color</th>
											<td v-text="d.auctionCar.COLOR"></td>
										</tr>
										
										<tr>
											<th>Starting Price</th>
											<td v-html="'&yen;' + d.auctionCar.START"></td>
										</tr>

										<tr>
											<th>Bid Price</th>
											<td>

												<span v-html="'&yen;' + d.bidAmount"></span>

												<p v-if="d.type == 'bookmark'" style="color: #a6c76c;">
			                    					Wishlist
			                    				</p>

			                    				<p v-if="d.isTrashed" style="color: red;">
			                    					Deleted
			                    				</p>
											</td>
										</tr>

										<tr>
											<th>Comments</th>
											<td>
												<p v-text="d.comments"></p>
												<p v-if="d.adminComments != null" v-text="'Admin comments: ' + d.adminComments"></p>
											</td>
										</tr>

										<tr>
											<th>Status</th>
											<td>
												<template v-if="d.isAwarded">
													Awarded
												</template>

												<p v-else v-text="d.auctionCar.STATUS"></p>
											</td>
										</tr>

										<tr>
											<th>Result</th>
											<td v-if="d.auctionCar.FINISH != '0'" v-html="'&yen;' + d.auctionCar.FINISH"></td>
										</tr>
	                    			</table>
	                    		</th>
	                    	</tr>

	                    	<tr v-else>
								<td v-text="d.auctionCar.LOT"></td>

								<td>
									<router-link v-bind:to="'/carDetail/' + d.auctionCar.CAR_ID">
										<template v-for="(img, index) in 2">
											<img v-if="index < d.auctionCar.IMAGES.length" v-bind:src="d.auctionCar.IMAGES[index].replaceAll('h=50', 'w=320')" style="height: 100px; display: inline-block;" />
										</template>
									</router-link>
								</td>

								<td v-text="d.auctionCar.TIME"></td>
								<td v-text="d.auctionCar.YEAR"></td>
								<td v-text="d.auctionCar.MARKA_NAME + ' ' + d.auctionCar.MODEL_NAME"></td>
								<td v-text="d.auctionCar.KUZOV"></td>
								<td v-html="d.auctionCar.GRADE"></td>
								<td v-text="d.auctionCar.MILEAGE + ' km'"></td>
								<td v-text="d.auctionCar.ENG_V + ' cc'"></td>
								<td v-text="d.auctionCar.KPP"></td>
								<td v-text="d.auctionCar.RATE"></td>
								<td v-text="d.auctionCar.COLOR"></td>
								<td v-html="'&yen;' + d.auctionCar.START"></td>
								<td>
									<span v-html="'&yen;' + d.bidAmount"></span>
									
									<p v-if="d.isTrashed" style="color: red;">Deleted</p>

									<p v-if="d.type == 'bookmark'" style="color: #a6c76c;">Wishlist</p>
								</td>
								<td>
									<p v-text="d.comments"></p>
									<p v-if="d.adminComments != null" v-text="'Admin comments: ' + d.adminComments"></p>
								</td>
								<td>
									<template v-if="d.isAwarded">
										Awarded
									</template>

									<p v-else v-text="d.auctionCar.STATUS"></p>
								</td>
								<td v-html="'&yen;' + d.auctionCar.FINISH"></td>
	                    	</tr>
	                    </template>
	                </tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>

	import axios from "axios"
    import swal from "sweetalert2"
    import jQuery from "jquery"

    import "../assets/js/jquery.js"
    import "../assets/datetimepicker/jquery.datetimepicker.min.css"
    import "../assets/datetimepicker/jquery.datetimepicker.full.js"

    import "../assets/datatable/datatables.css"
    import "../assets/datatable/datatables.min.js"

	export default {
		name: "MyBidsComponent",

		data() {
            return {
                date: this.$route.query.date || this.getCurrentDate(),
                loading: false,
                data: [],
                dataTable: null,
                id: this.$route.query.id || ""
            }
        },

        methods: {

            async getData() {
            	const self = this
                this.loading = true

                if (this.dataTable != null) {
	            	this.dataTable.destroy()
	            }

                try {
                    const formData = new FormData()
                    formData.append("date", this.date)
                    formData.append("id", this.id)

                    const response = await axios.post(
                        this.$apiURL + "/myBids",
                        formData,
                        {
                            headers: this.$headers
                        }
                    )

                    if (response.data.status == "success") {
                        this.data = response.data.data
                    }
                } catch (error) {
                    if (error?.response?.status == 401) {
                        swal.fire("Error", error.response.data.message || "Unauthorized", "error")
                    }
                } finally {
                    this.loading = false

                    setTimeout(function () {
			    		self.initializeTable()
			    	}, 100)
                }
            },

            initializeTable() {
	            if (this.$isMobile()) {
	            	this.dataTable = jQuery("#data-table").DataTable({
		            	ordering: false,
	                    pageLength: 1
		            })
	            } else {
	            	this.dataTable = jQuery("#data-table").DataTable({
		            	ordering: false
		            })
	            }
	        }
        },

		mounted() {
			const self = this
            this.getData()

            setTimeout(function () {
                jQuery("input[name='date']").datetimepicker({
                    format: "Y-m-d",
                    timepicker: false,
                    scrollMonth: false,
                    onSelectDate: function() {
	                	const d = jQuery("input[name='date']").datetimepicker('getValue')
	                	const dDate = new Date(d)
	                	self.date = dDate.getFullYear() + "-" + ((dDate.getMonth() + 1) + "").padZero() + "-" + (dDate.getDate() + "").padZero()
	                	self.addOrUpdateURLParam("date", self.date)
	                    self.getData()
	                }
                })
            }, 100)

            document.title = "My Bids"
        }
	}
</script>