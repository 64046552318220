<template>
	<!-- Main Section-->
    <section class="mt-5 ">
        <!-- Page Content Goes Here -->
        <!-- Product Top-->
        <section class="container">
            <!-- Breadcrumbs-->
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                	<li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
                    <li class="breadcrumb-item active" aria-current="page">Account</li>
                </ol>
            </nav> <!-- /Breadcrumbs-->
            <br />
            <div class="row g-5">
                <!-- Images Section-->
                <div class="col-md-2">
                	<ul class="list-group">
                        <li class="list-group-item">
                            <router-link style="text-decoration: none;" to="/account">My Account</router-link>
                        </li>
                        <li class="list-group-item">
                            <router-link style="text-decoration: none;" to="/account?tab=myInvoices">My Invoices</router-link>
                        </li>
                        <li class="list-group-item">
                            <router-link style="text-decoration: none;" to="/account?tab=myBids">My Bids</router-link>
                        </li>
                        <li class="list-group-item">
                            <router-link style="text-decoration: none;" to="/account?tab=myPurchases">My Purchases</router-link>
                        </li>
                        <li class="list-group-item">
                            <router-link style="text-decoration: none;" to="/account?tab=transactions">My Transactions</router-link>
                        </li>
                        <li class="list-group-item">
                            <router-link style="text-decoration: none;" to="/account?tab=myRequests">My Requests</router-link>
                        </li>
                    </ul>
                </div>

                <div class="col-md-10">
                    <template v-if="tab == ''">
                        <div class="row">
                            <div class="col-md-12">
                                <form v-on:submit.prevent="updateAccount" v-if="user != null" id="form-account">
                                    <div class="form-group">
                                        <label>Name</label>
                                        <input type="text" name="name" class="form-control" required v-model="user.name" />
                                    </div>
                                    <div class="form-group">
                                        <label>Email</label>
                                        <input type="email" name="email" class="form-control" readonly v-model="user.email" />
                                    </div>
                                    <div class="form-group">
                                        <label>Password</label>
                                        <textarea type="password" name="password" class="form-control" rows="1" oninput="this.value = this.value.replace(/\n/g, '')" style="resize: none;
                                        min-height: fit-content;
                                        font-family: sans-serif;
                                        font-size: 14px;
                                        color: transparent;
                                        text-shadow: 0 0 8px rgba(0,0,0,0.5);"></textarea>
                                    </div>
                                    <div class="form-group">
                                        <label>Confirm Password</label>
                                        <textarea type="password" name="confirmPassword" class="form-control" rows="1" oninput="this.value = this.value.replace(/\n/g, '')" style="resize: none;
                                        min-height: fit-content;
                                        font-family: sans-serif;
                                        font-size: 14px;
                                        color: transparent;
                                        text-shadow: 0 0 8px rgba(0,0,0,0.5);"></textarea>
                                    </div>
                                    <button type="submit" name="submit" class="btn btn-outline-dark" v-bind:disabled="updating">Update Account <i v-if="updating" class="fa fa-spinner fa-spin"></i></button>
                                </form>
                            </div>
                        </div>
                    </template>
                    <MyInvoicesComponent v-if="tab == 'myInvoices'" />
                    <MyBidsComponent v-if="tab == 'myBids'" />
                    <MyPurchasesComponent v-if="tab == 'myPurchases'" />
                    <TrackingComponent v-if="tab == 'tracking'" />
                    <TransactionsComponent v-if="tab == 'transactions'" />
                    <MyRequestsComponent v-if="tab == 'myRequests'" />
                </div>
            </div>
        </section>
    </section>
</template>

<script>
    import axios from "axios"
    import swal from "sweetalert2"
    import store from "../vuex/store"
    import MyBidsComponent from "./MyBidsComponent.vue"
    import MyPurchasesComponent from "./MyPurchasesComponent.vue"
    import TrackingComponent from "./TrackingComponent.vue"
    import TransactionsComponent from "./TransactionsComponent.vue"
    import MyRequestsComponent from "./MyRequestsComponent.vue"
    import MyInvoicesComponent from "./MyInvoicesComponent.vue"

	export default {
		name: "AccountComponent",
        components: {
            MyBidsComponent,
            MyPurchasesComponent,
            TrackingComponent,
            TransactionsComponent,
            MyRequestsComponent,
            MyInvoicesComponent
        },
        computed: {
            user () {
                return store.getters.getUser
            },
            loadingUser () {
                return store.getters.getLoadingUser
            }
        },
        data() {
            return {
                updating: false,
                tab: this.$route.query.tab || ""
            }
        },
        methods: {
            async updateAccount() {
                this.updating = true
                try {
                    const form = event.target
                    const formData = new FormData(form)
                    const response = await axios.post(
                        this.$apiURL + "/updateAccount",
                        formData,
                        {
                            headers: this.$headers
                        }
                    )
                    swal.fire("Update Account", response.data.message, response.data.status)
                    if (response.data.status == "success") {
                        store.commit("setUser", response.data.user)
                    }
                } catch (error) {
                    if (error?.response?.status == 401) {
                        swal.fire("Error", error.response.data.message || "Unauthorized", "error")
                    }
                } finally {
                    this.updating = false
                }
            }
        },
        mounted() {
            /*setTimeout(function () {
                document.getElementById("form-account").password.value = ""
                document.getElementById("form-account").confirmPassword.value = ""
            }, 1000)*/

            document.title = "My Account"
        },
        watch: {
            "$route.query": function(to, from) {
                this.tab = to.tab || ""
            }
        }
	}
</script>