<template>
    <!-- Footer -->
    <!-- Footer-->
    <footer class="bg-dark mt-10">
        <!-- Footer socials-->
        <div class="bg-light py-4">
            <div class="container d-flex justify-content-center align-items-center py-2">
                <p class="lead fw-bolder mb-0 lh-1">Find us online</p>
                <ul class="list-unstyled d-flex justify-content-start align-items-center mb-0 ms-3 lh-1">
                    <li class="mx-1 mb-0 lh-1"><a class="text-muted text-decoration-none opacity-75-hover transition-all lh-1" href="#"><i class="ri-instagram-fill ri-xl lh-1"></i></a></li>
                    <li class="mx-1 mb-0 lh-1"><a class="text-muted text-decoration-none opacity-75-hover transition-all lh-1" href="#"><i class="ri-facebook-fill ri-xl lh-1"></i></a></li>
                    <li class="mx-1 mb-0 lh-1"><a class="text-muted text-decoration-none opacity-75-hover transition-all lh-1" href="#"><i class="ri-twitter-fill ri-xl lh-1"></i></a></li>
                </ul>
            </div>
        </div>
        <!-- / Footer socials-->
        <!-- Instagram Display-->
        <div class="container pt-8">
            <div class="row g-2">
                <div class="d-none d-md-block col-md-4" data-aos="fade-in" data-aos-delay="150">
                    <picture>
                        <img class="img-fluid" src="../../assets/images/instagram/instagram-1.jpg" alt="Bootstrap 5 Template by Pixel Rocket" data-zoomable>
                    </picture>
                </div>
                <div class="col-12 col-md-8" data-aos="fade-in" data-aos-delay="300">
                    <div class="row g-2">
                        <div class="col-3">
                            <picture>
                                <img class="img-fluid" src="../../assets/images/instagram/instagram-2.jpg" alt="Bootstrap 5 Template by Pixel Rocket" data-zoomable>
                            </picture>
                        </div>
                        <div class="col-3">
                            <picture>
                                <img class="img-fluid" src="../../assets/images/instagram/instagram-3.jpg" alt="Bootstrap 5 Template by Pixel Rocket" data-zoomable>
                            </picture>
                        </div>
                        <div class="col-3">
                            <picture>
                                <img class="img-fluid" src="../../assets/images/instagram/instagram-4.jpg" alt="Bootstrap 5 Template by Pixel Rocket" data-zoomable>
                            </picture>
                        </div>
                        <div class="col-3">
                            <picture>
                                <img class="img-fluid" src="../../assets/images/instagram/instagram-5.jpg" alt="Bootstrap 5 Template by Pixel Rocket" data-zoomable>
                            </picture>
                        </div>
                        <div class="col-3">
                            <picture>
                                <img class="img-fluid" src="../../assets/images/instagram/instagram-6.jpg" alt="Bootstrap 5 Template by Pixel Rocket" data-zoomable>
                            </picture>
                        </div>
                        <div class="col-3">
                            <picture>
                                <img class="img-fluid" src="../../assets/images/instagram/instagram-7.jpg" alt="Bootstrap 5 Template by Pixel Rocket" data-zoomable>
                            </picture>
                        </div>
                        <div class="col-3">
                            <picture>
                                <img class="img-fluid" src="../../assets/images/instagram/instagram-8.jpg" alt="Bootstrap 5 Template by Pixel Rocket" data-zoomable>
                            </picture>
                        </div>
                        <div class="col-3">
                            <picture>
                                <img class="img-fluid" src="../../assets/images/instagram/instagram-9.jpg" alt="Bootstrap 5 Template by Pixel Rocket" data-zoomable>
                            </picture>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-between align-items-center mt-3">
                <p class="text-muted m-0">Follow us on Instragram <span class="text-lowercase">@ussglobal</span></p>
                <a class="text-link-border fw-bolder m-0 text-white" href="#">More On Instagram <i class="ri-external-link-line align-bottom"></i></a>
            </div>
        </div>
        <!-- Instagram Display-->
        <!-- Info Bar-->
        <div class="container mt-7">
            <div class="row">
                <!-- Info box-->
                <div class="col-12 col-md-4 mb-3 mb-xxl-0 text-white" data-aos="fade-left">
                    <div class="border-white-opacity border-2 p-4 d-flex flex-column flex-lg-row justify-content-start align-items-start h-100">
                        <i class="ri-questionnaire-line ri-lg mb-3 mb-lg-0"></i>
                        <div class="ms-lg-4">
                            <p class="mb-1 lh-1 fw-bold">Customer Services</p>
                            <small class="text-muted"><a class="text-muted" href="#">Click here</a> to chat with our support team</small>
                        </div>
                    </div>
                </div>
                <!-- / Info box-->
                <!-- Info box-->
                <div class="col-12 col-md-4 mb-3 mb-xxl-0 text-white" data-aos="fade-left" data-aos-delay="250">
                    <div class="border-white-opacity border-2 p-4 d-flex flex-column flex-lg-row justify-content-start align-items-start h-100">
                        <i class="ri-truck-line ri-lg mb-3 mb-lg-0"></i>
                        <div class="ms-lg-4">
                            <p class="mb-1 lh-1 fw-bold">Delivery</p>
                            <small class="text-muted">Standard delivery on all orders over $100</small>
                        </div>
                    </div>
                </div>
                <!-- / Info box-->
                <!-- Info box-->
                <div class="col-12 col-md-4 mb-3 mb-xxl-0 text-white" data-aos="fade-left" data-aos-delay="500">
                    <div class="border-white-opacity border-2 p-4 d-flex flex-column flex-lg-row justify-content-start align-items-start h-100">
                        <i class="ri-repeat-line ri-lg mb-3 mb-lg-0"></i>
                        <div class="ms-lg-4">
                            <p class="mb-1 lh-1 fw-bold">Fully Refundable Security Deposit</p>
                            <small class="text-muted">Fully refundable security deposit if you do not buy any car.</small>
                        </div>
                    </div>
                </div>
                <!-- / Info box-->
            </div>
        </div>
        <!-- / Info Bar-->
        <!-- Menus & Newsletter-->
        <div class="border-top-white-opacity py-7 mt-7 text-white">
            <div class="container" data-aos="fade-in">
                <div class="row my-4 flex-wrap">
                    <!-- Footer Nav-->
                    <nav class="col-6 col-md mb-4 mb-md-0">
                        <h6 class="mb-4 fw-bolder fs-6">Shop</h6>
                        <ul class="list-unstyled">
                            <li class="mb-2"><router-link class="text-decoration-none text-white opacity-75 opacity-25-hover transition-all" to="/search?query=japanese">Japanese</router-link></li>
                            <li class="mb-2"><router-link class="text-decoration-none text-white opacity-75 opacity-25-hover transition-all" to="/search?query=european">European</router-link></li>
                            <li class="mb-2"><router-link class="text-decoration-none text-white opacity-75 opacity-25-hover transition-all" to="/search?query=american">American</router-link></li>
                            <li class="mb-2"><router-link class="text-decoration-none text-white opacity-75 opacity-25-hover transition-all" to="/search?query=superCars">Super Cars</router-link></li>
                        </ul>
                    </nav>
                    <!-- /Footer Nav-->
                    <!-- Footer Nav-->
                    <nav class="col-6 col-md mb-4 mb-md-0">
                        <h6 class="mb-4 fw-bolder fs-6">Company</h6>
                        <ul class="list-unstyled">
                            <li class="mb-2"><router-link class="text-decoration-none text-white opacity-75 opacity-25-hover transition-all" to="/aboutUs">About Us</router-link></li>
                            <li class="mb-2"><a class="text-decoration-none text-white opacity-75 opacity-25-hover transition-all" href="#">Our Blog</a></li>
                            <li class="mb-2"><router-link class="text-decoration-none text-white opacity-75 opacity-25-hover transition-all" to="/faq">FAQs</router-link></li>
                            <li class="mb-2"><router-link class="text-decoration-none text-white opacity-75 opacity-25-hover transition-all" to="/contactUs">Contact</router-link></li>
                        </ul>
                    </nav>
                    <!-- /Footer Nav-->
                    <!-- Footer Nav-->
                    <nav class="col-6 col-md mb-4 mb-md-0">
                        <h6 class="mb-4 fw-bolder fs-6">My Account</h6>
                        <ul class="list-unstyled">
                            <li class="mb-2"><router-link class="text-decoration-none text-white opacity-75 opacity-25-hover transition-all" to="/login">Register</router-link></li>
                            <li class="mb-2"><router-link class="text-decoration-none text-white opacity-75 opacity-25-hover transition-all" to="/account?tab=myPurchases">My Purchased</router-link></li>
                            <li class="mb-2"><router-link class="text-decoration-none text-white opacity-75 opacity-25-hover transition-all" to="/account?tab=myBids">Account</router-link></li>
                            <li class="mb-2"><router-link class="text-decoration-none text-white opacity-75 opacity-25-hover transition-all" to="/investor">Investor Panel</router-link></li>
                        </ul>
                    </nav>
                    <!-- /Footer Nav-->
                </div>
                <div class="border-top-white-opacity justify-content-between flex-column flex-md-row align-items-center d-flex pt-6 mt-6 px-0">
                    <p class="small opacity-75">&copy; <span v-text="year"></span> USSGlobal All Rights Reserved.</p>
                    <nav>
                        <ul class="list-unstyled">
                            <li class="d-inline-block me-1 bg-white rounded px-2 pt-1"><i class="pi pi-paypal pi-sm"></i></li>
                            <li class="d-inline-block me-1 bg-white rounded px-2 pt-1"><i class="pi pi-mastercard pi-sm"></i>
                            </li>
                            <li class="d-inline-block me-1 bg-white rounded px-2 pt-1"><i class="pi pi-american-express pi-sm"></i></li>
                            <li class="d-inline-block bg-white rounded px-2 pt-1"><i class="pi pi-visa pi-sm"></i></li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
        <!-- Menus & Newsletter-->
    </footer>
    <!-- / Footer-->
    <!-- Search Overlay-->
    <section class="search-overlay">
        <div class="container search-container">
            <div class="py-5">
                <div class="d-flex justify-content-between align-items-center mb-4">
                    <p class="lead lh-1 m-0 fw-bold">What are you looking for?</p>
                    <button class="btn btn-light btn-close-search"><i class="ri-close-circle-line align-bottom"></i> Close search</button>
                </div>
                <form v-on:submit.prevent="doSearch">
                    <input type="text" class="form-control" id="searchForm" placeholder="Search by product or category name..." v-model="search.query" />
                </form>
                
                <div class="text-center loader" v-if="searching">
                    <div class="spinner-border"></div>
                </div>

                <div class="my-5" v-if="search.total">
                    <p class="lead fw-bolder"><span v-text="search.total"></span> results found for "<span class="fw-bold" v-text="search.query"></span>"</p>
                    <div class="row">
                        <div class="col-12 col-md-6 col-lg-3 mb-3 mb-lg-0" v-for="(d, index) in search.data">
                            <!-- Card Product-->
                            <div class="card position-relative h-100 card-listing hover-trigger">
                                <div class="card-header">
                                    <picture class="position-relative overflow-hidden d-block bg-light">
                                        <img class="w-100 img-fluid position-relative z-index-10" v-bind:title="d.MARKA_NAME + ' ' + d.MODEL_NAME" v-bind:src="d.IMAGES[0].replace('h=50', 'w=320')" v-bind:alt="d.MARKA_NAME + ' ' + d.MODEL_NAME" />
                                    </picture>

                                    <picture class="position-absolute z-index-20 start-0 top-0 hover-show bg-light" style="width: 100%;">
                                        <img class="w-100 img-fluid" v-bind:title="d.MARKA_NAME + ' ' + d.MODEL_NAME" v-bind:src="d.IMAGES[1].replace('h=50', 'w=320') ?? ''" v-bind:alt="d.MARKA_NAME + ' ' + d.MODEL_NAME" />
                                    </picture>

                                    <div class="card-actions">
                                        <span class="small text-uppercase tracking-wide fw-bolder text-center d-block" v-text="d.MILEAGE + ' km'"></span>
                                        <div class="d-flex justify-content-center align-items-center flex-wrap mt-3">
                                            <button class="btn btn-outline-dark btn-sm mx-2" v-text="'Grade: ' + d.RATE"></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body px-0 text-center">
                                    <router-link class="mb-0 mx-2 mx-md-4 fs-p link-cover text-decoration-none d-block text-center" v-bind:to="'/carDetail/' + d.ID" v-text="d.MARKA_NAME + ' ' + d.MODEL_NAME + ' - ' + d.YEAR"></router-link>
                                    <p class="fw-bolder m-0 mt-2" v-html="'&yen;' + d.START"></p>
                                </div>
                            </div>
                            <!--/ Card Product-->
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 text-center">
                            <router-link class="btn btn-orange btn-orange-chunky text-white my-1" v-bind:to="'/search?query=' + search.query + '&page=2'" v-on:click="document.querySelector('.search-overlay').style.display = 'none'">View all results</router-link>
                        </div>
                    </div>
                </div>
                <div class="bg-dark p-4 text-white" style="margin-top: 10px;">
                    <p class="lead m-0">Didn't find what you are looking for? <a class="transition-all opacity-50-hover text-white text-link-border border-white pb-1 border-2" href="#">Send us a message.</a></p>
                </div>
            </div>
        </div>
    </section>

    <!-- Modal -->
    <div class="modal" id="loginModal">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Login/Sign Up</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="modal-body">
                    ...
                </div>
            </div>
        </div>
    </div>

    <div id="live-chat">
        
        <header class="clearfix">
            
            <a href="#" class="chat-close" style="text-decoration: none;">x</a>
            <h4 v-text="agentName"></h4>

            <span class="chat-message-counter" v-if="unreadMessages > 0" v-text="unreadMessages"></span>
        </header>

        <div class="chat" style="display: none;">

            <div class="chat-history" v-if="user == null">
                <a href="/login">Kindly login or register first.</a>
            </div>
            
            <div class="chat-history" id="chat-history">

                <button type="button" v-if="hasMoreMessages" class="btn btn-light btn-load-more-chat" v-on:click="loadMoreChat">
                    <i v-bind:class="'fa fa-refresh ' + loadMoreAnimationClass"></i>
                </button>

                <template v-for="(message, index) in messages">
                    <template v-if="message.type == 'joined'">
                        <div class="chat-message clearfix" style="margin-top: 20px;">
                            <div class="chat-message-content clearfix" style="margin-left: 0px;">
                                <p v-html="message.message" style="position: relative;
                                    background-color: #dadada;
                                    width: fit-content;
                                    left: 50%;
                                    transform: translateX(-50%);
                                    color: black;
                                    padding: 1px 10px;
                                    border-radius: 5px;"></p>
                            </div>
                        </div>
                    </template>

                    <template v-else-if="message.type == 'message'">
                        <template v-if="message.sentBy == 'admin'">
                            <div class="chat-message clearfix">
                                <!-- <img src="http://gravatar.com/avatar/2c0ad52fc5943b78d6abe069cc08f320?s=32" width="32" height="32"> -->
                                <div class="chat-message-content clearfix" style="margin-left: 0px;">
                                    <h5 v-text="message.agentName"></h5>
                                    <p v-html="message.message"></p>
                                    <span class="chat-time" v-text="message.sentAt" style="float: none;"></span>
                                </div>
                            </div>
                        </template>

                        <template v-else-if="message.sentBy == 'user'">
                            <div class="chat-message clearfix">
                                <!-- <img src="http://gravatar.com/avatar/2c0ad52fc5943b78d6abe069cc08f320?s=32" width="32" height="32"> -->
                                
                                <div class="chat-message-content clearfix">
                                    <h5 v-text="user?.name" style="text-align: right;"></h5>
                                    <p v-html="message.message" style="text-align: right;"></p>
                                    <span class="chat-time" v-text="message.sentAt"></span>
                                </div>
                            </div>
                        </template>
                    </template>
                </template>
                
                <!-- <div class="chat-message clearfix">
                    <img src="http://gravatar.com/avatar/2c0ad52fc5943b78d6abe069cc08f320?s=32" alt="" width="32" height="32">
                    <div class="chat-message-content clearfix">
                        <span class="chat-time">13:35</span>
                        <h5>John Doe</h5>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Error, explicabo quasi ratione odio dolorum harum.</p>
                    </div>
                </div>

                <hr>

                <div class="chat-message clearfix">
                    <img src="http://gravatar.com/avatar/2c0ad52fc5943b78d6abe069cc08f320?s=32" alt="" width="32" height="32">
                    
                    <div class="chat-message-content clearfix">
                        <span class="chat-time">13:37</span>
                        <h5>Marco Biedermann</h5>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Blanditiis, nulla accusamus magni vel debitis numquam qui tempora rem voluptatem delectus!</p>
                    </div>
                </div> -->

            </div>

            <!-- <p class="chat-feedback">Yazıyor..</p> -->

            <form action="#" method="post" v-on:submit.prevent="sendMessage">
                <fieldset>
                    <input type="text" style="width: 90%;" placeholder="Enter message" name="message" />

                    <button type="submit" style="width: 10%;
                        border: none;
                        background: no-repeat;">
                        <i class="fa fa-spinner fa-spin" v-if="sendingMessage"></i>
                        <i class="fa fa-paper-plane" v-else></i>
                    </button>
                </fieldset>
            </form>

        </div> <!-- end chat -->

    </div> <!-- end live-chat -->
</template>

<script>

import "../../assets/js/vendor.bundle.js"
import "../../assets/js/theme.bundle.js"

import store from "../../vuex/store"
import axios from "axios"
import swal from "sweetalert2"
import jQuery from "jquery"
import { io } from "socket.io-client"

export default {
    name: "AppFooter",

    computed: {
        search() {
            return store.getters.getSearch
        },

        user() {
            return store.getters.getUser
        }
    },

    data() {
        return {
            year: new Date().getFullYear(),
            searching: false,
            agentName: "",
            messages: [],
            chatPages: 0,
            chatPage: 1,
            sendingMessage: false,
            fetchingMessages: false,
            socketIO: null,
            hasMoreMessages: false,
            loadMoreAnimationClass: "",
            unreadMessages: 0
        }
    },

    methods: {
        async loadMoreChat() {
            this.loadMoreAnimationClass = "btn-load-more-animate"

            this.chatPage++
            this.fetchMessages()
        },

        async fetchMessages() {
            const self = this
            this.fetchingMessages = true
            const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

            const formData = new FormData()
            formData.append("timezone", timezone)
            formData.append("page", this.chatPage)

            try {
                const response = await axios.post(
                    this.$apiURL + "/messages/fetch",
                    formData,
                    {
                        headers: this.$headers
                    }
                )

                if (response.data.status == "success") {
                    response.data.messages.forEach(function (message) {
                        self.messages = self.prependArray(message, self.messages)
                    })
                    
                    this.chatPages = response.data.pages
                    this.agentName = response.data.agentName
                    this.hasMoreMessages = response.data.hasMoreMessages
                    this.loadMoreAnimationClass = ""

                    if (this.chatPage == 1) {
                        this.socketIO.emit("connected", this.user.email)
                        this.socketIO.on("newMessage", function (message) {
                            self.agentName = message.agentName
                            self.messages.push(message)

                            const audio = new Audio(require("@/assets/mp3/new-message.mp3"))
                            audio.play()

                            if (document.querySelector(".chat").style.display == "none") {
                                self.unreadMessages++
                            }
                        })
                    }
                }
            } catch (error) {
                if (error?.response?.status == 401) {
                    // swal.fire("Error", error.response.data.message || "Unauthorized", "error")
                }
            } finally {
                this.fetchingMessages = false
            }
        },

        async sendMessage() {
            const self = this
            this.sendingMessage = true

            const form = event.target
            const formData = new FormData(form)

            try {
                const response = await axios.post(
                    this.$apiURL + "/messages/send",
                    formData,
                    {
                        headers: this.$headers
                    }
                )

                if (response.data.status == "success") {
                    this.messages.push(response.data.messageObj)

                    if (this.socketIO != null) {
                        this.socketIO.emit("newMessage", {
                            message: response.data.messageObj
                        })
                    }

                    form.message.value = ""
                } else if (response.data.status == "error") {
                    swal.fire("Error", response.data.message, "error")
                }
            } catch (error) {
                if (error?.response?.status == 401) {
                    swal.fire("Error", error.response.data.message || "Unauthorized", "error")
                }
            } finally {
                this.sendingMessage = false
            }
        },

        async doSearch() {
            this.searching = true

            const formData = new FormData()
            formData.append("q", this.search.query)

            try {
                const response = await axios.post(
                    this.$apiURL + "/search",
                    formData, {
                        header: this.$headers
                    }
                )

                if (response.data.status == "success") {
                    this.search.data = response.data.cars
                    this.search.total = response.data.total
                }
            } catch (error) {
                if (error?.response?.status == 401) {
                    swal.fire("Error", error.response.data.message || "Unauthorized", "error")
                }
            } finally {
                this.searching = false
            }
        }
    },

    mounted() {

        const self = this
        this.socketIO = io(this.$nodeURL)
        this.fetchMessages()

        $('#live-chat header').on('click', function() {
            $('.chat').slideToggle(300, 'swing');
            // $('.chat-message-counter').fadeToggle(300, 'swing');

            setTimeout(function () {
                document.getElementById("chat-history").scrollTop = document.getElementById("chat-history").scrollHeight
            }, 100)

            if (document.querySelector(".chat").style.display == "") {
                self.unreadMessages = 0
            }
        })

        $('.chat-close').on('click', function(e) {
            e.preventDefault();
            // $('#live-chat').fadeOut(300);
        })
    },

    watch: {
        $route(to, from) {
            try {
                document.querySelector(".btn-close-search").click()
                if (!to.path.includes("/chat")) {
                    window.scrollTo(0, 0)
                }
                if (document.getElementById("navbarNavDropdown").className.includes("show")) {
                    document.querySelector(".btn-close-nav").click()
                }
            } catch (exp) {}
        }
    }
}

</script>

<style scoped>
@keyframes rotationAnimation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(180deg);
    }
}
.btn-load-more-animate {
    animation-name: rotationAnimation;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
.btn-load-more-chat {
    background-color: transparent;
    text-align: center;
    width: 100%;
    margin-bottom: 30px;
}

/* ---------- LIVE-CHAT ---------- */
#live-chat {
    bottom: 0;
    font-size: 12px;
    right: 24px;
    position: fixed;
    width: 300px;
    z-index: 1000;
}

#live-chat header {
    background: #293239;
    border-radius: 5px 5px 0 0;
    color: #fff;
    cursor: pointer;
    padding: 16px 24px;
}

/*#live-chat h4:before {
    background: #1a8a34;
    border-radius: 50%;
    content: "";
    display: inline-block;
    height: 8px;
    margin: 0 8px 0 0;
    width: 8px;
}*/

#live-chat h4 {
    font-size: 12px;
}

#live-chat h5 {
    font-size: 10px;
}

#live-chat form {
    padding: 24px;
}

#live-chat input[type="text"] {
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 8px;
    outline: none;
    width: 234px;
}

.chat-message-counter {
    background: #e62727;
    border: 1px solid #fff;
    border-radius: 50%;
    font-size: 12px;
    font-weight: bold;
    height: 28px;
    left: 0;
    line-height: 28px;
    margin: -15px 0 0 -15px;
    position: absolute;
    text-align: center;
    top: 0;
    width: 28px;
}

.chat-close {
    background: #1b2126;
    border-radius: 50%;
    color: #fff;
    display: block;
    float: right;
    font-size: 10px;
    height: 16px;
    line-height: 16px;
    margin: 2px 0 0 0;
    text-align: center;
    width: 16px;
}

.chat {
    background: #fff;
}

.chat-history {
    height: 252px;
    padding: 8px 24px;
    overflow-y: scroll;
}

.chat-message {
    margin: 16px 0;
}

.chat-message img {
    border-radius: 50%;
    float: left;
}

.chat-message-content {
    margin-left: 56px;
}

.chat-time {
    float: right;
    font-size: 10px;
}

.chat-feedback {
    font-style: italic; 
    margin: 0 0 0 80px;
}
</style>
