<template>
    <!-- Main Section-->
    <section class="mt-0 ">
        <!-- Category Top Banner -->
        <div class="py-6 bg-img-cover bg-dark bg-overlay-gradient-dark position-relative overflow-hidden mb-4 bg-pos-center-center" style="background-image: url(./assets/images/banners/banner-1.jpg);">
            <div class="container position-relative z-index-20" data-aos1="fade-right" data-aos1-delay="300">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item breadcrumb-light">
                            <router-link to="/">Home</router-link>
                        </li>
                        <li class="breadcrumb-item active breadcrumb-light" aria-current="page" v-text="query"></li>
                    </ol>
                </nav>
                <h1 class="fw-bold display-6 mb-4 text-white">
                    Total
                    <span v-text="'\'' + query + '\''"></span>
                    &nbsp;<span v-text="'('"></span>
                    <div class="spinner-border" style="border-width: thick;" v-if="loading"></div>
                    <span v-text="total" v-else></span>
                    <span v-text="')'"></span>
                </h1>
                <div class="col-12 col-md-6">
                    <p class="lead text-white mb-0">
                        Move, stretch, jump and hike in our latest waterproof arrivals. We've got you covered for your
                        hike or climbing sessions, from Gortex jackets to lightweight waterproof pants. Discover our
                        latest range of outdoor clothing.
                    </p>
                </div>
            </div>
        </div>
        <!-- Category Top Banner -->
        <div class="container">
            <div class="row">
                <!-- Category Aside/Sidebar -->
                <div class="d-none d-lg-flex col-lg-3">
                    <div class="pe-4">
                        <!-- Category Aside -->
                        <aside>
                            <form ref="searchForm" style="display: contents;" v-on:submit.prevent="doSearch">

                                <div class="form-group">
                                    <label>LOT</label>
                                    <input type="text" name="lot" class="form-control py-2 filter-search rounded" />
                                </div>

                                <div class="form-group">
                                    <label>Date</label>
                                    <input type="text" name="date" class="form-control py-2 filter-search rounded" autocomplete="off" />
                                </div>

                                <button type="submit" v-bind:disabled="loading" class="btn btn-orange btn-orange-chunky flex-grow-1 me-2 text-white">Search</button>
                            </form>
                        </aside>
                        <!-- / Category Aside-->
                    </div>
                </div>
                <!-- Category Products-->
                <div class="col-12 col-lg-9">
                    <!-- Products-->
                    <div class="row g-4 mb-5">

                        <div class="text-center loader" v-if="loading">
                            <div class="spinner-border"></div>
                        </div>

                        <select id="paypal-security-deposit-amount" v-on:change="renderPayPalButton">
                            <option value="1050">Auction Security Deposit $1,050.00 USD</option>
                            <option value="2100">Auction Security Deposit $2,100.00 USD</option>
                            <option value="3000" selected>Auction Security Deposit $3,000.00 USD</option>
                            <option value="5000">Auction Security Deposit $5,000.00 USD</option>
                        </select>

                        <div id="paypal-button-placeholder">
                            <div id="btn-paypal-checkout"></div>
                        </div>

                        <div id="paypal-button-container" style="width: 100% !important;"></div>

                        <div id="payment-processing-loader" style="display: none;">
                            <p style="color: #ff6262; margin-top: 10px;">
                                Processing Payment...
                            </p>
                            <div class="dot-flashing"></div>
                        </div>
                        
                        <div id="pp-button-processing-loader" style="margin-top: 20px;">
                            <div class="dot-flashing"></div>
                        </div>

                        <div class="col-12 col-sm-6 col-md-4" v-for="(d, index) in data">
                            <!-- Card Product-->
                            <div class="card position-relative h-100 card-listing hover-trigger">
                                <div class="card-header">
                                    <picture class="position-relative overflow-hidden d-block bg-light">
                                        <img class="w-100 img-fluid position-relative z-index-10" v-bind:title="d.MARKA_NAME + ' ' + d.MODEL_NAME" v-bind:src="getImage(d.IMAGES, 0)" v-bind:alt="d.MARKA_NAME + ' ' + d.MODEL_NAME" />
                                    </picture>
                                    <picture class="position-absolute z-index-20 start-0 top-0 hover-show bg-light">
                                        <img class="w-100 img-fluid" v-bind:title="d.MARKA_NAME + ' ' + d.MODEL_NAME" v-bind:src="getImage(d.IMAGES, 1)" v-bind:alt="d.MARKA_NAME + ' ' + d.MODEL_NAME" />
                                    </picture>
                                    <div class="card-actions">
                                        <span class="small text-uppercase tracking-wide fw-bolder text-center d-block" v-text="d.MILEAGE + ' km'"></span>
                                        <div class="d-flex justify-content-center align-items-center flex-wrap mt-3">
                                            <button class="btn btn-outline-dark btn-sm mx-2" v-text="'Grade: ' + d.RATE"></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body px-0 text-center">
                                    <router-link class="mb-0 mx-2 mx-md-4 fs-p link-cover text-decoration-none d-block text-center" v-bind:to="'/carDetail/' + d.ID" v-text="d.MARKA_NAME + ' ' + d.MODEL_NAME + ' - ' + d.YEAR"></router-link>
                                    <p class="fw-bolder m-0 mt-2" v-html="'&yen;' + d.START"></p>
                                </div>
                            </div>
                            <!--/ Card Product-->
                        </div>
                    </div>
                    <!-- / Products-->
                    <!-- Pagiation-->
                    <!-- Pagination-->
                    <nav class="border-top mt-5 pt-5 d-flex justify-content-between align-items-center" aria-label="Category Pagination">
                        <ul class="pagination" style="overflow-y: scroll;">
                            <li v-for="page in pages" v-bind:class="'page-item mx-1' + (page == pageNumber ? ' active' : '')"><a class="page-link" href="javascript:void(0)" v-on:click="paginate(page)" v-text="page"></a></li>
                        </ul>
                    </nav> <!-- / Pagination-->
                </div>
                <!-- / Category Products-->
            </div>
        </div>
    </section>
</template>

<script>

    import axios from "axios"
    import swal from "sweetalert2"
    import jQuery from "jquery"

    import "../assets/js/jquery.js"
    import "../assets/datetimepicker/jquery.datetimepicker.min.css"
    import "../assets/datetimepicker/jquery.datetimepicker.full.js"

    export default {
        name: "SearchComponent",

        data() {
            return {
                query: this.$route.query.query || "",
                loading: false,
                data: [],
                total: 0,
                pages: 0,
                pageNumber: this.$route.query.page || 1,
                orderID: "",
                payerID: "",
                paymentID: "",
                paymentToken: ""
            }
        },

        methods: {
            paymentMade: async function() {
                this.loading = true
                try {
                    const formData = new FormData()
                    formData.append("orderID", this.orderID)
                    formData.append("payerID", this.payerID)
                    formData.append("paymentID", this.paymentID)
                    formData.append("paymentToken", this.paymentToken)

                    const response = await axios.post(
                        this.$apiURL + "/paypalPayment",
                        formData,
                        {
                            headers: this.$headers
                        }
                    )

                    jQuery("#payment-processing-loader").hide()
    
                    if (response.data.status == "success") {
                        alert(response.data.message)
                        // window.location.href = baseUrl + "/auctions";
                    } else {
                        alert(response.data.message)
                    }
                } catch (error) {
                    if (error?.response?.status == 401) {
                        swal.fire("Error", error.response.data.message || "Unauthorized", "error")
                    }
                } finally {
                    this.loading = false
                }
            },
        
            renderPayPalButton: async function() {
                var self = this;
        
                if (document.getElementById("btn-paypal-checkout") != null) {
                    document.getElementById("btn-paypal-checkout").remove();
                }
        
                var div = document.createElement("div");
                div.setAttribute("id", "btn-paypal-checkout");
                div.style.marginTop = "20px";
                document.getElementById("paypal-button-placeholder").appendChild(div);
        
                // cookieItems array will be used for PayPal because it uses a specific format of data
                var cookieItems = [];
                cookieItems.push({
                    name: "Auction Security Deposit - USSGlobal.com",
                    description: "Auction Security Deposit - USSGlobal.com",
                    quantity: 1,
                    price: parseFloat(document.getElementById("paypal-security-deposit-amount").value),
                    sku: "1",
                    currency: "USD"
                });
        
                var total = 0;
                for (var a = 0; a < cookieItems.length; a++) {
                    total += cookieItems[a].price;
                }
        
                // Render the PayPal button
                paypal.Button.render({
        
                    // Set your environment
                    env: 'sandbox', // sandbox | production
        
                    // Specify the style of the button
                    style: {
                        label: 'checkout',
                        size: 'medium', // small | medium | large | responsive
                        shape: 'pill', // pill | rect
                        color: 'gold', // gold | blue | silver | black,
                        layout: 'vertical'
                    },
        
                    // PayPal Client IDs - replace with your own
                    // Create a PayPal app: https://developer.paypal.com/developer/applications/create
        
                    client: {
                        sandbox: "Ab6ibzauVBYh4wI2fvmVJZfgk_5mSErtbh0PGnYo9m9DyiN0UWKzZdlzvAjg77dEdqwxgehuvYyozK_7",
                        production: "AUoygdM-puSq9WqRV5V6T2RUd3hYtBX4ObVV17HNfyb2M2dtmE3tan8Z8JOKvPL-I4txVf46NMop25H9"
                    },
        
                    funding: {
                        allowed: [
                            // paypal.FUNDING.CARD,
                            // paypal.FUNDING.ELV
                        ]
                    },
        
                    payment: function(data, actions) {
                        return actions.payment.create({
                            payment: {
                                transactions: [{
                                    amount: {
                                        // get grand total from hidden input field
                                        total: parseFloat(total),
                                        currency: 'USD'
                                    },
                                    item_list: {
                                        // custom cookieItems array created specifically for PayPal 
                                        items: cookieItems
                                    }
                                }]
                            }
                        });
                    },
        
                    onAuthorize: function(data, actions) {
                        return actions.payment.execute().then(function() {
                            // console.log({
                            //     method: "onAuthorize",
                            //     data: data,
                            //     actions: actions
                            // });
        
                            // you can use all the values received from PayPal as you want
                            var intent = data.intent;
                            self.orderID = data.orderID;
                            self.payerID = data.payerID;
                            self.paymentID = data.paymentID;
                            self.paymentToken = data.paymentToken;
        
                            if (document.getElementById("btn-paypal-checkout") != null) {
                                document.getElementById("btn-paypal-checkout").remove();
                            }
                            jQuery("#payment-processing-loader").show();
        
                            // console.log(data);
        
                            /*console.log({
                                "intent": intent,
                                "orderID": self.orderID,
                                "payerID": self.payerID,
                                "paymentID": self.paymentID,
                                "paymentToken": self.paymentToken,
                            });*/
        
                            self.paymentMade();
        
                            // redirect the user to home page
                            // window.location.href = "index.php";
                        });
                    },
                    
                    onCancel: function (data, actions) {
                        // Show a cancel page or return to cart
                        console.log({
                            method: "onCancel",
                            data: data,
                            actions: actions
                        });
        
                        jQuery("#payment-processing-loader").hide();
                    }
        
                }, '#btn-paypal-checkout');
            },

            paginate(page) {
                this.data = []
                this.total = 0
                this.pages = 0
                this.pageNumber = page
                this.addOrUpdateURLParam("page", page)
                this.getData()
                window.scrollTo(0, 0)
            },

            async doSearch() {
                this.data = []
                this.total = 0
                this.pages = 0
                this.pageNumber = 1
                this.query = ""
                this.getData()
            },

            async getData() {
                this.loading = true
                try {
                    const form = this.$refs["searchForm"]
                    const formData = new FormData(form)
                    formData.append("q", this.query)
                    formData.append("page", this.pageNumber)

                    const response = await axios.post(
                        this.$apiURL + "/search",
                        formData,
                        {
                            headers: this.$headers
                        }
                    )

                    if (response.data.status == "success") {
                        this.data = response.data.cars
                        this.total = response.data.total
                        this.pages = response.data.pages
                    }
                } catch (error) {
                    if (error?.response?.status == 401) {
                        swal.fire("Error", error.response.data.message || "Unauthorized", "error")
                    }
                } finally {
                    this.loading = false
                }
            }
        },

        mounted() {
            this.getData()
            this.renderPayPalButton()

            setTimeout(function () {
                jQuery("input[name='date']").datetimepicker({
                    format: "Y-m-d",
                    timepicker: false,
                    scrollMonth: false
                })
            }, 500)

            if (this.query == "") {
                document.title = "Search | USSGlobal.com"
            } else {
                document.title = this.query + " | USSGlobal.com"
            }
        },

        watch: {
            $route(to, from) {
                this.query = to.query.query
                this.data = []
                this.pageNumber = 1
                this.pages = 0
                this.total = 0
                this.getData()
                window.scrollTo(0, 0)
            }
        }
    }
</script>
